








import Vue from "vue";
export default Vue.extend({
  name: "RunTitle",
  props: {
    title: String,
  },
});
