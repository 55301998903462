import { render, staticRenderFns } from "./CompoundID.vue?vue&type=template&id=97a4a784&scoped=true&"
import script from "./CompoundID.vue?vue&type=script&lang=ts&"
export * from "./CompoundID.vue?vue&type=script&lang=ts&"
import style0 from "./CompoundID.vue?vue&type=style&index=0&id=97a4a784&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a4a784",
  null
  
)

export default component.exports