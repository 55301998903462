









import Vue from "vue";
export default Vue.extend({
  name: "HomeOutlineTitle",
  data() {
    return {
      outlineTitle: this.$t("home.outlineTitle")
    }
  },
  watch: {
    '$i18n.locale'(language) {
      this.initLanguage()
    }
  },
  methods: {
    initLanguage() {
      this.outlineTitle = this.$t("home.outlineTitle")
    }
  }
});
