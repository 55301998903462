














import Vue from "vue";
export default Vue.extend({
  name: "HSEBandState",
  computed: {
    bandState: {
      get: function () {
        return this.$store.state.matDataDetails.mat_data_des.elec_diagram;
      },
    },
  },
  updated() {
    if (this.bandState.hse_elec_diagram_display == "block"){
      var svg = document.getElementsByTagName('svg')[1];
      svg.setAttribute('width', '100%');
      svg.setAttribute('viewbox', '0 0 833.8225 602.533125');
      svg.removeAttribute('height')
    }
  }
});
