import { APINode } from "arkfbp/lib/apiNode";

export class WeChatRegisterNode extends APINode {
  mode = "direct";
  url = "/api/arkid/wechat/register/";
  method = "post";

  async buildParams() {
    const mobile = this.$state.fetch().mobile;
    const sms_token = this.$state.fetch().store.state.login.sms_token;
    const wechat_id = this.$state.fetch().store.state.user.userWeChatId;
    return {
      mobile: mobile,
      sms_token: sms_token,
      wechat_id: wechat_id,
    };
  }
}
