import { APINode } from "arkfbp/lib/apiNode";

export class GetNewTokenNode extends APINode {
  mode = "direct";
  url = "/api/arkid/register_get_sms_token/";
  method = "post";

  async buildParams() {
    this.$state.commit((state: any) => {
      state.store = this.inputs.store;
      return state;
    });
    return { mobile: this.inputs.mobile, code: this.inputs.code};
  }
  
}
