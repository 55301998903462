export default {
  state: {
    lang: true
  },
  mutations: {
    changeLanguage(state: any): void {
      state.lang = !state.lang
    }
  },
  actions: {
    asyncChangeLanguage (context: any) {
      context.commit("changeLanguage");
    }
  }
}