import { AuthApiNode } from "@/nodes/authApiNode"
export class GetInterfaceData extends AuthApiNode {
  mode = "direct";
  url = "/api/interface_reaction/interface_receive/";
  method = "post";
  
  buildParams = async () => {
    let params_obj = { reactant1: "", reactant2: "" }
    this.$state.commit((state: any) => {
      state.store_ = this.inputs.interface_store
      return state
    })
    params_obj.reactant1 = this.inputs.reactant1
    params_obj.reactant2 = this.inputs.reactant2
    return params_obj
  }

}