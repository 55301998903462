export default {
  state: {
    run_upload_file: {
      error: "false",
      script: "",
      lattice: {
        a: null,
        b: null,
        c: null,
        alpha: null,
        beta: null,
        gamma: null,
        volume: null,
      },
      symmetry: {
        hall: null,
        spacegroup: null,
        pointgroup: null,
        crystal_system: null,
      },
    },
  },
  mutations: {
    saveUploadFileData(state: any, data: any) {
      state.run_upload_file.script = data.script;
      state.run_upload_file.error = data.error;
      state.run_upload_file.lattice = data.detail.lattice;
      state.run_upload_file.symmetry = data.detail.symmetry;
    },
  },
  actions: {
    saveUploadFile(context: any, data: any) {
      context.commit("saveUploadFileData", data);
    },
  },
};
