import { AuthApiNode } from "@/nodes/authApiNode";
export class GetDetails extends AuthApiNode {

  mode = "direct"
  method = "post"
  url = "/api/tight_binding/get_lattice_structure/"

  buildParams = async () => {
    let params_obj = { grid_id: "" }
    params_obj.grid_id = this.$state.fetch().grid_id;
    return params_obj
  }
  
}