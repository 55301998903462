export default {
  state: {
    // 分子式信息
    formulaInfo: {
      name: "H2O",
      id: "101010",
    },
  },
  mutations: {
    // 修改分子式信息中的name和id -- 第一次尝试 -- 后续添加更多所要绑定和修改的数据
    // state 之前的状态信息  newInfo 需要传入的新的分子式信息（包含name，id等信息）
    switchFormula (state: any, newInfo: any) {
      state.formulaInfo.name = newInfo.name;
      state.formulaInfo.id = newInfo.id;
    }
  }
}