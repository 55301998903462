import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import {GetOldSmsToken} from "./nodes/GetOldSmsToken"
export class MobileOldSms extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "start",
        next: "1",
      },
      {
        cls: GetOldSmsToken,
        id: "1",
        next: "end",
      },
      {
        cls: StopNode,
        id: "end",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
