import { FunctionNode } from 'arkfbp/lib/functionNode'
import processXrdTable from '@/utils/xrd'

export class StoreXrdInfo extends FunctionNode {
  async run() {
    const _store = this.$state.fetch().store
    let _data;
    if (this.inputs.results && this.inputs.results.length > 0) {
        _data = processXrdTable(this.inputs.results)
    } else {
        _data = []
    }
    _store.dispatch("updateXrdBySomeway", _data)
    _store.dispatch("getXrdCSize", this.inputs.crystal_size)
    _store.dispatch("getRadiation", this.inputs.radiation)
    return this.inputs
  }
}