export default function formatFormula (formula: string): string {
  if (!formula) {
    return '-'
  }
  const spilt_formula = formula.split("");
  const r = /[a-zA-Z]/;
  let result: Array<any> = []
  spilt_formula.forEach(item => {
    if(r.test(item)) {
      result.push('<span>' + item + '</span>');
    } else if(item == "(" || item == ")" || item == "[" || item == "]" || item == "}" || item == "{") {
      result.push('<span>' + item + '</span>');
    } else {
      result.push('<sub>' + item + '</sub>');
    }
  })
  const f = result.join('')
  return f;
}