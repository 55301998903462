






























































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from 'vue-property-decorator';
import NavHead from "@/components/NavHead.vue";
import TitleBox from "@/components/public/titleBox/TitleBox.vue";
import TitleBoxDielectric from "@/components/dielectric/TitleBoxDielectric.vue";
import TitleBoxIrvsp from "@/components/irvsp/TitleBoxIrvsp.vue";
import TitleBoxPiezoelectric from "@/components/piezoelectric/TitleBoxPiezoelectric.vue";
import TitleBoxElastic from "@/components/elastic/TitleBoxElastic.vue";
import CompoundID from "@/components/matdatades/CompoundID.vue";
import Table from "@/components/Table.vue";
import CrystalStructure from "@/components/matdatades/CrystalStructure.vue";
import BandState from "@/components/matdatades/BandState.vue";
import HSEBandState from "@/components/matdatades/HSEBandState.vue";
import PhaseDiagram from "@/components/matdatades/PhaseDiagram.vue";
import SecStableTable from "@/components/SecStableTable.vue";
import XRDComponent from "@/components/XRDComponent.vue";
import ThreeComponent from "@/components/ThreeComponent.vue";
import MoreTable from "@/components/matdatades/MoreTable.vue";
import NavFooter from "@/components/NavFooter.vue";
import Dielectric from "@/components/matdatades/Dielectric.vue";
import Piezoelectric from "@/components/matdatades/Piezoelectric.vue";
import Elastic from "@/components/matdatades/Elastic.vue";
import Irvsp from "@/components/matdatades/Irvsp.vue"

import { runWorkflow } from "arkfbp/lib/flow";
import { Main } from "@/flows/matDataDes";
import { Main_ } from "@/flows/brillouinZone";
import { Main_X_Ray } from "@/flows/getXRayFlow";
import { Main_Phase_Diagram } from "@/flows/matdatadesPhaseDiagram";
import { Main_irvsp } from "@/flows/irvsp";
@Component({
  components: {
    NavHead,
    TitleBox,
    TitleBoxDielectric,
    TitleBoxPiezoelectric,
    TitleBoxElastic,
    CompoundID,
    ThreeComponent,
    CrystalStructure,
    BandState,
    HSEBandState,
    //BandComponent,
    PhaseDiagram,
    SecStableTable,
    XRDComponent,
    Table,
    MoreTable,
    NavFooter,
    Dielectric,
    Piezoelectric,
    Elastic,
    Irvsp,
    TitleBoxIrvsp,
  },
})
export default class MatDataDes extends Vue {
  name = "MatDataDes";
  navData: Object = {
    bgc_scroll: "rgba(224,224,224,0.4)",
    bgc_unscroll: "rgba(224,224,224,0.4)",
    sbgc_scroll: "rgba(255,255,255,0.5)",
    sbgc_unscroll: "rgba(255,255,255,0.1)",
    border_color: "#FAFBFC",
    color_scroll: "#000",
    color_unscroll: "#000",
  };
  radiation_wavelength: Number = 1.54184;
  particle_size: Number = 200;
  jmoldata: String = "";
  struct_id: String = "";
  isRotated: Boolean = false;

  MATDES_STORE = this.$store.state.matDataDetails.mat_data_des;

  get bandData() {
    return this.$store.state.matDataPhaseDiagram.matBandData;
  }

  get stableData() {
    return this.$store.state.matDataPhaseDiagram.stableData || [];
  }

  get unstableData() {
    return this.$store.state.matDataPhaseDiagram.unstableData || [];
  }

  get compound_str() {
    return (
      this.$store.state.matDataDetails.mat_data_des.formula
    );
  }
  get compound_id() {
    return (
      this.$store.state.matDataDetails.mat_data_des.struct_id
    );
  }

  get xrd_data() {
    return this.$store.state.xRayData.x_ray_data;
  }

  get isResetMatDes() {
    return this.$store.state.isReset.isResetLayout;
  }

  get three_bz() {
    return this.$store.state.bZone.b_zone.brillouinZone;
  }

  get generateNumber() {
    return this.$store.state.matDataDetails.mat_data_des.nelements;
  }

  get first_table_data() {
    return {
      a:
        this.$store.state.matDataDetails.mat_data_des.lattice.a,
      alpha:
        this.$store.state.matDataDetails.mat_data_des.lattice.alpha,
      b:
        this.$store.state.matDataDetails.mat_data_des.lattice.b,
      beta:
        this.$store.state.matDataDetails.mat_data_des.lattice.beta,
      c:
        this.$store.state.matDataDetails.mat_data_des.lattice.c,
      gamma:
        this.$store.state.matDataDetails.mat_data_des.lattice.gamma,
      volume:
        this.$store.state.matDataDetails.mat_data_des.lattice.volume,
    };
  }
  get second_table_data() {
    return {
      energy: this.$store.state.matDataDetails.mat_data_des.energies.energy,
      energy_atom: this.$store.state.matDataDetails.mat_data_des.energies.energy_per_atom,
      energy_vdw: this.$store.state.matDataDetails.mat_data_des.energies.energy_vdw,
      energy_vdw_atom: this.$store.state.matDataDetails.mat_data_des.energies.energy_per_atom_vdw,
    };
  }

  get third_table_data() {
    return {
      hall:
        this.$store.state.matDataDetails.mat_data_des.symmetry_data.hall,
      spacegroup:
        this.$store.state.matDataDetails.mat_data_des.symmetry_data.space_group,
      pointgroup:
        this.$store.state.matDataDetails.mat_data_des.symmetry_data.point_group,
      crystal_system:
        this.$store.state.matDataDetails.mat_data_des.symmetry_data.crystal_system,
    };
  }

  get more_table_data() {
    return {
      calc_method:
        this.$store.state.matDataDetails.mat_data_des.run_type,
      calc_date:
        this.$store.state.matDataDetails.mat_data_des.calculated_at,
      vasp:
        this.$store.state.matDataDetails.mat_data_des.vasp_version,
      potcar:
        this.$store.state.matDataDetails.mat_data_des.potcar_symbols,
      u_value:
        this.$store.state.matDataDetails.mat_data_des.hubbards || "--",
      band_gap_type: this.$store.state.matDataDetails.mat_data_des.band_gap_type || "--",
      band_gap: this.$store.state.matDataDetails.mat_data_des.band_gap || "--",
      nsites: this.$store.state.matDataDetails.mat_data_des.nsites,
      e_above_hull: this.$store.state.matDataDetails.mat_data_des.energies.e_above_hull || '--',
      magnetization: this.$store.state.matDataDetails.mat_data_des.total_magnetization || "--",
      formation_energy_per_atom: this.$store.state.matDataDetails.mat_data_des.energies.formation_energy_per_atom || "--",
      decomposition: this.$store.state.matDataDetails.mat_data_des.decomposition || "--",
      elements_type: this.$store.state.matDataDetails.mat_data_des.elements_type,
      elements_quantity: this.$store.state.matDataDetails.mat_data_des.elements_quantity,
      efermi: this.$store.state.matDataDetails.mat_data_des.energies.efermi
    };
  }

  get bandinclude() {
    return this.$store.state.matDataDetails.mat_data_des.nelements;
  }

  get epsilon_data() {
    return {
      has_epsilon: this.$store.state.matDataDetails.mat_data_des.epsilon_piezo.has_epsilon,
      has_piezo: this.$store.state.matDataDetails.mat_data_des.epsilon_piezo.has_piezo,
      epsilon: this.$store.state.matDataDetails.mat_data_des.epsilon_piezo.epsilon_piezo_data
    }
  }

  get elastic_data() {
    return {
      has_elastic: this.$store.state.matDataDetails.mat_data_des.elastic_dict.has_elastic,
      elastic_tensor: this.$store.state.matDataDetails.mat_data_des.elastic_dict.elastic_tensor,
      elastic_property: this.$store.state.matDataDetails.mat_data_des.elastic_dict.elastic_property
    }
  }

  get irvsp_data() {
    return {
      is_topo_display: this.$store.state.irvsp.irvsp_data.is_topo_display,
      labels: this.$store.state.irvsp.irvsp_data.labels,
      topo_data_web: this.$store.state.irvsp.irvsp_data.topo_data_web,
    }
  }

  @Watch('bandinclude')
  getNewElements(val: any) {
    if (val > 1 && val <5) {
      this.isRotated = true;
      runWorkflow(new Main_Phase_Diagram(), {
        matdatades_phase_store: this.$store,
        include: this.$store.state.matDataDetails.mat_data_des.elements,
        n: this.generateNumber.toString(),
      }).then((data) => {
        this.isRotated = false;
      });
    }
  }

  matDataRunFlow(struct_id_value: any) {
      runWorkflow(new Main(), {
        struct_id: struct_id_value,
        detail_store: this.$store,
      });
      runWorkflow(new Main_X_Ray(), {
        struct_id: struct_id_value,
        x_ray_store: this.$store,
      });
      runWorkflow(new Main_irvsp(), {
        struct_id: struct_id_value,
        irvsp_data: this.$store,
      });
      runWorkflow(new Main_(), {
        struct_id: struct_id_value,
        b_zone_store: this.$store,
      });      
  }

  judgeRequestParams() {
    const _id: any = this.$route.params.id;
    if (_id != "") {
      this.matDataRunFlow(_id);
      this.$store.dispatch("storeStructIDGrobal", _id);
    }
  }

  getCurrentThreeWidth(): string {
    const curScreenWidth = document.documentElement.offsetWidth;
    if (curScreenWidth >= 1440) {
      return "1000";
    } else if (curScreenWidth >= 800 && curScreenWidth < 1440) {
      //return (curScreenWidth * 0.38).toString();
      return (curScreenWidth * 0.38).toString();
    } else {
      return (curScreenWidth * 0.6).toString();
    }
  }

  getCurrentThreeHeight(): string {
    const curScreenWidth = document.documentElement.offsetWidth;
    if (curScreenWidth >= 1440) {
      return "550";
    } else if (curScreenWidth >= 800 && curScreenWidth < 1440) {
      //return (curScreenWidth * 0.335).toString();
      return (curScreenWidth * 0.335).toString();
    } else {
      return (curScreenWidth * 0.48).toString();
    }
  }

  //beforeCreate() {
  //  if(!localStorage.getItem('userToken')) {
  //    this.$router.push("/");
  //  }
  //}

  mounted() {
    this.judgeRequestParams();
  }

}
