export default {
  state: {
    mat_data_des: {
      struct_id: new String(),
      total_magnetization: new String(),
      elements: new Array(),
      nelements: new Number(),
      formula: new String(),
      nsites: new Number(),
      run_type: new String(),
      calculated_at: new String(),
      vasp_version: new String(),
      potcar_symbols: new String(),
      hubbards: new String(),
      band_gap: new String(),
      band_gap_type: new String(),
      decomposition: new String(),
      elements_type: new String(),
      elements_quantity: new String(),
      lattice: new Object(),
      energies: new Object(),
      symmetry_data: new Object(),
      output_structs: new Object(),
      elec_diagram: new Object(),
      epsilon_piezo: new Object(),
      elastic_dict: new Object(),

      /*
      banddos: "",
      crystal_system: "",
      display: "block",
      initial_formula: "",
      pointgroup: "",
      result: {
        struct_id: new Number(),
        struct_id_: "",
        nelements: new String(),
        elements: [],
        calculated_at: new String(),
        energy: new Number(),
        energy_per_atom: new Number(),
        energy_per_atom_vdw: new Number(),
        energy_vdw: new Number(),
        band_gap_type: "",
        band_gap: "",
        e_above_hull: "",
        magnetization: "",
        formation_energy_per_atom: "",
        decomposition: "",
        elements_type: "",
        elements_quantity: "",
        efermi: "",
        hubbards: "",
        nsites: new String(),
        potcar_symbols: "",
        pretty_formula: new String(),
        relaxed_structure: {
          charge: null,
          lattice: {
            a: new Number(),
            alpha: new Number(),
            b: new Number(),
            beta: new Number(),
            c: new Number(),
            gamma: new Number(),
            volume: new Number(),
            matrix: [],
          },
        },
        run_type: "",
        symmetry_data_relaxed: {
          number: 2,
          hall_number: 2,
          international: "",
          hall: new String(),
          choice: "",
          equivalent_atoms: [],
          origin_shift: [],
          pointgroup: "",
          rotations: [],
          sit_symmetry_symbols: [],
          std_lattice: [],
          std_mapping_to_primitive: [],
          std_positions: [],
          std_rotation_matrix: [],
          std_types: [],
          transformation_matrix: [],
          translations: [],
          wyckoffs: [],
          vasp_version: new String(),
        },
        vasp_version: "",
      },
      spacegroup: new String(),
      struct: ""
      */
  
    },
  },
  mutations: {
    saveMatDataDetails(state: any, data: any) {
      state.mat_data_des = data;
    },
  },
  actions: {
    saveMatDetails(context: any, data: any) {
      context.commit("saveMatDataDetails", data);
    },
  },
};