import { AuthApiNode } from "@/nodes/authApiNode"

export class GetDataByFormula extends AuthApiNode {
  method = 'post'
  mode = 'direct'
  url = '/api/matdata/search_by_formula/'

  buildParams = async () => {
    this.$state.commit((state: any) => {
      state.store = this.inputs.search_store
      return state
    })
    let params_obj = { include: "" }
    params_obj.include = this.inputs.include
    return params_obj
  }
}