export default {
    state: {
      x_ray_data_initial: {
        data: [
          {
            hoverinfo: "text",
            mode: "markers",
            text: [],
            x: [],
            y: []
          }
        ],
        x: [],
        y: [],
        name: ""
      }
    },
    mutations: {
      saveXRayDataInitial(state: any, data: any){
        state.x_ray_data_initial = data
      }
    },
    actions: {
      saveXRayInitial (context: any, data: any) {
        context.commit("saveXRayDataInitial", data);
      }
    }
  }