import { AuthApiNode } from "@/nodes/authApiNode";

export class RequestData extends AuthApiNode {
  mode = "direct";
  url = "/api/matdata/get_phase_diagram/";
  method = "post";

  buildParams = async () => {
    this.$state.commit((state: any) => {
      state.store = this.inputs.matdatades_phase_store
    })
    let params_obj = {
      include: this.inputs.include,
      n: this.inputs.n
    }
    return params_obj
  }

}
