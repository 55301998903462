import { AuthApiNode } from "@/nodes/authApiNode"
export class RequestImgInfo extends AuthApiNode {
  url = "/api/xrd/xrd_get/"
  method = "post"
  mode = "direct"

  buildParams = async () => {
    const params_obj = {
      struct_id: this.inputs.struct_id,
      crystal_size: this.inputs.crystal_size,
      radiation: this.inputs.radiation
    }
    this.$state.commit((state: any) => {
      state.store = this.inputs.upload_store
    })
    return params_obj
  }

}