import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { GetXRayData } from "./nodes/GetXRayData"
import { UpdateData } from "./nodes/UpdateData"

export class Main_X_Ray extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "1",
        next: "2",
      },
      {
        cls: GetXRayData,
        id: "2",
        next: "3",
      },
      {
        cls: UpdateData,
        id: "3",
        next: "4",
      },
      {
        cls: StopNode,
        id: "4",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
