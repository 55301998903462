











import Vue from "vue";
export default Vue.extend({
  name: "ButtonCpn",
  props: {
    b_text: String,
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    handleClickButton(e: any) {
      let target = e.target;
      if (target.nodeName == "SPAN" || target.nodeName == "I") {
        target = e.target.parentNode;
      }
      target.blur();
      this.$emit("click");
    },
  },
});
