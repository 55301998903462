import { render, staticRenderFns } from "./XrdDisplay.vue?vue&type=template&id=0afee332&scoped=true&"
import script from "./XrdDisplay.vue?vue&type=script&lang=ts&"
export * from "./XrdDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./XrdDisplay.vue?vue&type=style&index=0&id=0afee332&lang=scss&scoped=true&"
import style1 from "./XrdDisplay.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0afee332",
  null
  
)

export default component.exports