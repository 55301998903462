import { FunctionNode } from "arkfbp/lib/functionNode";
export class HandleParams extends FunctionNode {
  async run() {
    this.$state.commit((state: any) => {
      state.store = this.inputs.tb_store;
      state.grid_id = this.inputs.grid_id;
      state.t1_ratio = this.inputs.t1_ratio;
      state.t2_ratio = this.inputs.t2_ratio;
      state.onsites_symbol = this.inputs.onsites_symbol;
      state.new_grid = this.inputs.new_grid;
      return state;
    });
  }
}
