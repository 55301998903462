export default {
    state: {
      tb_data: {
        grid_id: new String(),
        script: new String(),
        wyckoeffs: new Object(),
      },
      tb_band: {
        k_dist: new Object(),
        plotly_data: new Object(),
        hsp_nodes: new Object(),
        hsp_labels: new Object(),
      },
    },
    mutations: {
      saveTbDataDetails(state: any, data: any) {
        state.tb_data = data;
      },
      saveTbBandDetails(state: any, data: any) {
        state.tb_band = data;
      },
    },
    actions: {
      saveTbData(context: any, data: any) {
        context.commit("saveTbDataDetails", data);
      },
      saveTbBand(context: any, data: any) {
        context.commit("saveTbBandDetails", data);
      },
    },
  };