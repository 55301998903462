import { render, staticRenderFns } from "./SecStableTable.vue?vue&type=template&id=2c9edf94&scoped=true&"
import script from "./SecStableTable.vue?vue&type=script&lang=ts&"
export * from "./SecStableTable.vue?vue&type=script&lang=ts&"
import style1 from "./SecStableTable.vue?vue&type=style&index=1&id=2c9edf94&lang=scss&scoped=true&"
import style2 from "./SecStableTable.vue?vue&type=style&index=2&id=2c9edf94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9edf94",
  null
  
)

export default component.exports