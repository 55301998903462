












import Vue from "vue";
export default Vue.extend({
  name: "Message-Prompt",
  data() {
    return {
      isDisplayIt: false,
      flag: false,
      isRight: "right",
      content: ""
    };
  },
  computed: {
    isShow: {
      get: function () {
        if (this.isDisplayIt == false) {
          return false;
        }
        if (this.isDisplayIt == true) {
          return true;
        }
        return true;
      },
    },
  },
  methods: {
    handleMessageClose() {
      this.isDisplayIt = false;
    },
    showMessage(isRight: string, content: string, time: number = 5000) {
      this.isRight = isRight
      this.content = content
      if(this.isDisplayIt != false) {
        this.isDisplayIt = false
      }
      const _this: any = this;
      _this.isDisplayIt = true;
      setTimeout(() => {
        _this.isDisplayIt = false;
      }, time);
    },
  },
});
