<template>
  <div class="atomly-home">
    <NavHead :navData="navData"></NavHead>
    <div class="home">
      <HomeHeader></HomeHeader>
      <AtomlyOutline
        :atomlyData="atomlyData"
        :teamList="teamData"
        :isResetOutline="isResetHome"
        class="atomly-home-outline"
      ></AtomlyOutline>
    </div>
    <NavFooter id="home-footer"></NavFooter>
  </div>
</template>

<script>
import NavHead from "@/components/NavHead.vue";
import HomeHeader from "@/components/home/HomeHeader.vue";
import AtomlyOutline from "@/components/home/AtomlyOutline.vue";
import NavFooter from "@/components/NavFooter.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main } from "@/flows/atomlyOutline";

export default {
  name: "home",
  components: {
    NavHead,
    HomeHeader,
    AtomlyOutline,
    NavFooter,
  },
  data() {
    return {
      navData: {
        bgc_scroll: "rgba(224, 224, 224, 0.4)",
        bgc_unscroll: "rgba(255,255,255,0)",
        sbgc_scroll: "rgb(255,255,255,0.5)",
        sbgc_unscroll: "rgba(0,0,0,0.1)",
        border_color: "none",
        color_scroll: "#000",
        color_unscroll: "#FFF",
      },
    };
  },
  computed: {
    atomlyData: {
      get: function () {
        return {
          compounds: this.$store.state.homeData.home_data.compounds,
          bandStructures: this.$store.state.homeData.home_data.band_structures,
          phaseDiagrams: this.$store.state.homeData.home_data.phase_diagrams,
        };
      },
    },
    teamData: {
      get: function () {
        return this.$store.state.teamList.team_data;
      },
    },
    isResetHome: {
      get: function () {
        return this.$store.state.isReset.isResetLayout;
      },
    },
    currentWidthValue: {
      get: function () {
        const current_width = this.$store.state.isReset.currentPageWidth;
        if (current_width > 1440) {
          return 1;
        } else if (current_width > 1000 && current_width < 1440) {
          return 0;
        } else {
          return -1;
        }
      },
    },
  },
  mounted() {
    runWorkflow(new Main(), {
      home_store: this.$store,
    });
  },
  created() {
    this.toJudgeLoginState();
  },
  methods: {
    getWidth() {
      const _width = document.documentElement.offsetWidth;
      if (_width > 800) {
        return false;
      } else {
        return true;
      }
    },
    toJudgeLoginState() {
      //if(localStorage.getItem('userToken') && localStorage.getItem('invalidToken') == 'false') {
      //  this.$router.push("/matdata");
      //}
      if(localStorage.getItem('userToken')) {
        this.$router.push("/matdata");
      }
    }
  }
};
</script>

<style lang="scss" scoped>

#home-footer {
  margin-top: -15px;
}

@media screen and (min-width: 1440px) {
  .home {
    background-color: #f2f2f2;
    .atomly-outline-info {
      position: relative;
      margin-left: calc(50% - 604.5px);
      top: -100px;
    }
  }
}

@media screen and (min-width: 800.1px) and (max-width: 1439.9px) {
  .home {
    width: 100%;
    background-color: #f2f2f2;
  }
  .atomly-outline-info {
    width: 100%;
    background: #f2f2f2;
    .atomly-outline-info-web {
      margin-top: -4.5rem;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 800px) {
  .home {
    background-color: #fff;
  }
  .atomly-introduction-mobile {
    width: 100%;
    height: 17rem !important;
    background-size: auto 212px !important;
    .ssn-home-title {
      .ssn-home-first-title {
        font-size: 24px !important;
      }
      .ssn-home-second-title {
        font-size: 12px !important;
      }
      .ssn-home-third-title {
        font-size: 10px !important;
      }
    }
  }
  #home-footer {
    margin-top: 2rem;
  }
}
</style>