













































































































































import Vue from 'vue'
export default Vue.extend({
    name: "Piezoelectric",
    props: {
        epsilon_data: Object,
    }
})
