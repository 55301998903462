import { AuthApiNode } from "@/nodes/authApiNode"
export class RequestImgInfo extends AuthApiNode {
  url = "/api/xrd/xrd_nor_data/"
  method = "post"
  mode = "direct"

  buildParams = async () => {
    const params_obj = {
      xrd_data: this.inputs.xrd_data
    }
    this.$state.commit((state: any) => {
      state.store = this.inputs.upload_store
    })
    return params_obj
  }

}