import { AuthApiNode  } from "@/nodes/authApiNode";

export class UpdateInfoNode extends AuthApiNode {
  mode = "direct";
  url = "/api/arkid/change_infor/";
  method = "post";
  async buildParams() {
    return this.inputs;
  }
}
