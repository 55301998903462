import { FunctionNode } from 'arkfbp/lib/functionNode'
import processSearchTableResult from '@/utils/search'
export class UpdateData extends FunctionNode {

  async run() {
    const _store = this.$state.fetch().store
    let _data;
    if (this.inputs.results && this.inputs.results.length > 0) {
      _data = processSearchTableResult(this.inputs.results)
    } else {
      _data = []
    }
    _store.dispatch("updateBySomeway", _data)
    _store.dispatch("addTime")
    _store.dispatch("getLenAndFormula", {
      length: this.inputs.len,
      formula: this.inputs.formula
    })
    return this.inputs
  }
}