


















































import Vue from "vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { WxLoginRequest } from "@/flows/wxLoginRequest";
import { GitLoginRequest } from "@/flows/gitLoginRequest";
export default Vue.extend({
  data() {
    return {
      accountForm: {
        userType: this.$store.state.user.userType,
        UID: this.$store.state.user.userid,
        mobile: this.$store.state.user.userMobile,
        weChatId: this.$store.state.user.userWeChatId,
        githubId: this.$store.state.user.userGithubId,
      },
    };
  },

  methods: {
    changeMobile() {
      this.$emit("goChangeMobile");
    },
    changeBindWx() {
      if (!this.accountForm.weChatId) {
        runWorkflow(new WxLoginRequest(), {});
      } else {
        this.$message({
          message: "您已绑定",
          type: "warning",
           offset: 100,
        });
      }
    },
    changeBindGit() {
      if (!this.accountForm.githubId) {
        runWorkflow(new GitLoginRequest(), {});
      } else {
        this.$message({
          message: "您已绑定",
          type: "warning",
          offset: 100,
        });
      }
    },
    logOut() {
      localStorage.removeItem("userToken");
      this.$router.push("/");
      this.$store.commit("setUserId","")
    },
  },
});
