import { Flow } from 'arkfbp/lib/flow';
import { Graph } from 'arkfbp/lib/graph';
import { StartNode } from 'arkfbp/lib/startNode';
import { StopNode } from 'arkfbp/lib/stopNode';
import { StoreCheckedData } from './nodes/StoreCheckedData'
export class Main extends Flow {
  createNodes() {
    return [{
      cls: StartNode,
      id: '1',
      next: '2'
    }, {
      cls: StoreCheckedData,
      id: '2',
      next: '3'
    }, {
      cls: StopNode,
      id: '3'
    }];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }

}