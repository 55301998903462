import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import moreInfo from './modules/moreInformation'
import submittedStructures from './modules/submittedStructures'
import searchBySomeway from './modules/searchBySomeway'
import formulaInfo from './modules/formulaInfo'
import homeData from './modules/outline'
import compoundID from './modules/compoundID'
import user from "./modules/user"
import lang from "./modules/lang"
import checkedBoxData from "./modules/checkedBoxData"
import bZone from "./modules/brillouinZone"
import matDataDetails from "./modules/matDataDetails"
import submittedHistory from "./modules/submittedHistory"
import runUploadFile from "./modules/runUploadFile"
import runHistoryFile from "./modules/runHistoryFile"
import structIdGrobal from "./modules/storeStructID"
import login from "./modules/login"
import account from "./modules/account"
import xRayData from "./modules/xRayData"
import xRayDataInitial from "./modules/xRayDataInitial"
import xRayDataBg from "./modules/xRayDataBg"
import matDataPhaseDiagram from "./modules/matDataPhaseDiagram"
import isReset from "./modules/isResetLayout"
import periodic from "./modules/periodic"
import bandData from "./modules/bandStructureData"
import stableTableData from "./modules/stableTableData"
import teamList from "./modules/teamData"
import runSubmit from "./modules/runSubmitFile"
import cifFile from "./modules/cifFile"
import matchBySomeway from "./modules/matchBySomeway"
import matchBoxData from "./modules/matchBoxData"
import ecwSearchBySomeway from "./modules/ecwSearchBySomeways"
import ecwCheckedBoxData from "./modules/ecwCheckedBoxData"
import ecwDetails from "./modules/ecwDetails"
import interfaceReaction from "./modules/interfaceReaction"
import pldDetail from "./modules/pldDetail"
import irvsp from "./modules/irvsp"
import tbData from "./modules/tbData";
export default new Vuex.Store({
  state: {
    uploadFile: {},
    chemicalElement: {
      chemical_name: "",
      chemical_id: 0,
    },
    x_ray_data: require("@/assets/xrd_handle.json"),
  },
  modules: {
    user,
    login,
    account,
    moreInfo,
    submittedStructures,
    searchBySomeway,
    formulaInfo,
    homeData,
    compoundID,
    lang,
    checkedBoxData,
    bZone,
    matDataDetails,
    submittedHistory,
    runUploadFile,
    runHistoryFile,
    structIdGrobal,
    xRayData,
    xRayDataInitial,
    xRayDataBg,
    matDataPhaseDiagram,
    isReset,
    periodic,
    bandData,
    stableTableData,
    teamList,
    runSubmit,
    cifFile,
    matchBySomeway,
    matchBoxData,
    ecwSearchBySomeway,
    ecwCheckedBoxData,
    ecwDetails,
    interfaceReaction,
    pldDetail,
    irvsp,
    tbData,
  },
});
