import { render, staticRenderFns } from "./MoreTable.vue?vue&type=template&id=bbe16270&scoped=true&"
import script from "./MoreTable.vue?vue&type=script&lang=ts&"
export * from "./MoreTable.vue?vue&type=script&lang=ts&"
import style0 from "./MoreTable.vue?vue&type=style&index=0&id=bbe16270&lang=scss&scoped=true&"
import style1 from "./MoreTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbe16270",
  null
  
)

export default component.exports