















import { Component, Vue } from "vue-property-decorator";
import AboutContainer from "@/components/about/AboutContainer.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { GetAboutInfo } from "@/flows/aboutInfo";
@Component({
  components: { AboutContainer },
})
export default class Advisory extends Vue {
  advisoryInfo = "";
  mounted() {
    this.getInfo();
  }
  getInfo() {
    runWorkflow(new GetAboutInfo(), {}).then((data) => {
      this.advisoryInfo = data.advisory_board;
    });
  }
}
