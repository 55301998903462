
























import Vue from 'vue'
export default Vue.extend({
    name: "Irvsp",
    props: {
        irvsp_data: Object,
    },

})
