import { APINode } from "arkfbp/lib/apiNode";

export class GetOldSmsToken extends APINode {
  mode = "direct";
  url = "/api/arkid/login_get_sms_token/";
  method = "post";

  async buildParams() {
    return { mobile: this.inputs.mobile,code:this.inputs.code };
  }
}
