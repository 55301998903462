export default {
    state: {
      search_by_someway_gather: [],
      search_by_someway_time: "",
      search_by_someway_len: ""
    },
    mutations: {
      updateSearchBySomeway(state: any, data: any) {
        state.search_by_someway_gather = data;
      },
      addCurrentTime(state: any, data: any) {
        state.search_by_someway_time = data
      },
      getLenBySomeway(state: any, data: any) {
        state.search_by_someway_len = data.length
      }
    },
    actions: {
      ecwUpdateBySomeway(context: any, data: any) {
        context.commit("updateSearchBySomeway", data);
      },
      ecwAddTime(context: any) {
        const date = new Date();
        const near_cur = date.getFullYear();
        const month_cur = date.getMonth() + 1;
        const day_cur = date.getDate();
        const time_cur = near_cur + '-' + month_cur + '-' + day_cur
        context.commit("addCurrentTime", time_cur)
      },
      ecwGetLen(context: any, data: any) {
        context.commit("getLenBySomeway", data)
      }
    },
  };
  