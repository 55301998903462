import { AuthApiNode } from "@/nodes/authApiNode";

export class GetUserInfoNode extends AuthApiNode {
  mode = "direct";
  url = "/api/arkid/get_user_infor/";
  method = "get";

  async run() {
    this.$state.commit((state: any) => {
      state.store = this.inputs.store;
      return state;
    });
    return super.run();
  }
}
