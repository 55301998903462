import { AuthApiNode } from "@/nodes/authApiNode";

export class ChangeMobileNode extends AuthApiNode {
  mode = "direct";
  url = "/api/arkid/change_mobile/";
  method = "post";

  async buildParams() {
    const old_mobile_sms_token = this.$state.fetch().store.state.account
      .old_sms_token;
    const new_mobile_sms_token = this.inputs.sms_token;
    return {
      old_mobile_sms_token: old_mobile_sms_token,
      new_mobile_sms_token: new_mobile_sms_token,
    };
  }
}
