












import Vue from "vue";
export default Vue.extend({
  name: "HomeThreeData",
  props: {
    atomlyData: Object,
    firstProp: String,
    title: String
  },
  computed:{
    img_src(): String{
      switch(this.firstProp){
        case 'compounds':
          return require("@/assets/index/img-compounds.png");
        case 'bandStructures':
          return require("@/assets/index/img-band-structures.png");
        case 'phaseDiagrams':
          return require("@/assets/index/img-phase-diagrams.png");
      }
      return ''
    },
  },
  methods: {
    // 给数字的中间加上逗号
    formatNumber(num: Number) {
      if(!num)
        return '0';
      const str = num.toString();
      const reg = /(\d)(?=(?:\d{3})+$)/g;
      const val = str.replace(reg, "$1,");
      return val;
    },
  },
});
