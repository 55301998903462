export default {
  state: {
    checked_box_data: [
      {
        name: "Struct_id",
        checked: true
      },
      {
        name: "Formula",
        checked: true
      },
      {
        name: "Spacegroup",
        checked: true
      },
      {
        name: "Formation Energy",
        checked: true
      },
      {
        name: "E Above Hull",
        checked: true
      },
      {
        name: "Band Gap",
        checked: true
      },
      {
        name: "Nistes",
        checked: true
      },
      {
        name: "E Fermi",
        checked: false
      },
      {
        name: "Crystal System",
        checked: false
      },
      {
        name: "Lattice Type",
        checked: false
      },
      {
        name: "Has Band",
        checked: false
      },
      {
        name: "Hubbard",
        checked: false
      },
      {
        name: "Has Hse",
        checked: false
      },
      {
        name: "Has Epsilon",
        checked: false
      },
      {
        name: "Has Piezo",
        checked: false
      },
      {
        name: "Has Elastic",
        checked: false
      }
    ]
  },
  mutations: {
    updateCheckedBoxData(state: any, data: any) {
      state.checked_box_data = data;
    }
  },
  actions: {
    updateCheckedBox(context: any, data: any) {
      context.commit("updateCheckedBoxData", data);
    }
  }
};
