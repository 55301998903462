import { FunctionNode } from 'arkfbp/lib/functionNode'
export class StoreImgInfo extends FunctionNode {
  async run() {
    const _store = this.$state.fetch().store
    const err = this.inputs.error
    if(err == "false") {
      _store.dispatch("saveUploadFile", this.inputs)
      _store.dispatch("saveFileStructure", this.inputs.structure)
    }
    return this.inputs
  }
}