import { FunctionNode } from 'arkfbp/lib/functionNode'
import processSearchTableResult from '@/utils/ecwSearch'
export class RenderData extends FunctionNode {

  async run() {
    const _store = this.$state.fetch().store
    let _data;
    if (this.inputs.results && this.inputs.results.length > 0) {
      _data = processSearchTableResult(this.inputs.results)
    } else {
      _data = []
    }
    _store.dispatch("ecwUpdateBySomeway", _data)
    _store.dispatch("ecwAddTime")
    _store.dispatch("ecwGetLen", {
      length: this.inputs.len
    })
    return this.inputs
  }
}