export default {
  state: {
    // 判断页面的尺寸大小是否需要加载新的样式
    isResetLayout: false,
    // 存储当前页面的宽度值
    currentPageWidth: document.documentElement.clientWidth
  },
  mutations: {
    resetLayoutData (state: any, data: any) {
      state.isResetLayout = data
    },
    saveCurrentPageWidthData(state: any, data: any) {
      state.currentPageWidth = data
    }
  },
  actions: {
    resetLayout (context: any, data: any) {
      context.commit("resetLayoutData", data);
    },
    saveCurrentPageWidth(context: any, data: any) {
      context.commit("saveCurrentPageWidthData", data);
    }
  }
}