















import Vue from "vue";
import CompoundAnalysis from "./CompoundAnalysis.vue";
export default Vue.extend({
  name: "CompoundID",
  components: {
    CompoundAnalysis,
  },
  props: {
    compounds: String,
    compounds_id: String,
  },
});
