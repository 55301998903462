export default {
    state: {
      ecw_des: {
        struct_id: new String(),
        formula: new String(),
        reduction_onset: new Number(),
        oxidation_onset: new Number(),
        work_ion: new String(),
        decomposition_table_data: new Array(),
        ref_miu: new Array(),
        voltage: new Array(),
        rxn_energy: new Array(),
        hovertext: new Array(),
        d_e_reduction_onset: new Number(),
        d_e_oxidation_onset: new Number(),
        transition_indexes: new Object(),
      },
    },
    mutations: {
      saveEcwDetails_(state: any, data: any) {
        state.ecw_des = data;
      },
    },
    actions: {
      saveEcwDetails(context: any, data: any) {
        context.commit("saveEcwDetails_", data);
      },
    },
  };