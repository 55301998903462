<template>
  <div>
    <div
      id="compounds-group"
      v-html="formula"
    ></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Divider } from 'element-ui';
import formatFormula from '@/utils/formatFormula'
export default Vue.extend({
  name: "Compound",
  props: {
    compound_string: String
  },
  computed: {
    formula: {
      get: function () {
        if (this.compound_string) {
          return formatFormula(this.compound_string)
        } else {
          return '-'
        }
      }
    }
  },
})
</script>

<style lang="scss" scoped>
#compounds-group {
  display: inline;
}
</style>
