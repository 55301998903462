import { APINode } from "arkfbp/lib/apiNode";

export class WxLoginNode extends APINode {
  mode = "direct";
  url = "/api/arkid/wechat/login/";
  method = "post";

  async buildParams() {
    sessionStorage.setItem("third_party", "weChat");
    const wxredirect_uri = window.location.href.replace("#/", "");
    return { redirect_uri: wxredirect_uri };
  }
}
