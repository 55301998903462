import { AuthApiNode } from "@/nodes/authApiNode"

export class GetDataById extends AuthApiNode {
  method = 'post'
  mode = 'direct'
  url = '/api/ecwindow/search_by_id/'

  buildParams = async () => {
    this.$state.commit((state: any) => {
      state.store = this.inputs.search_store
      return state
    })
    let params_obj = { 
      include: "",
      collapse_work_ion: "",
      collapse_excluded_elements: "",
      collapse_included_elements: "",
      collapse_Reduction: Array(),
      collapse_Oxidation: Array(),
      collapse_ehull: Array(),
      collapse_bandgap: Array(), 
    }
    params_obj.include = this.inputs.include
    params_obj.collapse_work_ion = this.inputs.collapse_work_ion
    params_obj.collapse_excluded_elements = this.inputs.collapse_excluded_elements
    params_obj.collapse_included_elements = this.inputs.collapse_included_elements
    params_obj.collapse_Reduction = this.inputs.collapse_Reduction
    params_obj.collapse_Oxidation = this.inputs.collapse_Oxidation
    params_obj.collapse_ehull = this.inputs.collapse_ehull
    params_obj.collapse_bandgap = this.inputs.collapse_bandgap
    return params_obj
  }
}