

































import Vue from "vue";

export default Vue.extend({
  props: {
    btnText: String,
    getCodeHandler: Function,
    submitHandler: Function,
  },
  data() {
    return {
      mobile: "",
      code: "",
      codeBtn: this.$t("placeholder.sendCode"),
      regMobile: /^1[3|4|5|6|7|8][0-9]{9}/,
      regCode: /^\d{6}$/,
      waitTime: 61, //验证码按钮失效时间
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.codeBtn = this.$t("placeholder.sendCode");
    },
  },

  computed: {
    // 校验手机号码
    testMobile() {
      if (this.regMobile.test(this.mobile)) {
        return true;
      }
      return false;
    },
    // 校验手机号&&Code
    testMobileCode() {
      if (this.regMobile.test(this.mobile) && this.regCode.test(this.code)) {
        return true;
      }
      return false;
    },
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 61) {
          if (this.mobile) {
            return false;
          }
          return true;
        }
        return true;
      },
      set() {},
    },
  },
  methods: {
    //获取验证码
    getCodeFunction() {
      if (!this.testMobile) {
        return;
      }
      this.getCodeHandler(this.mobile);
      // 用到了定时器，需要保存this指向
      let that = this;
      that.waitTime--;
      that.getCodeBtnDisable = true;
      this.codeBtn = `${this.waitTime}s`;
      let timer = setInterval(function() {
        if (that.waitTime > 1) {
          that.waitTime--;
          that.codeBtn = `${that.waitTime}s`;
        } else {
          clearInterval(timer);
          that.codeBtn = that.$t("placeholder.sendCode");
          that.getCodeBtnDisable = false;
          that.waitTime = 61;
        }
      }, 1000);
    },
    //获取Smstoken
    submitFunction() {
      if (!this.testMobileCode) {
        return;
      }
      this.submitHandler(this.mobile, this.code);
    },
  },
});
