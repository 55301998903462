import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { MobileCodeRequestNode } from "./nodes/MobileCodeRequestNode";
import { HandleCodeNode } from "./nodes/HandleCodeNode";
import { RegisterCodeRequestNode } from "./nodes/RegisterCodeRequestNode";
export class MobileCode extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "start",
        next: "1",
      },
      {
        cls: MobileCodeRequestNode,
        id: "1",
        next: "2",
      },
      {
        cls: HandleCodeNode,
        id: "2",
        positiveNext: "3",
        negativeNext: "end",
      },
      {
        cls: RegisterCodeRequestNode,
        id: "3",
        next: "end",
      },
      {
        cls: StopNode,
        id: "end",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
