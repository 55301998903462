export default {
  state: {
    userid: "", //用户id
    username: "",
    userType: "",
    userAvatar: "",
    userMobile: "",
    userAddress: "",
    userDes: "",
    userOcc: "",
    userWeChatId: "", //微信Id
    userGithubId: "", //Git Id
  },
  getters: {
    isLogin: (state: any): boolean => {
      return state.userid != "";
    },
  },
  mutations: {
    setUserMobile(state: any, mobile: string) {
      state.userMobile = mobile;
    },
    setGithubId(state: any, github_id: string) {
      state.userGithubId = github_id;
    },
    setWechatId(state: any, wechat_id: string) {
      state.userWeChatId = wechat_id;
    },
    setUserInfo(state: any, data: any) {
      state.userid = data.username;
      state.username = data.name;

      state.userType = data.is_extern_user;
      state.userAvatar = data.avatar;
      state.userMobile = data.mobile;
    },
    setUserDetail(state: any, data: any) {
      state.userAddress = data.custom_user.data.url;
      state.userDes = data.custom_user.data.des;
      state.userOcc = data.custom_user.data.occ;
    },
    setUserId(state: any, data: any) {
      state.userid = data;
    },
    setUserAvatar(state: any, data: any) {
      state.userAvatar = data;
    },
  },
  actions: {
    setUserInfo(context: any, data: any) {
      
      if (data.detail != "Invalid token") {
        context.commit("setUserInfo", data);
      } 

      function isnotEmpty(obj: any) {
        if (typeof obj == "undefined" || obj == null || obj == "") {
          return false;
        } else {
          return true;
        }
      }
      if (isnotEmpty(data.github_user)) {
        context.commit("setGithubId", data.github_user.github_user_id);
      }
      if (isnotEmpty(data.wechat_user)) {
        context.commit("setWechatId", data.wechat_user.unionid);
      }
      if (isnotEmpty(data.custom_user)) {
        context.commit("setUserDetail", data);
      }
    },
  },
};
