export default {
    state: {
      match_by_someway_gather: [],
      match_by_someway_len: "",
      match_crystal_size: "",
      match_radiation: "",
    },
    mutations: {
      updateMatchBySomeway(state: any, data: any) {
        state.match_by_someway_gather = data;
        state.match_by_someway_len = data.length;
      },
      getXrdLenBySomeway(state: any, data: any) {
        state.match_by_someway_len = data.length
      },
      getXrdCrystalSize(state: any, data: any) {
        state.match_crystal_size = data
      },
      getXrdRadiation(state: any, data: any) {
        state.match_radiation = data
      },
    },
    actions: {
      updateXrdBySomeway(context: any, data: any) {
        context.commit("updateMatchBySomeway", data);
      },
      getXrdLen(context: any, data: any) {
        context.commit("getXrdLenBySomeway", data)
      },
      getXrdCSize(context: any, data: any) {
        context.commit("getXrdCrystalSize", data)
      },
      getRadiation(context: any, data: any) {
        context.commit("getXrdRadiation", data)
      },
    },
  };
  