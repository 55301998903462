import { AuthApiNode } from "@/nodes/authApiNode";
export class GetDetails extends AuthApiNode {

  mode = "direct"
  method = "post"
  url = "/api/irvsp/fetch_irvsp/"

  buildParams = async () => {
    let params_obj = { struct_id: "" }
    params_obj.struct_id = this.$state.fetch().struct_id_;
    return params_obj
  }
  
}