export default {
  state: {
    run_history_data: new Array(),
    run_history_user_data: new Object()
  },
  mutations: {
    updateRunHistoryData(state: any, data: any) {
      state.run_history_data = data.data;
      state.run_history_user_data = data.user_data;
    },
  },
  actions: {
    updateRunHistory(context: any, data: any) {
      context.commit("updateRunHistoryData", data);
    },
  },
};
