import { APINode } from "arkfbp/lib/apiNode";

export class RegisterCodeRequestNode extends APINode {
  mode = "direct";
  url = "/api/arkid/register_request_sm/";
  method = "post";

  async buildParams() {
    const mobile = this.$state.fetch().mobile;
    return { mobile: mobile };
  }
}