



























































import Vue from "vue";
import NavHead from "@/components/NavHead.vue";
import NavFooter from "@/components/NavFooter.vue";
import PeriodicTable from "@/components/matdata/PeriodicTable.vue";
//import BandComponent from "@/components/matdatades/BandComponent.vue";
import Diagram from "@/components/pd/Diagram.vue";
import SecStableTable from "@/components/SecStableTable.vue";
import ButtonCpn from "@/components/public/operateBox/ButtonCpn.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main } from "@/flows/getPhaseDiagram";
import {
  CapticalNum,
  isInArray,
  IsUpper,
  IsNumber,
  response,
  analyzeFormula,
  analyzeGererate,
  IsInPeriodicTable,
  cleanInput,
} from "@/views/matdata/SearchInput";

export default Vue.extend({
  components: {
    NavHead,
    NavFooter,
    PeriodicTable,
    SecStableTable,
    //BandComponent,
    Diagram,
    ButtonCpn,
  },
  data() {
    return {
      navData: {
        bgc_scroll: "rgba(224,224,224,0.4)",
        bgc_unscroll: "rgba(224,224,224,0.4)",
        sbgc_scroll: "rgba(255,255,255,0.5)",
        sbgc_unscroll: "rgba(255,255,255,0.1)",
        border_color: "#BDBDBD",
        color_unscroll: "#000",
        color_scroll: "#000",
      },
      searchValue: "",
      searchNumber: 0,
      isResultGenerateData: false,
      hasSearchData: false,
      flag: true,
      generateNumber: 0,
      isRotated: false,
      timer: 0,
    };
  },
  computed: {
    bandData: {
      get: function() {
        return this.$store.state.bandData.band_data;
      },
    },
    stableData: {
      get: function() {
        return this.$store.state.stableTableData.stableData || [];
      },
    },
    unstableData: {
      get: function() {
        return this.$store.state.stableTableData.unstableData || [];
      },
    },
  },
  watch: {
    searchValue(val) {
      if (val !== "") {
        this.flag = false;
      }
    },
  },
  created() {
    this.keyupEnterEvent();
  },
  methods: {
    // ---------------    scroll ---------------
    scrollInSomeCondition() {
      const distance = document.documentElement.scrollTop + document.documentElement.clientHeight;
      if (distance >= document.documentElement.scrollHeight) {
        clearInterval(this.timer);
      }
      if (document.documentElement.scrollTop < 500) {
        window.scrollBy(0, 5);
      } else {
        clearInterval(this.timer);
      }
    },
    // -------------- keyup enter --------------
    keyupEnterEvent() {
      document.onkeydown = (e) => {
        let body = document.getElementsByTagName('body')[0];
        if (e.keyCode === 13 && e.target === body && (e.target as any).baseURI.match('/phase')) {
          this.generate();
        }
      }
    },
    getGenerateInput(value: string) {
      this.searchValue = this.searchValue.concat(value + " ");
      const generate_value = this.searchValue.slice(
        0,
        this.searchValue.length - 1
      );
      let arr = generate_value.split(" ");
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i] == arr[arr.length - 1]) {
          arr.pop();
          arr.splice(i, 1);
          break;
        }
      }
      const _arr = arr.map((item: any) => item + " ");
      this.searchValue = _arr.join("");
      response(arr);
    },
    getGenerateValue() {
      const g_val = this.searchValue.trim();
      this.generateNumber = g_val.split(" ").length;
      return g_val;
    },
    generate() {
      this.isRotated = true;
      this.hasSearchData = true;
      this.flag = true;
      this.isResultGenerateData = false;
      runWorkflow(new Main(), {
        phase_formula: this.getGenerateValue(),
        phase_number: this.getGenerateValue().split(" ").length,
        phase_store: this.$store,
      }).then((data) => {
        this.flag = false;
        this.hasSearchData = false;
        this.isRotated = false;
        // this.timer = setInterval(this.scrollInSomeCondition, 5);
        if (data == null) {
          this.$router.go(0);
        } else {
          this.isResultGenerateData = true;
        }
      }).catch((err) => {});
    },
    changeGererateValue() {
      const arr = analyzeGererate(this.searchValue);
      response(arr);
      for (var i = 0; i < arr.length; i++) {
        if (!IsInPeriodicTable(arr[i])) {
          this.flag = true;
        } else {
          this.flag = false;
        }
      }
    },
    cleanSearchInput() {
      cleanInput(this.searchValue);
      this.searchValue = "";
    },
  },
});
