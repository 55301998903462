import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/element.js";
import VueI18n from "vue-i18n";

//@ts-ignore
var _hmt = _hmt || [];
//@ts-ignore
window._hmt = _hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?9570722e54443d71237b51fdc34ce58b";
  var s = document.getElementsByTagName("script")[0]; 
  //@ts-ignore
  s.parentNode.insertBefore(hm, s);
})();

Vue.config.productionTip = false;
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem("languageSet") || "zh",
  messages: {
    zh: require("./utils/language/zh"),
    en: require("./utils/language/en"),
  },
});

router.beforeEach((to, from, next) => {
    if(localStorage.getItem("userToken")) {
      if (to.path == '/') {
        next('/matdata')
      } else {
        next()
      }
    } else {
      if (to.path == '/' || to.path == '/atomly' || to.path == '/team' || 
          to.path == '/milestone' || to.path == '/advisory' ||
          to.path == '/publication' || to.path == '/about') {
        next()
      }
      else {
        next('/')
      }
    }
  
  //baidu statistics
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', to.fullPath])
    }
  }
  next()

})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

