import { IFNode } from "arkfbp/lib/ifNode";

export class LoginOrRegisterNode extends IFNode {
  condition() {
    this.$state.commit((state: any) => {
      state.mobile = this.inputs.mobile;
      state.code = this.inputs.code;
      state.store = this.inputs.store;
      return state;
    });
    const loginOrRegister = this.$state.fetch().store.state.login.loginOrRegister;
    return loginOrRegister.data == "login";
  }

  positive() {
    return {};
  }

  negative() {
    return {};
  }
}
