import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import {WxLoginNode} from "./nodes/WxLoginNode"
import {WxHandleNode } from "./nodes/WxHandleNode"
export class WxLoginRequest extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "1",
        next: "2",
      },
      {
        cls: WxLoginNode,
        id: "2",
        next: "3",
      },
      {
        cls: WxHandleNode,
        id: "3",
        next: "enc",
      },
      {
        cls: StopNode,
        id: "end",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
