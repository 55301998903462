import { render, staticRenderFns } from "./PeriodicBox.vue?vue&type=template&id=509fdf04&scoped=true&"
import script from "./PeriodicBox.vue?vue&type=script&lang=ts&"
export * from "./PeriodicBox.vue?vue&type=script&lang=ts&"
import style0 from "./PeriodicBox.vue?vue&type=style&index=0&id=509fdf04&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509fdf04",
  null
  
)

export default component.exports