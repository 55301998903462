
































import Vue from "vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main } from "@/flows/displayOrHideBtn";
export default Vue.extend({
  name: "DisplayHideButton",
  data() {
    return {
      isDrop: false,
      checkBoxValue: [
        {
          name: "Struct_id",
          checked: true
        },
        {
          name: "Formula",
          checked: true
        },
        {
          name: "Spacegroup",
          checked: true
        },
        {
          name: "Formation Energy",
          checked: true
        },
        {
          name: "E Above Hull",
          checked: true
        },
        {
          name: "Band Gap",
          checked: true
        },
        {
          name: "Nsites",
          checked: true
        },
        {
          name: "E Fermi",
          checked: false
        },
        {
          name: "Crystal System",
          checked: false
        },
        {
          name: "Lattice Type",
          checked: false
        },
        {
          name: "Has Band",
          checked: false
        },
        {
          name: "Hubbard",
          checked: false
        },
        {
          name: "Has Hse",
          checked: false
        },
        {
          name: "Has Epsilon",
          checked: false
        },
        {
          name: "Has Piezo",
          checked: false
        },
        {
          name: "Has Elastic",
          checked: false
        }
      ],
    };
  },
  methods: {
    checkItem(e: any) {
      this.checkBoxValue[e.target.id].checked = e.target.checked;
      runWorkflow(new Main(), {
        checked_box: this.checkBoxValue,
        checked_store: this.$store,
      }).then();
    },
  },
});
