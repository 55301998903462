


























import { Component, Vue } from "vue-property-decorator";
import LoginFooter from "@/components/login/LoginFooter.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { WxLoginRequest } from "@/flows/wxLoginRequest";
import { GitLoginRequest } from "@/flows/gitLoginRequest";

@Component({
  components: { LoginFooter },
})
export default class BindAccount extends Vue {
  bindWx() {
    runWorkflow(new WxLoginRequest(), {});
  }
  bindGit() {
    runWorkflow(new GitLoginRequest(), {});
  }
  skip() {
    this.$store.state.login.loginShow = false;
    window.location.href = this.$route.path.replace("/", "/#/");
  }
}
