import { Flow } from 'arkfbp/lib/flow';
import { Graph } from 'arkfbp/lib/graph';
import { StartNode } from 'arkfbp/lib/startNode';
import { StopNode } from 'arkfbp/lib/stopNode';
import { About } from './nodes/About';
import { RenderNode } from './nodes/RenderNode';
export class Main extends Flow {
  createNodes() {
    return [{
      cls: StartNode,
      id: '1',
      next: '2'
    }, {
      cls: About,
      id: '2',
      next: '3',
      x: 76.9878108373429,
      y: 188.9999936819079
    }, {
      cls: RenderNode,
      id: '3',
      next: '4',
      x: 164.96498857534658,
      y: 36.99999972184501
    }, {
      cls: StopNode,
      id: '4',
      x: 352.91623192471815,
      y: 38.999999642372146
    }];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }

}