












































import Vue from 'vue';
import NavHead from "@/components/NavHead.vue";
import NavFooter from "@/components/NavFooter.vue";
import CompoundID from "@/components/matdatades/CompoundID.vue";
import TitleBox from "@/components/public/titleBox/TitleBox.vue";
import EcwDisplayWindow from "@/components/ecwindow/EcwDisplayWindow.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main } from "@/flows/ecwDataDes";
export default Vue.extend({
    components: {
        NavHead,
        NavFooter,
        CompoundID,
        TitleBox,
        EcwDisplayWindow
    },
    data() {
        return {
            navData: {
                bgc_unscroll: "rgba(224, 224, 224, 0.4)",
                bgc_scroll: "rgba(224, 224, 224, 0.4)",
                sbgc_scroll: "rgba(255,255,255,0.5)",
                sbgc_unscroll: "rgba(255,255,255,0.1)",
                border_color: "#BDBDBD",
                color_unscroll: "#000",
                color_scroll: "#000",
            },
        }
    },
    computed: {
        compound_str: {
            get: function () {
                return this.$store.state.ecwDetails.ecw_des.formula || "--"
                
            }
        },
        compound_id: {
            get: function () {
                return this.$store.state.ecwDetails.ecw_des.struct_id || "--"
            }
        },
        ecw_data: {
            get: function () {
                return {
                    'ref_miu': this.$store.state.ecwDetails.ecw_des.ref_miu,
                    'voltage': this.$store.state.ecwDetails.ecw_des.voltage,
                    'rxn_energy': this.$store.state.ecwDetails.ecw_des.rxn_energy,
                    'reduction_onset': this.$store.state.ecwDetails.ecw_des.reduction_onset,
                    'oxidation_onset': this.$store.state.ecwDetails.ecw_des.oxidation_onset,
                    'hovertext': this.$store.state.ecwDetails.ecw_des.hovertext,
                    'd_e_reduction_onset': this.$store.state.ecwDetails.ecw_des.d_e_reduction_onset,
                    'd_e_oxidation_onset': this.$store.state.ecwDetails.ecw_des.d_e_oxidation_onset,
                }
            }
        },
        tableData: {
            get: function (): any {
                return this.$store.state.ecwDetails.ecw_des.decomposition_table_data;
            },
        },
        transition_indexes: {
            get: function (): any {
                return this.$store.state.ecwDetails.ecw_des.transition_indexes;
            }
        }
    },
    methods: {
        tableRowClassName({row, rowIndex}: any) {
            if (rowIndex === this.transition_indexes.reduction_onset || rowIndex === this.transition_indexes.oxidation_onset) {
                return 'warning-row';
            }
        },
        judgeRequestParams() {
            const _id: any = this.$route.params.id;
            const work_ion: any = this.$route.params.work_ion;
            this.matDataRunFlow(_id, work_ion);
        },
        matDataRunFlow(struct_id_value: any, work_ion_type: any) {
            runWorkflow(new Main(), {
                struct_id: struct_id_value,
                work_ion: work_ion_type,
                detail_store: this.$store,
            }).then((data) => {
                
            });
        },
    },
    mounted() {
        this.judgeRequestParams();
    }
})
