import formatFormula from "@/utils/formatFormula"
export default function processXrdSearchTable (before_arr: Array<any>) {
  let after_arr = new Array()
    before_arr.forEach((item: any) => {
      let temp = new Object();
      temp = {
        struct_id: item[1] || "-",
        formula: formatFormula(item[2]),
        space_group: item[3] || "-",
        nsites: item[4] || "-",
        ehull: item[5]  || "-",
        distance: item[0] || "-",
      };
      after_arr.push(temp);
    })
    return after_arr;
}