import formatFormula from "@/utils/formatFormula"
export default function processSearchTableResult(before_arr: Array<any>) {
  let after_arr = new Array()
    before_arr.forEach((item: any) => {
      let temp = new Object();
      temp = {
        struct_id: item[0],
        formula: formatFormula(item[1]),
        reduction_onset: item[2],
        oxidation_onset: item[3],
        work_ion: item[4],
        ehull: item[5] === 0 ? "0" : item[5] || "-",
        bandgap: item[6] === 0 ? "0" : item[6] || "-",
        nsites: item[7],
        spacegroup: item[8],
        crystal_system: item[9],
        pointgroup: item[10],
        hall: item[11]
      };
      after_arr.push(temp);
    })
    return after_arr;
}