








import Vue from 'vue';
// @ts-ignore
import Plotly from "plotly.js-dist";
export default Vue.extend({
    props: {
        interfaceData: Object
    },
    watch: {
        interfaceData(data: any){
            var atomic_fraction = data.atomic_fraction;
            var reaction_energy = data.reaction_energy;
            var atomic_title = data.atomic_title;
            var molar_title = data.molar_title;
            var molar_text = data.molar_text;
            var text = data.text;

            var d_min = Math.min.apply(null, reaction_energy) - 0.01;

            var trace = {
                x: atomic_fraction,
                y: reaction_energy,
                showlegend: false,
                xaxis: 'x',
                hoverinfo: 'skip',
                name: 'atomic',
            };
            var trace1 = {
                x: atomic_fraction,
                y: reaction_energy,
                showlegend: false,
                xaxis: 'x2',
                type: 'scatter',
                name: 'molar',
                text: text,
            };
            var plotly_data = [
                trace1, trace
            ];
            var layout = {
                margin: {
                    b: 50,
                    r: 30,
                    t: 70
                },
                xaxis: {
                    title: atomic_title,
                    autorange: false,
                    side: 'top',
                    ticks: 'outside',
                    range: [-0.05, 1.005],
                    anchor: 'free',
                    position: 1,
                    zeroline: false,
                    showgrid: false,
                    //automargin: true,
                    showline: true,
                    linecolor: 'rgb(0,0,0)',
                },
                xaxis2: {
                    title: molar_title,
                    autorange: false,
                    ticks: 'inside',
                    range: [-0.05, 1.005],
                    zeroline: false,
                    showgrid: false,
                    //automargin: true,
                    showline: true,
                    linecolor: 'rgb(0,0,0)',
                    tickangle: 30,
                    tickmode: 'array',
                    ticktext: molar_text,
                    tickvals: atomic_fraction,
                    overlaying: 'x'
                },
                yaxis: {
                    title: "Reaction Energy (eV/atom)",
                    autorange: false,
                    ticks: 'outside',
                    range: [d_min,0.01],
                    zeroline: false,
                    showline: true,
                    //automargin: true,
                    linecolor: 'rgb(0,0,0)',
                    showgrid: false,
                    anchor: 'free',
                },
                autosize: true,
                hovermode: "closest",
                
            };

            //@ts-ignore
            Plotly.newPlot("ir", plotly_data, layout, {
                displayModeBar: false,
                responsive: true,
                scrollZoom: true,
                displaylogo: false
            });
        }
    }
})
