
















import Vue from "vue";
import LoginFooter from "@/components/login/LoginFooter.vue";
import LoginInput from "@/components/login/LoginInput.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { BindCode } from "@/flows/bindCode";
import { BindLogin } from "@/flows/bindLogin";

export default Vue.extend({
  components: { LoginFooter, LoginInput },

  data() {
    return {
      text: this.$t("placeholder.confirmBind"),
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.initProtocalData();
    },
  },

  methods: {
    initProtocalData() {
      this.text = this.$t("placeholder.confirmBind");
    },
    getCodeHandler(mobile: string) {
      runWorkflow(new BindCode(), {
        mobile: mobile,
        store: this.$store,
      });
    },
    submitHandler(mobile: string, code: string) {
      runWorkflow(new BindLogin(), {
        mobile: mobile,
        code: code,
        store: this.$store,
      }).then((data) => {
        if (data.token) {
          this.$store.dispatch("setUserInfo", data);
          window.localStorage.setItem("userToken", data.token);
          this.$store.state.login.loginShow = false;
          window.location.href = this.$route.path.replace("/", "/#/");
        } else {
          this.$message({
            message: "Oops,fail to login",
            type: "error",
          });
        }
      });
    },
  },
});
