





















import Vue from "vue";
import NavHead from "@/components/NavHead.vue";
import NavFooter from "@/components/NavFooter.vue";
export default Vue.extend({
  name: "ProtocalComponent",
  components: { NavHead, NavFooter },
  data() {
    return {
      navData: {
        bgc_unscroll: "rgba(224, 224, 224, 0.4)",
        bgc_scroll: "rgb(256,256,256)",
        border_color: "#BDBDBD",
        color_unscroll: "#000",
        color_scroll: "#000",
      },
    };
  },
  props: {
    protocalData: Object,
  },
  mounted() {
    this.$store.state.login.loginShow = false;
  },
});
