













import Vue from "vue";
import LoginIndex from "@/views/login/LoginIndex.vue";
import BindMobile from "@/views/login/BindMobile.vue";
import BindAccount from "@/views/login/BindAccount.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { GetUserInfo } from "@/flows/userInfoRequest";
export default Vue.extend({
  name: "login",
  components: { LoginIndex, BindMobile, BindAccount },
  data() {
    return {
      showComponent: this.$store.state.login.showComponent,
    };
  },
  computed: {
    loginShow(): boolean {
      return this.showComponent == "index";
    },
    bindMobileShow(): boolean {
      return this.showComponent == "mobile";
    },
    bindAccountShow(): boolean {
      return this.showComponent == "account";
    },
  },
  mounted() {
    this.goThirdLogin();
    Vue.prototype.toJudgeIsLogin = this.showLoginIndex;
  },
  methods: {
    goThirdLogin() {
      if (window.location.href.indexOf("?") != -1) {
        var params_str = window.location.href.split("?")[1].split("#")[0];
        var params_array = params_str.split("&");
        var params: { [key: string]: string } = {};
        for (let i in params_array) {
          var pp = params_array[i].toString().split("=");
          params[pp[0]] = pp[1];
        }
        if (params["token"] != "") {
          window.localStorage.setItem("userToken", params["token"]);
          runWorkflow(new GetUserInfo(), { store: this.$store });
          window.location.href = this.$route.path.replace("/", "/#/");
        } else {
          let third_party_id = params["third_party_id"];
          if (!third_party_id) return;
          let third_party = sessionStorage.getItem("third_party");
          switch (third_party) {
            case "github":
              this.$store.commit("setGithubId", third_party_id);
              break;
            case "weChat":
              this.$store.commit("setWechatId", third_party_id);
              break;
            default:
              return;
          }
          this.$store.state.login.loginShow = true;
          this.showBindMobile();
        }
      }
    },
    setShowComponent(value: string) {
      this.showComponent = value;
    },
    //登录首页
    showLoginIndex() {
      this.showComponent = "index";
    },
    //绑定账户
    showBindAccount() {
      this.showComponent = "account";
    },
    //绑定手机
    showBindMobile() {
      this.showComponent = "mobile";
    },
    showClose() {
        // 如果是mobile界面的绑定手机， 则不允许关闭, 强制必须绑定手机
        if (this.showComponent != "mobile") {
            this.$store.state.login.loginShow = false;
            window.location.href = this.$route.path.replace("/", "/#/");
        }
    },
  },
});
