






































































import Vue, { VNode, CreateElement } from "vue";
import { EventBus } from "../eventBus";
import NavHead from "@/components/NavHead.vue";
import RunTitle from "@/components/run4u/RunTitle.vue";
import UploadCpn from "@/components//public/operateBox/UploadCpn.vue";
import ButtonCpn from "@/components/public/operateBox/ButtonCpn.vue";
import JmolComponent from "@/components/JmolComponent.vue";
import LittleTable from "@/components/Table.vue";
import MessagePrompt from "@/components/run4u/MessagePrompt.vue";
import Message from "@/components/run4u/MessagePrompt.vue";
import LargeTable from "@/components/LargeTable.vue";
import NavFooter from "@/components/NavFooter.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main_Run_History } from "@/flows/runHistoryFlow";
import { Main_Upload_Img } from "@/flows/runUploadFlow";
import { Main_Submit_Img } from "@/flows/runSubmitFlow";
export default Vue.extend({
  name: "RunPage",
  components: {
    NavHead,
    RunTitle,
    UploadCpn,
    ButtonCpn,
    JmolComponent,
    LittleTable,
    MessagePrompt,
    Message,
    LargeTable,
    NavFooter,
  },
  data() {
    return {
      navData: {
        bgc_unscroll: "rgba(224, 224, 224, 0.4)",
        bgc_scroll: "rgba(224, 224, 224, 0.4)",
        sbgc_scroll: "rgba(255,255,255,0.5)",
        sbgc_unscroll: "rgba(255,255,255,0.1)",
        border_color: "#BDBDBD",
        color_unscroll: "#000",
        color_scroll: "#000",
      },
      upload_text_1: this.$t("run4u.upload"),
      upload_text_2: this.$t("run4u.reupload"),
      upload_file_info: this.$t("run4u.fileInfoTitle"),
      upload_file_info_1: this.$t("run4u.fileInfoContent01"),
      upload_file_info_2: this.$t("run4u.fileInfoContent02"),
      upload_file_name: "",
      isUpload: false,
      flag: false,
      submitStatus: 0,
      submit_text: this.$t("run4u.submit"),
      promptInfo: "Please Upload a structure first!",
      promptColor: "",
    };
  },
  beforeCreate() {
    if (!localStorage.getItem("userToken")) {
      this.$router.push("/");
      EventBus.$emit("toShowLoginEvent");
    }
  },
  computed: {
    getBackgroundImage: {
      get: function () {
        const _width = document.documentElement.offsetWidth;
        if (_width >= 800.01) {
          return "run4u-page";
        } else {
          return "run4u-mobile-page";
        }
      },
    },
    jmol_data_: {
      get: function () {
        return this.$store.state.runUploadFile.run_upload_file.script;
      },
    },
    first_table: {
      get: function () {
        return this.$store.state.runUploadFile.run_upload_file.lattice;
      },
    },
    second_table: {
      get: function () {
        return this.$store.state.runUploadFile.run_upload_file.symmetry;
      },
    },
    large_table_data: {
      get: function () {
        return this.$store.state.runHistoryFile.run_history_data || [];
      },
    },
  },
  watch: {
    "$i18n.locale"(language) {
      this.initLanguage();
    },
  },
  methods: {
    initLanguage() {
      this.upload_text_1 = this.$t("run4u.upload");
      this.upload_text_2 = this.$t("run4u.reupload");
      this.submit_text = this.$t("run4u.submit");
      this.upload_file_info = this.$t("run4u.fileInfoTitle");
      this.upload_file_info_1 = this.$t("run4u.fileInfoContent01");
      this.upload_file_info_2 = this.$t("run4u.fileInfoContent02");
    },
    uploadCifFile(file_data: any) {
      this.upload_file_name = file_data.name;
      let formData = new FormData();
      formData.append("cif-file", file_data.raw);
      runWorkflow(new Main_Upload_Img(), {
        upload_data: formData,
        upload_store: this.$store,
      }).then((data) => {
        this.isUpload = true;
        if (data == null) {
          return;
        }
        const _el_msg: any = this.$refs.runMsg;
        if (data.error == "false") {
          this.popMessagePromptBox(
            "right",
            "Your structure has been uploaded Successfully!"
          );
        }
        if (data.error == "true") {
          if (data.message) {
            this.popMessagePromptBox("error", data.message);
          } else {
            this.popMessagePromptBox(
              "warning",
              "Only CIF or VASP format file is valid !"
            );
          }
        }
      });
    },
    submitCifFile() {
      const file_structure = this.$store.state.runSubmit.structure;
      if (file_structure) {
        runWorkflow(new Main_Submit_Img(), {
          submit_data: true,
          structure: file_structure,
          submit_store: this.$store,
        }).then((data) => {
          if (data == null) {
            return;
          }
          const _el_upload: any = this.$refs.runUploadCpn;
          _el_upload.clearFileList();
          const msg = data.message;
          const _el_msg: any = this.$refs.runMsg;
          switch (msg) {
            case "Error":
              this.popMessagePromptBox(
                "error",
                "Please Upload a structure first!"
              );
              break;
            case "Full":
              this.popMessagePromptBox(
                "warning",
                "You can only submit Three structures for One day!"
              );
              "";
              break;
            case "Success":
              this.popMessagePromptBox(
                "right",
                "Your structure has been submitted Successfully!"
              );
              this.getHistorySubmitData();
              break;
            default:
              this.popMessagePromptBox("error", msg);
              this.getHistorySubmitData();
          }
        });
      } else {
        this.popMessagePromptBox("warning", "Please Upload a structure first!");
      }
    },
    getHistorySubmitData() {
      runWorkflow(new Main_Run_History(), {
        history_store: this.$store,
      }).then((data) => {
        const data_ = data.user_data;
        const result = {
          'script':data_.script, 
          'error':'false', 
          'detail':{
            'lattice':data_.lattice, 
            'symmetry':data_.symmetry
          }
        };
        this.$store.dispatch("saveUploadFile", result)
      });
    },
    popMessagePromptBox(msgIcon: String, msgInfo: String) {
      const _el_msg: any = this.$refs.runMsg;
      const _el_mes_: any = this.$refs.runSecMsg;
      const _this: any = this;
      _el_msg.showMessage(msgIcon, msgInfo);
      _el_mes_.showMessage(msgIcon, msgInfo);
    },
  },
  mounted() {
    this.getHistorySubmitData();
  },
});
