





































































import Vue from 'vue'
export default Vue.extend({
    name: "LargeTable",
    props: {
        tableData: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            pagerCount: 7,
        }
    },
    methods: {
        watchMatDataDes(item: any) {
            if (item.status == 'existed' || item.status == 'finished') {
                const { href } = this.$router.resolve({
                    name: "matdatades",
                    params: {
                        id: item.struct_id,
                    },
                });
                window.open(href, "_blank");
            }
        },
    }
})
