
















































































































































































































































































































































import Vue from "vue";
export default Vue.extend({
  name: "Footer",
  data() {
    return {
      isMouseEnterAPI: false,
      isDisplayFirendLink: false,
      isDisplayTerms: false,
    };
  },
  computed: {
    isLang: {
      get: function (): any {
        return localStorage.getItem("languageSet");
      },
    },
    isLogin: {
      get: function (): any {
        return this.$store.getters.isLogin;
      },
    },
  },
  methods: {
    displayFirendLink() {
      this.isDisplayFirendLink = !this.isDisplayFirendLink;
      this.isDisplayTerms = false;
    },
    displayTerms() {
      this.isDisplayTerms = !this.isDisplayTerms;
      this.isDisplayFirendLink = false;
    },
    handleEnterAPI() {
      this.isMouseEnterAPI = true;
    },
    handleLeaveAPI() {
      this.isMouseEnterAPI = false;
    },
    changeLanguage() {
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh");
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.$store.dispatch("asyncChangeLanguage");
      if (this.$route.path == "/") {
        this.$router.go(0);
      }
    },
  },
});
