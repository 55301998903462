export default {
    state: {
      irvsp_data: {
        is_topo_display: new Boolean(),
        labels: new Array(),
        topo_data_web: new Array(),
      },
    },
    mutations: {
      save_Irvsp(state: any, data: any) {
        state.irvsp_data = data;
      },
    },
    actions: {
      saveIrvsp(context: any, data: any) {
        context.commit("save_Irvsp", data);
      },
    },
  };