




































































import Vue from 'vue';
import RunTitle from "@/components/interface/RunTitle.vue";
import NavHead from "@/components/NavHead.vue";
import NavFooter from "@/components/NavFooter.vue";
import InterfaceDiagram from "@/components/interface/InterfaceDiagram.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { MainInterface } from "@/flows/interfaceReaction";
export default Vue.extend({
    name: "Interface_reaction",
    components: {
        RunTitle,
        NavHead,
        NavFooter,
        InterfaceDiagram,
    },
    data() {
        return {
            navData: {
                bgc_unscroll: "rgba(224, 224, 224, 0.4)",
                bgc_scroll: "rgba(224, 224, 224, 0.4)",
                sbgc_scroll: "rgba(255,255,255,0.5)",
                sbgc_unscroll: "rgba(255,255,255,0.1)",
                border_color: "#BDBDBD",
                color_unscroll: "#000",
                color_scroll: "#000",
            },
            reactant1: '',
            reactant2: '',
            loading: false,
            isRotated: false,
            gotData: false,
        }
    },
    computed: {
        interfaceData: {
            get: function() {
                return {
                    'atomic_fraction': this.$store.state.interfaceReaction.interface_data.atomic_fraction,
                    'reaction_energy': this.$store.state.interfaceReaction.interface_data.reaction_energy,
                    'atomic_title': this.$store.state.interfaceReaction.interface_data.atomic_title,
                    'molar_title': this.$store.state.interfaceReaction.interface_data.molar_title,
                    'molar_text': this.$store.state.interfaceReaction.interface_data.molar_text,
                    'text': this.$store.state.interfaceReaction.interface_data.text,
                }
            }
        },
        
        interface_table_data: {
            get: function() {
                return this.$store.state.interfaceReaction.interface_data.interface_table_data
            }
        },

        min_re_index: {
            get: function() {
                return this.$store.state.interfaceReaction.interface_data.min_re_index
            }
        }
    },
    methods: {
        compute() {
            this.gotData = false;
            this.loading = true;
            this.isRotated = true;
            const reactant1 = this.reactant1;
            const reactant2 = this.reactant2;
            runWorkflow(new MainInterface(), {
                reactant1: reactant1,
                reactant2: reactant2,
                interface_store: this.$store,
            }).then((data) => {
                this.loading = false;
                this.isRotated = false;
                this.gotData = true;
            })
        },

        tableRowClassName({row, rowIndex}: any) {
            if (rowIndex === this.min_re_index) {
                return 'warning-row';
            }
        },
    }
})
