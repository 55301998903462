import { FunctionNode } from "arkfbp/lib/functionNode";

export class HandleUserInfoNode extends FunctionNode {
  async run() {
    const _store = this.$state.fetch().store;
    _store.dispatch("setUserInfo", this.inputs);
    super.run();
    if (this.inputs.detail == 'Invalid token' || 
        (this.inputs.detail == 'Token has expired' && !this.inputs.mobile)){
      //localStorage.setItem('invalidToken', 'true');
      localStorage.removeItem('userToken')
    }
  }
}
