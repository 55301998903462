export default {
    state: {
      match_box_data: [
        {
          name: "Struct_id",
          checked: true
        },
        {
          name: "Formula",
          checked: true
        },
        {
          name: "Spacegroup",
          checked: true
        },
        {
          name: "Nsites",
          checked: true
        },
        {
          name: "Ehull(eV)",
          checked: true
        },
        {
          name: "Distance",
          checked: true
        },
      ]
    },
    mutations: {
      updateMatchBoxData(state: any, data: any) {
        state.checked_box_data = data;
      }
    },
    actions: {
      updateMatchBox(context: any, data: any) {
        context.commit("updateMatchBoxData", data);
      }
    }
  };
  