export {
  CapticalNum,
  isInArray,
  IsUpper,
  IsNumber,
  response,
  analyzeFormula,
  analyzeGererate,
  IsInPeriodicTable,
  cleanInput,
};
function CapticalNum(tex: any) {
  var p = 0;
  for (var i = 0; i < tex.length; i++) {
    if (/[A-Z]/.test(tex[i])) {
      p += 1;
    }
  }
  if (p >= 2) {
    return true;
  } else {
    return false;
  }
}

function isInArray(arr: any, value: any) {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
}

function IsUpper(p: any) {
  return /[A-Z]/.test(p);
}

function IsNumber(p: any) {
  return /[0-9]/.test(p);
}

function IsInPeriodicTable(value: any) {
  var periodicData = [
    "H",
    "He",
    "Li",
    "Be",
    "B",
    "C",
    "N",
    "O",
    "F",
    "Ne",
    "Na",
    "Mg",
    "Al",
    "Si",
    "P",
    "S",
    "Cl",
    "Ar",
    "K",
    "Ca",
    "Sc",
    "Ti",
    "V",
    "Cr",
    "Mn",
    "Fe",
    "Co",
    "Ni",
    "Cu",
    "Zn",
    "Ga",
    "Ge",
    "As",
    "Se",
    "Br",
    "Kr",
    "Rb",
    "Sr",
    "Y",
    "Zr",
    "Nb",
    "Mo",
    "Tc",
    "Ru",
    "Rh",
    "Pd",
    "Ag",
    "Cd",
    "In",
    "Sn",
    "Sb",
    "Te",
    "I",
    "Xe",
    "Cs",
    "Ba",
    "La",
    "Ce",
    "Pr",
    "Nd",
    "Pm",
    "Sm",
    "Eu",
    "Gd",
    "Tb",
    "Dy",
    "Ho",
    "Er",
    "Tm",
    "Yb",
    "Lu",
    "Hf",
    "Ta",
    "W",
    "Re",
    "Os",
    "Ir",
    "Pt",
    "Au",
    "Hg",
    "Tl",
    "Pb",
    "Bi",
    "Po",
    "At",
    "Rn",
    "Fr",
    "Ra",
    "Ac",
    "Th",
    "Pa",
    "U",
    "Np",
    "Pu",
    "Am",
    "Cm",
    "Bk",
    "Cf",
    "Es",
    "Fm",
    "Md",
    "No",
    "Lr",
    "Rf",
    "Db",
    "Sg",
    "Bh",
    "Hs",
    "Mt",
    "Ds",
    "Rg",
    "Cn",
    "Nh",
    "Fl",
    "Mc",
    "Lv",
    "Ts",
    "Og",
  ];
  for (var i = 0; i < periodicData.length; i++) {
    if (value == periodicData[i]) {
      return true;
    }
  }
  return false;
}
function analyzeFormula(formula: any) {
  let r: any = [];
  let d = formula.split("");
  let t = formula.split("");
  for (let i = 0; i < t.length; i++) {
    if (IsUpper(t[i]) && i != 0) {
      r = r.concat([d.slice(0, -(t.length - i))]);
      d = d.slice(-(t.length - i), d.length);
    }
  }
  r = r.concat([d]);
  for (let i = 0; i < r.length; i++) {
    for (var j = 0; j < r[i].length; j++) {
      if (IsNumber(r[i][j])) {
        r[i] = r[i].slice(0, j);
      }
    }
    r[i] = r[i].join("");
  }
  return r;
}

function analyzeGererate(formula: any) {
  let r: any = [];
  let d = formula.split("");
  let t = formula.split("");
  for (let i = 0; i < t.length; i++) {
    if (IsUpper(t[i]) && i != 0) {
      r = r.concat([d.slice(0, -(t.length - i))]);
      d = d.slice(-(t.length - i), d.length);
    }
  }
  r = r.concat([d]);
  for (let i = 0; i < r.length; i++) {
    for (var j = 0; j < r[i].length; j++) {
      if (IsNumber(r[i][j])) {
        r[i] = r[i].slice(0, j);
      }
    }
    r[i] = r[i].join("");
  }
  return r.map((item: any) => item.trim());
}
function response(item: any) {
  //console.log(item);
  const periodic: any = Array.from( document.getElementsByClassName("periodic-box") );
  const element: any = item;
  periodic.forEach((item: any) => {
    if (item.classList.contains("active")) {
      if (!isInArray(element, item.dataset.pvalue)) {
        item.classList.remove("active");
      }
    } else {
      if (isInArray(element, item.dataset.pvalue)) {
        item.classList.add("active");
      }
    }
  });
}

function cleanInput(item: any) {
  const periodic: any = Array.from( document.getElementsByClassName("periodic-box") );
  periodic.forEach((item: any) => {
    if (item.classList.contains("active")) {
      item.classList.remove("active");
    }
  });
}
