























import Vue from "vue";
export default Vue.extend({
  name: "HomeTeam",
  props: {
    teamList: Array,
  }
});
