import { APINode } from "arkfbp/lib/apiNode";

export class WeChatBindNode extends APINode {
  mode = "direct";
  url = "/api/arkid/wechat/bind/";
  method = "post";

  async buildParams() {
    const sms_token = this.$state.fetch().store.state.login.sms_token;
    const wechat_id = this.$state.fetch().store.state.user.userWeChatId;
    return {
      sms_token: sms_token,
      wechat_id: wechat_id,
    };
  }
}
