
































import Vue from "vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main } from "@/flows/ecwDisplayOrHideBtn";
export default Vue.extend({
  name: "DisplayHideButton",
  data() {
    return {
      isDrop: false,
      checkBoxValue: [
        {
          name: "Struct_id",
          checked: true,
        },
        {
          name: "Formula",
          checked: true,
        },
        {
          name: "Reduction_onset(V)",
          checked: true,
        },
        {
          name: "Oxidation_onset(V)",
          checked: true,
        },
        {
          name: "Work_ion",
          checked: true,
        },
        {
          name: "Ehull(eV)",
          checked: true,
        },
        {
          name: "Bandgap(eV)",
          checked: true,
        },
        {
          name: "Nsites",
          checked: true,
        },
        {
          name: "Spacegroup",
          checked: false,
        },
        {
          name: "Crystal_system",
          checked: false,
        },
        {
          name: "Pointgroup",
          checked: false,
        },
        {
          name: "Hall",
          checked: false,
        }
      ],
    };
  },
  methods: {
    checkItem(e: any) {
      this.checkBoxValue[e.target.id].checked = e.target.checked;
      runWorkflow(new Main(), {
        checked_box: this.checkBoxValue,
        checked_store: this.$store,
      }).then();
    },
  },
});
