import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { RequestData } from "./nodes/request";
import { ResponseData } from "./nodes/response";
export class Main_Phase_Diagram extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "1",
        next: "2",
      },
      {
        cls: RequestData,
        id: "2",
        next: "handle_user_infor",
      },
      {
        cls: ResponseData,
        id: "handle_user_infor",
        next: "4",
      },
      {
        cls: StopNode,
        id: "4",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}