








import Vue from 'vue';
// @ts-ignore
import Plotly from "plotly.js-dist";

export default Vue.extend({
    props: {
        tb_band_data: Object
    },

    watch: {
        tb_band_data(data: any){
            var plotly_data = data.plotly_data;

            var layout = {
                margin: {
                    b: 30,
                    r: 10,
                    t: 10,
                    l: 30,
                },
                xaxis: {
                    tickmode: 'array',
                    tickvals: data.hsp_nodes,
                    ticktext: data.hsp_labels,
                    showline: true,
                    ticks: 'inside',
                    overlaying: 'x',
                    side: 'bottom',
                    linecolor: "rgb(71,71,71)",
                    linewidth: 2,
                    tickwidth: 2,
                    tickfont: {size: 12},
                    mirror: "ticks",
                },
                yaxis: {
                    showline: true,
                    ticks: 'inside',
                    overlaying: 'y',
                    side: 'left',
                    linecolor: "rgb(71,71,71)",
                    linewidth: 2,
                    tickwidth: 2,
                    tickfont: {size: 12},
                    zerolinewidth: 2,
                    mirror: "ticks",
                },

                autosize: true,
                hovermode: "closest",
            }

            //@ts-ignore
            Plotly.newPlot("band", plotly_data, layout, {
                displayModeBar: false,
                responsive: true,
                scrollZoom: true,
                displaylogo: false
            });
        }
    },
})
