import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { BindSmsNode } from "./nodes/BindSmSNode";
import { HandleCodeNode } from "./nodes/HandleCodeNode";
export class BindCode extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "start",
        next: "1",
      },
      {
        cls: BindSmsNode,
        id: "1",
        next: "2",
      },
      {
        cls: HandleCodeNode,
        id: "2",
        positiveNext: "end",
        negativeNext: "end",
      },
      {
        cls: StopNode,
        id: "end",
      },
    ];
  }
  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
