import { APINode } from "arkfbp/lib/apiNode";

export class RegisterInfoNode extends APINode {
  mode = "direct";
  url = "/api/arkid/register_check_sm/";
  method = "post";

  async buildParams() {
    const mobile = this.$state.fetch().mobile;
    const sms_token = this.inputs.sms_token;
    return { mobile: mobile, sms_token: sms_token };
  }
}
