import { FunctionNode } from 'arkfbp/lib/functionNode';
import formatStableData from "@/utils/formatStableData";
import formatUnstableData from "@/utils/formatUnstableData";
export class RenderData extends FunctionNode {
  async run() {
    const _store = this.$state.fetch().store_
    _store.dispatch("saveBand", this.inputs)
    const stable_data = this.inputs.data[this.inputs.data.length - 1]
    const unstable_data = this.inputs.data[this.inputs.data.length - 2]
    const bd_data = {
      stable_data: formatStableData(stable_data),
      unstable_data: formatUnstableData(unstable_data)
    }
    _store.dispatch("saveStable", bd_data)
    return this.inputs
  }
}