





























import Vue from "vue";
import fillZero from "@/utils/fillZero"
export default Vue.extend({
  name: "StableTable",
  props: {
    stableData: Array,
    unstableData: Array,
  },
  methods: {
    toMatDataDes(item: any) {
      const formula_id = fillZero(item.id);
      const { href } = this.$router.resolve({
        name: "matdatades",
        params: {
          id: formula_id,
        },
      });
      window.open(href, "_blank");
    },
  },
});
