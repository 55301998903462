export default {
  state: {
    x_ray_data: {
      data: [
        {
          hoverinfo: "text",
          mode: "markers",
          text: [],
          x: [],
          y: []
        }
      ],
      x: [],
      y: [],
      name: ""
    }
  },
  mutations: {
    saveXRayData(state: any, data: any){
      state.x_ray_data = data
    }
  },
  actions: {
    saveXRay (context: any, data: any) {
      context.commit("saveXRayData", data);
    }
  }
}