export default {
  state: {
    home_data: {
      compounds: 0,
      band_structures: 0,
      phase_diagrams: 0,
    }
  },
  mutations: {
    saveConuts (state: any, data: any) {
      state.home_data = {
        compounds: data.co,
        band_structures: data.bs,
        phase_diagrams: data.pd
      }
    }
  },
  actions: {
    asyncSaveCounts (context: any, data: any) {
      context.commit("saveConuts", data);
    }
  }
}