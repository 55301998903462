import user from "./user";
export default {
  state: {
    loginOrRegister: "", //用户登录方式
    user: user,
    loginShow: false,
    show: false,
    showComponent: "",
    sms_token: "",
  },
  getters: {},
  mutations: {
    setloginOrRegister(state: any, data: any) {
      state.loginOrRegister = data;
    },
    setSmsToken(state: any, data: any) {
      state.sms_token = data;
    },
  },
  actions: {
    setloginOrRegister({ commit }: any, data: any) {
      commit("setloginOrRegister", data);
    },
    setSmsToken({ commit }: any, data: any) {
      commit("setSmsToken", data);
    },
  },
};
