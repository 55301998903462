export default {
  state: {
    cif_data: {
      pcif: "",
      ccif: ""
    }
  },
  mutations: {
    saveCifData (state: any, data: any) {
      state.cif_data.pcif = data.poscar,
      state.cif_data.ccif = data.conventional_cell
    }
  },
  actions: {
    saveCif (context: any, data: any) {
      context.commit("saveCifData", data);
    }
  }
}