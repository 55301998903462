












import Vue from "vue";
export default Vue.extend({
  name: "HomeThreeTitle",
  data() {
    return {
      threedataTitle01: this.$t("home.threedataTitle01"),
      threedataTitle02: this.$t("home.threedataTitle02"),
      threedataTitle03: this.$t("home.threedataTitle03"),
    }
  },
  watch: {
    '$i18n.locale'(language) {
      this.initLanguage()
    }
  },
  methods: {
    initLanguage() {
      this.threedataTitle01 = this.$t("home.threedataTitle01")
      this.threedataTitle02 = this.$t("home.threedataTitle02")
      this.threedataTitle03 = this.$t("home.threedataTitle03")
    }
  }
});
