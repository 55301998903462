import { FunctionNode } from 'arkfbp/lib/functionNode'
export class UpdateData extends FunctionNode {
  async run() {
    const _store = this.$state.fetch().store
    const _data = this.inputs
    _store.dispatch("saveMatDetails", _data)
    const _cif = {
      poscar: this.inputs.output_structs.output_struct_primitive,
      conventional_cell: this.inputs.output_structs.output_struct_conventional
    }
    _store.dispatch("saveCif", _cif)
    return this.inputs
  }
}