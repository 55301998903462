





























































import Vue from "vue";
import PeriodicTable from "@/components/matdata/PeriodicTable.vue";
import NavHead from "@/components/NavHead.vue";
import SearchTable from "@/components/SearchTable.vue";
import ButtonCpn from "@/components/public/operateBox/ButtonCpn.vue";
import NavFooter from "@/components/NavFooter.vue";
import {
  CapticalNum,
  isInArray,
  IsUpper,
  IsNumber,
  response,
  analyzeFormula,
  analyzeGererate,
  IsInPeriodicTable,
  cleanInput,
} from "@/views/matdata/SearchInput";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main_By_Elements } from "@/flows/matDataByElements";
import { Main_By_Formula } from "@/flows/matDataByFormula";
import {Main_By_Id} from "@/flows/matDataById";
import { ConeGeometry, log } from "three";
export default Vue.extend({
  components: {
    PeriodicTable,
    NavHead,
    SearchTable,
    NavFooter,
    ButtonCpn,
  },
  data() {
    return {
      navData: {
        bgc_unscroll: "rgba(224, 224, 224, 0.4)",
        bgc_scroll: "rgba(224, 224, 224, 0.4)",
        sbgc_scroll: "rgba(255,255,255,0.5)",
        sbgc_unscroll: "rgba(255,255,255,0.1)",
        border_color: "#BDBDBD",
        color_unscroll: "#000",
        color_scroll: "#000",
      },
      displaySearchValue: false,
      options: [
        {
          value: "formula",
          label: "by Formula",
        },
        {
          value: "element",
          label: "by Elements",
        },
        {
          value: "structid",
          label: "by Struct_id",
        }
      ],
      value: "element",
      searchValue: "",
      hasSearchData: false,
      flag: false,
      timer: 0,
    };
  },
  computed: {
    largeTableData() {
      return this.$store.state.submittedStructures
        .submitted_structures_data_gather;
    },
  },
  watch: {
    searchValue(val) {
      if (val == "") {
        this.flag = false;
      }
    }
  },
  
  created() {
    this.keyupEnterEvent();
  },

  beforeCreate() {
    if(!localStorage.getItem('userToken')) {
      this.$router.push("/");
    }
  },

  methods: {
    // ---------------    scroll ---------------
    // scrollTop: 滚动条滚动了多少距离;
    // clientHeight: 视野内可见的内容和内边距，不包括x轴的滚动条高度、边框、外边距;
    // scrollHeight: 所有的内容和内边距，这个内容包括肉眼看不见、溢出、被窗口遮挡的部分;
    toScrollTable() {
      const distance = document.documentElement.scrollTop + document.documentElement.clientHeight;
      if (distance < document.documentElement.scrollHeight - 10) {
        if (document.documentElement.scrollTop < 600) {
          window.scrollBy(0, 7);
        }
        else {
          clearInterval(this.timer);
        }
      } 
      else {
        clearInterval(this.timer);
      }
    },
    // --------------- keyup enter ---------------
    keyupEnterEvent() {
      document.onkeydown = (e) => {
        let body = document.getElementsByTagName('body')[0];
        if (e.keyCode === 13 && e.target === body && (e.target as any).baseURI.match('/matdata')) {
          this.search();
        }
      }
    },
    getPeriodicInput(value: string) {
      if (this.value === "formula") {
        this.cleanSearchInput();
        this.value = "element";
      }
      this.searchValue = this.searchValue.concat(value + "-");
      const search_value = this.searchValue.slice(
        0,
        this.searchValue.length - 1
      );
      let arr = search_value.split("-");
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i] == arr[arr.length - 1]) {
          arr.pop();
          arr.splice(i, 1);
          break;
        }
      }
      const _arr = arr.map((item: any) => item + "-");
      this.searchValue = _arr.join("");
      response(arr);
    },
    //-------------------------input-----------------------------
    changeSearchValue() {
      if (this.searchValue.trim() == "") {
        this.flag = false;
      }
      if (this.searchValue.length == 0) {
        this.flag = false;
        response([]);
      }
      //element
      if (this.value === "element") {
        let arr = this.searchValue.split("-");
        response(arr);
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] != "") {
            if (!IsInPeriodicTable(arr[i])) {
              this.flag = true;
            } else {
              this.flag = false;
            }
          } else {
            if (i != arr.length - 1) {
              this.flag = false;
            }
          }
        }
      }
      //formula
      if (this.value === "formula") {
        if (
          IsNumber(this.searchValue.slice(0, 1)) ||
          !IsUpper(this.searchValue.slice(0, 1))
        ) {
          this.flag = true;
        } else {
          let arr = analyzeFormula(this.searchValue);
          response(arr);
          for (let i = 0; i < arr.length; i++) {
            if (!IsInPeriodicTable(arr[i])) {
              this.flag = true;
            } else {
              this.flag = false;
            }
          }
        }
      }
    },
    cleanSearchInput() {
      cleanInput(this.searchValue);
      this.searchValue = "";
    },
    //-------------------------search-----------------------------
    search() {
      const search_type = this.value;
      this.hasSearchData = true;
      this.flag = true;
      if (search_type === "element") {
        const search_arr = this.searchValue.split("");
        if (search_arr[search_arr.length - 1] == "-") {
          this.runFlowByElements(
            this.searchValue.substring(0, this.searchValue.length - 1)
          );
        } else {
          this.runFlowByElements(this.searchValue);
        }
      }
      if (search_type === "formula") {
        this.runFlowByFormula(this.searchValue);
      }
      if (search_type === "structid") {
        this.runFlowById(this.searchValue);
      }
    },

    runFlowByElements(include: string) {
      runWorkflow(new Main_By_Elements(), {
        include: include,
        search_store: this.$store,
      }).then((data) => {
        if(data && data.error == "true"){
            this.flag = false;
            this.hasSearchData = false;
            this.$message({
                message: data.message,
                type: "error",
                duration: 3000,
                showClose: true,
                customClass: "error-message-box",
                // offset: 100,
            });
        }
        else{
            this.flag = false;
            this.displaySearchValue = true;
            this.hasSearchData = false;
            this.timer = setInterval(this.toScrollTable, 2);
        }
      });
    },

    runFlowByFormula(include: string) {
      runWorkflow(new Main_By_Formula(), {
        include: include || "Li(CoO2)2",
        search_store: this.$store,
      }).then((data) => {
        if(data && data.error == "true"){
            this.flag = false;
            this.hasSearchData = false;
            this.$message({
                message: data.message,
                type: "error",
                duration: 3000,
                showClose: true,
                customClass: "error-message-box",
                // offset: 100,
            });
        }
        else{
            this.flag = false;
            this.displaySearchValue = true;
            this.hasSearchData = false;
            this.timer = setInterval(this.toScrollTable, 2);
        }
      });
    },

    runFlowById(include: string) {
      runWorkflow(new Main_By_Id(), {
        include: include,
        search_store: this.$store,
      }).then((data) => {
        if(data && data.error == "true"){
            this.flag = false;
            this.hasSearchData = false;
            this.$message({
                message: data.message,
                type: "error",
                duration: 3000,
                showClose: true,
                customClass: "error-message-box",
                // offset: 100,
            });
        }
        else{
            this.flag = false;
            this.displaySearchValue = true;
            this.hasSearchData = false;
            this.timer = setInterval(this.toScrollTable, 2);
        }
      });
    },  

  },
});
