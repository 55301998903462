import { AuthApiNode } from "@/nodes/authApiNode";
export class GetDetails extends AuthApiNode {

  mode = "direct"
  method = "post"
  url = "/api/tight_binding/get_band_data/"

  buildParams = async () => {
    let params_obj = { 
      grid_id: null,
      t1_ratio: null,
      t2_ratio: null,
      onsites_symbol: null,
      new_grid: null,
    }
    params_obj.grid_id = this.$state.fetch().grid_id;
    params_obj.t1_ratio = this.$state.fetch().t1_ratio;
    params_obj.t2_ratio = this.$state.fetch().t2_ratio;
    params_obj.onsites_symbol = this.$state.fetch().onsites_symbol;
    params_obj.new_grid = this.$state.fetch().new_grid;
    return params_obj
  }
  
}