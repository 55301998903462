

















































































































































































































































import Vue from 'vue'
export default Vue.extend({
    name: "Elastic",
    props: {
        elastic_data: Object,
    }
})
