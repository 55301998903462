import formatFormula from '@/utils/formatFormula'
export default function formatUnstableData(data: any) {
  let tempUnstable: Array<any> = [];
  if (data.formula) {
    data.formula.forEach((item: any, index: number) => {
      let tempObj = new Object();
      tempObj = {
        formula: formatFormula(item),
        id: data.tid[index],
        eAboveHull: data.eAboveHull[index],
        fEnergy: data.fEnergy[index]
      }
      tempUnstable.push(tempObj);
    })
  }
  return tempUnstable;
}