import { AuthApiNode } from "@/nodes/authApiNode"
export class GetPhaseDiagram extends AuthApiNode {
  mode = "direct";
  url = "/api/phase_diagram/phase_diagram_handle/";
  method = "post";
  
  buildParams = async () => {
    let params_obj = { include: "" }
    this.$state.commit((state: any) => {
      state.store_ = this.inputs.phase_store
      return state
    })
    params_obj.include = this.inputs.phase_formula
    return params_obj
  }

}