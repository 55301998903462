







import Vue from "vue";
import AboutTabBox from "@/components/about/AboutTabBox.vue";
export default Vue.extend({
  components: { AboutTabBox },
  data() {
    return {
      tabData: [
        {
          id: 1,
          routerLink: "/atomly",
          imgIcon: require("@/assets/icon/atomly.svg"),
          routerName: this.$t("about.atomly"),
        },
        {
          id: 2,
          routerLink: "/team",
          imgIcon: require("@/assets/icon/team.svg"),
          routerName: this.$t("about.team"),
        },
        {
          id: 3,
          routerLink: "/milestone",
          imgIcon: require("@/assets/icon/flag.svg"),
          routerName: this.$t("about.mileStone"),
        },
        {
          id: 4,
          routerLink: "/advisory",
          imgIcon: require("@/assets/icon/advise.svg"),
          routerName: this.$t("about.advisory"),
        },
        {
          id: 5,
          routerLink: "/publication",
          imgIcon: require("@/assets/icon/paper.svg"),
          routerName: this.$t("about.publication"),
        },
      ],
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.initProtocalData();
    },
  },
  methods: {
    initProtocalData() {
      this.tabData[0].routerName = this.$t("about.atomly");
      this.tabData[1].routerName = this.$t("about.team");
      this.tabData[2].routerName = this.$t("about.mileStone");
      this.tabData[3].routerName = this.$t("about.advisory");
      this.tabData[4].routerName = this.$t("about.publication");
    },
  },
});
