































































































































































































































































































































































import Vue from "vue";
import { EventBus } from "../eventBus";
import Login from "@/views/login/Login.vue";
export default Vue.extend({
  components: { Login },
  props: {
    navData: Object,
  },
  data() {
    return {
      isScroll: false,
      isPopUpNav: false,
      isApplication: false,
      isAbout: false,
      maskWidth: 0,
      maskHeight: 0,
      head_icon_1: require("../assets/responsive/responsive_svg/responsive_header.svg"),
      head_icon_2: require("../assets/responsive/responsive_svg/responsive_header_scroll.svg"),
    };
  },
  computed: {
    avatar: {
      get: function (): any {
        return this.$store.state.user.userAvatar;
      },
    },
    isLang: {
      get: function (): any {
        return this.$store.state.lang.lang;
      },
    },
    isResetLayout: {
      get: function (): any {
        return this.$store.state.isReset.isResetLayout;
      },
    },
    isLogin(): any {
      return this.$store.getters.isLogin;
    },
    scrollColor(): string {
      if (this.isScroll) return this.navData.color_scroll;
      return this.navData.color_unscroll;
    },
    routeValue(): string {
      return this.$route.path;
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollHandler);
    window.addEventListener("click", this.closeNavPopBox);
  },
  methods: {
    closeNavPopBox(e: any) {
      if (
        e.target.className == "mask-pop-up-nav" &&
        e.target.className !== "ssn-nav-ul"
      ) {
        this.isPopUpNav = !this.isPopUpNav;
      }
    },
    isShrinkNavigator() {
      if (this.isApplication == false && this.isAbout == false) {
        let itemsInnerNav = document.getElementsByClassName("ssn-box");
        (itemsInnerNav[0] as HTMLElement).style.height = "15.5rem";
      }
    },
    isPopUpNavigator() {
      this.isPopUpNav = !this.isPopUpNav;
      this.isApplication = false;
      this.isAbout = false;
      this.isShrinkNavigator();
    },
    displayApplication() {
      this.isApplication = !this.isApplication;
      this.isAbout = false;
      let itemsInnerNav = document.getElementsByClassName("ssn-box");
      (itemsInnerNav[0] as HTMLElement).style.height = "27.9rem";
      this.isShrinkNavigator();
    },
    displayAbout() {
      this.isAbout = !this.isAbout;
      this.isApplication = false;
      let itemsInnerNav = document.getElementsByClassName("ssn-box");
      (itemsInnerNav[0] as HTMLElement).style.height = "31rem";
      this.isShrinkNavigator();
    },
    scrollHandler() {
      if (document.documentElement.scrollTop < 10) {
        this.isScroll = false;
      } else {
        this.isScroll = true;
      }
    },
    goLoginShow() {
      this.$store.state.login.loginShow = true;
      setTimeout(() => {
        (this.$refs.login as any).showLoginIndex();
      });
    },
    goLoginShowMobile() {
      this.$store.state.login.loginShow = true;
      this.isPopUpNav = false;
      setTimeout(() => {
        (this.$refs.loginmobile as any).showLoginIndex();
      });
    },
    closeLoginShow() {
      this.$store.state.login.loginShow = false;
    },
    changeLanguage() {
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh");
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.$store.dispatch("asyncChangeLanguage");
      if (this.$route.path == "/") {
        this.$router.go(0);
      }
    },
  },
});
