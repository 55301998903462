export default {
  state: {
    band_data: {}
  },
  mutations: {
    saveBandData(state: any, data: any) {
      state.band_data = data;
    }
  },
  actions: {
    saveBand(context: any, data: any) {
      context.commit("saveBandData", data);
    }
  }
}