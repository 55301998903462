export default {
  state: {
    matBandData: {},
    stableData: [],
    unstableData: []
  },
  mutations: {
    saveMatDesBandData(state: any, data: any) {
      state.matBandData = data
    },
    saveMatDesStableData(state: any, data: any) {
      state.stableData = data.stable_data;
      state.unstableData = data.unstable_data;
    }
  },
  actions: {
    saveMatDesBand (context: any, data: any) {
      context.commit("saveMatDesBandData", data);
    },
    saveMatDesStable (context: any, data: any) {
      context.commit("saveMatDesStableData", data)
    }
  }
}