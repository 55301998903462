export default {
  state: {
    structure: ""
  },
  mutations: {
    saveFileStructureData(state: any, data: any) {
      state.structure = data;
    },
  },
  actions: {
    saveFileStructure(context: any, data: any) {
      context.commit("saveFileStructureData", data);
    },
  },
};