
































import Vue from "vue";
export default Vue.extend({
  props: {
    title: String,
    cell_data: {
      type: Object,
      default: null,
    },
    symm_data: {
      type: Object,
      default: null,
    },
    energies_data: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.toJudgeTitle();
  },
  computed: {
    TableData: {
      get: function () {
        const _this: any = this;
        if (_this.cell_data !== null) {
          return _this.formatUnitCellParameter(_this.cell_data);
        }
        if (_this.symm_data !== null) {
          return _this.formatSymmetryProperty(_this.symm_data);
        }
        if (_this.energies_data !== null) {
          return _this.formatEnergy(_this.energies_data);
        }
        return null;
      },
    },
  },
  methods: {
    toJudgeTitle() {
      if (this.cell_data !== null) {
        return 1;
      }
      if (this.symm_data !== null) {
        return 0;
      }
      if (this.energies_data !== null) {
        return -1;
      }
    },
    // ① 格式化晶胞参数
    formatUnitCellParameter(unit_cell: any) {
      let newArr_1 = [["a", "α"], ["b", "β"], ["c", "γ"], ["Volume"]];
      if (unit_cell.a) {
        newArr_1[0].splice(1, 0, unit_cell.a + " Å");
        newArr_1[0].push(unit_cell.alpha + "°");
        newArr_1[1].splice(1, 0, unit_cell.b + " Å");
        newArr_1[1].push(unit_cell.beta + "°");
        newArr_1[2].splice(1, 0, unit_cell.c + " Å");
        newArr_1[2].push(unit_cell.gamma + "°");
        newArr_1[3].push(unit_cell.volume + " Å<sup>3<sup>");
      } else {
        newArr_1[0].splice(1, 0, "");
        newArr_1[0].push("");
        newArr_1[1].splice(1, 0, "");
        newArr_1[1].push("");
        newArr_1[2].splice(1, 0, "");
        newArr_1[2].push("");
        newArr_1[3].push("");
      }
      return newArr_1;
    },
    // ② 格式化对称属性
    formatSymmetryProperty(symmetry_data: any) {
      let newArr_2 = [
        ["Hall"],
        ["Space Group"],
        ["Point Group"],
        ["Crystal System"],
      ];
      if (symmetry_data) {
        newArr_2[0].push(symmetry_data.hall);
        newArr_2[1].push(symmetry_data.spacegroup);
        newArr_2[2].push(symmetry_data.pointgroup);
        newArr_2[3].push(symmetry_data.crystal_system);
      } else {
        newArr_2[0].push("");
        newArr_2[1].push("");
        newArr_2[2].push("");
        newArr_2[3].push("");
      }
      return newArr_2;
    },
    // ③ 格式化能量属
    formatEnergy(energies: any) {
      let newArr_3 = [
        ["Energy"],
        ["Energy/Atom"],
        ["Energy Vdw"],
        ["Energy Vdw/Atom"],
      ];
      newArr_3[0].push(energies.energy + " eV");
      newArr_3[1].push(energies.energy_atom + " eV");
      newArr_3[2].push(energies.energy_vdw + " eV");
      newArr_3[3].push(energies.energy_vdw_atom + " eV");
      return newArr_3;
    },
  },
});
