
































import Vue from "vue";
import AccountManage from "@/views/account/AccountManage.vue";
import UserInfo from "@/views/account/UserInfo.vue";
import ChangeMobile from "@/views/account/ChangeMobile.vue";
import BindNewMobile from "@/views/account/BindNewMobile.vue";
import BindMobile from "@/views/login/BindMobile.vue";
import NavHead from "@/components/NavHead.vue";
import NavFooter from "@/components/NavFooter.vue";
export default Vue.extend({
  components: {
    AccountManage,
    UserInfo,
    ChangeMobile,
    BindNewMobile,
    BindMobile,
    NavHead,
    NavFooter,
  },
  data() {
    return {
      activeName: "userinfo",
      isUserInfo: true,
      isAccount: false,
      maskShow: false,
      showComponent: this.$store.state.account.showComponent,
      navData: {
        bgc_unscroll: "rgba(224,224,224,0.2)",
        bgc_scroll: "rgba(224, 224, 224, 0.2)",
        sbgc_scroll: "rgba(0,0,0,0.1)",
        sbgc_unscroll: "rgba(0,0,0,0.1)",
        border_color: "#BDBDBD",
        color_unscroll: "#000",
        color_scroll: "#000",
      },
    };
  },
  mounted() {
    this.goThirdLogin();
  },
  computed: {
    changeMobileShow(): boolean {
      return this.showComponent == "change";
    },
    bindNewMobileShow(): boolean {
      return this.showComponent == "bind";
    },
    bindAccountShow(): boolean {
      return this.showComponent == "account";
    },
  },
  methods: {
    //处理tab转换
    handleClick(tab: any) {
      if (tab.name == "userinfo") {
        this.isUserInfo = true;
        this.isAccount = false;
      } else if (tab.name == "account") {
        this.isUserInfo = false;
        this.isAccount = true;
      }
    },
    goThirdLogin() {
      if (window.location.href.indexOf("?") != -1) {
        var params_str = window.location.href.split("?")[1].split("#")[0];
        var params_array = params_str.split("&");
        var params: { [key: string]: string } = {};
        for (let i in params_array) {
          var pp = params_array[i].toString().split("=");
          params[pp[0]] = pp[1];
        }
        let third_party_id = params["third_party_id"];
        if (!third_party_id) return;

        let third_party = sessionStorage.getItem("third_party");
        switch (third_party) {
          case "github":
            this.$store.commit("setGithubId", third_party_id);
            break;
          case "weChat":
            this.$store.commit("setWechatId", third_party_id);
            break;
          default:
            return;
        }
        this.maskShow = true;
        this.showBindAccount();
      }
    },
    setShowComponent(value: string) {
      this.showComponent = value;
    },
    //更换旧手机号
    showChangeMobile() {
      this.showComponent = "change";
      this.maskShow = true;
    },
    //绑定新手机号
    showBindNewMobile() {
      this.showComponent = "bind";
      this.maskShow = true;
    },
    //绑定第三方账户
    showBindAccount() {
      this.showComponent = "account";
      this.maskShow = true;
    },
    showClose() {
      this.maskShow = false;
      this.showComponent = "";
    },
  },
});
