import Vue from "vue";

import {
  Tabs,
  TabPane,
  Message,
  Button,
  Table,
  TableColumn,
  Select,
  Option,
  Upload,
  Pagination,
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Collapse,
  CollapseItem,
  Divider,
  Row,
  Slider,
  Col,
  Input,
} from "element-ui";

Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Upload);
Vue.use(Pagination);
Vue.use(Icon);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tooltip);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Divider);
Vue.use(Row);
Vue.use(Slider);
Vue.use(Col);
Vue.use(Input);
Vue.prototype.$message = Message;
