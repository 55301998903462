






import Vue from "vue";
import ProtocalComponent from "@/components/ProtocalComponent.vue";
export default Vue.extend({
  components: {
    ProtocalComponent,
  },
  data() {
    return {
      protocalData: {
        textHead: this.$t("protocal.userTitle"),
        textContent: this.$t("protocal.userInfo"),
      },
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.initProtocalData();
    },
  },
  methods: {
    initProtocalData() {
      this.protocalData.textHead = this.$t("protocal.userTitle");
      this.protocalData.textContent = this.$t("protocal.userInfo");
    },
  },
});
