import { APINode } from "arkfbp/lib/apiNode";

export class GitRegisterNode extends APINode {
  mode = "direct";
  url = "/api/arkid/github/register/";
  method = "post";

  async buildParams() {
    const mobile = this.$state.fetch().mobile;
    const sms_token = this.$state.fetch().store.state.login.sms_token;
    const github_id = this.$state.fetch().store.state.user.userGithubId;
    return {
      mobile: mobile,
      sms_token: sms_token,
      github_id: github_id,
    };
  }
}
