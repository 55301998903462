






























































import Vue from "vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { UpdateInfo } from "@/flows/updateUserInfo";
import { UploadImage } from "@/flows/uploadImage";
import { mapState } from "vuex";
export default Vue.extend({
  data() {
    return {
      //头像文件
      file: null,
    };
  },
  computed: {
    authHeader() {
      return {
        Authorization: "token " + localStorage.getItem("userToken"),
      };
    },
    userForm() {
      return {
        avatar: this.$store.state.user.userAvatar,
        mobile: this.$store.state.user.userMobile,
        name: this.$store.state.user.username,
        desc: this.$store.state.user.userDes,
        address: this.$store.state.user.userAddress,
        occ: this.$store.state.user.userOcc,
      };
    },
  },
  methods: {
    updataInfo() {
      runWorkflow(new UpdateInfo(), {
        avatar: this.userForm.avatar,
        name: this.userForm.name,
        des: this.userForm.desc,
        url: this.userForm.address,
        occ: this.userForm.occ,
      }).then((data) => {
        this.$store.dispatch("setUserInfo", data);
        this.$message({
          message: "Information updated successfully",
          type: "success",
          offset: 100,
        });
      });
    },
    onSuccess(response: any) {
      this.$store.commit("setUserAvatar", response.file_url);
    },
  },
});
