import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { GetPhaseDiagram } from "./nodes/GetPhaseDiagram";
import { RenderData } from "./nodes/RenderData";

export class Main extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "1",
        next: "2",
      },
      {
        cls: GetPhaseDiagram,
        id: "2",
        next: "3",
      },
      {
        cls: RenderData,
        id: "3",
        next: "4",
      },
      {
        cls: StopNode,
        id: "4",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
