import { AuthApiNode } from "@/nodes/authApiNode"
export class GetResData extends AuthApiNode {
  url = "/api/run4u/run4u_submit/"
  method = "post"
  mode = "direct"

  async buildParams() {
    this.$state.commit((state: any) => {
      state.store = this.inputs.submit_store
    });
    const params_obj = {
      submit: true,
      structure: this.inputs.structure
    }
    return params_obj
  }
}