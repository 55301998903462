import { APINode } from "arkfbp/lib/apiNode";

export class GitBindNode extends APINode {
  mode = "direct";
  url = "/api/arkid/github/bind/";
  method = "post";

  async buildParams() {
    const sms_token = this.$state.fetch().store.state.login.sms_token;
    const github_id = this.$state.fetch().store.state.user.userGithubId;
    return {
      sms_token: sms_token,
      github_id: github_id,
    };
  }
}
