







import Vue from "vue";
import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  DirectionalLight,
  Camera,
  Color,
  Geometry,
  Vector3,
  AmbientLight,
  BackSide,
  MeshBasicMaterial,
  Mesh,
  Object3D,
  SphereGeometry,
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
  ArrowHelper,
  FontLoader,
  Sprite,
  Texture,
  SpriteMaterial,
  Vector2
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry";
import { SceneUtils } from "three/examples/jsm/utils/SceneUtils";

export default Vue.extend({
  props: {
    brillouinZone: Object,
    //width: String,
    //height: String,
  },

  data() {
    return {
      renderer: new WebGLRenderer({ antialias: true }),
      camera: new PerspectiveCamera(),
      scene: new Scene(),
      light: new DirectionalLight(),
      controls: new OrbitControls(new Camera(), new WebGLRenderer().domElement),
    };
  },

  watch: {
    brillouinZone() {
      this.initRender();
      // this.initScene();
      this.initCamera();
      this.initLight();
      this.initModel();
      this.initControls();
      this.animate();
      window.onresize = this.onWindowResize;
    },
  },
  computed: {
    widthNum() {
      const brillouin: any = document.getElementById("brillouin-content_");
      const brillouin_width = brillouin.clientWidth;
      return Number(brillouin_width);
      //return Number(this.width);
    },
    heightNum() {
      const brillouin: any = document.getElementById("brillouin-content_");
      const brillouin_height = brillouin.clientHeight;
      return Number(brillouin_height);
      //return Number(this.height);
    },
  },
  methods: {
    initRender() {
      this.renderer = new WebGLRenderer({ antialias: true });
      this.renderer.setClearColor(new Color(0xffffff));
      this.renderer.setSize(this.widthNum, this.heightNum);
      (this.$refs.three_draw as Element).appendChild(this.renderer.domElement);
    },

    initCamera() {
      this.camera = new PerspectiveCamera(
        40,
        this.widthNum / this.heightNum,
        1,
        1000
      );
      this.camera.position.set(130, 0, 400);
      this.camera.lookAt(new Vector3(0, 0, 0));
    },

    initLight() {
      this.scene.add(new AmbientLight(0x404040));
      this.light = new DirectionalLight(0xffffff);
      this.light.position.set(1, 1, 1);
      this.scene.add(this.light);
    },

    initControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = false;
      this.controls.dampingFactor = 0.8;
      this.controls.enableZoom = true;
      this.controls.autoRotate = false;
      this.controls.minDistance = 0;
      this.controls.maxDistance = 800;
      this.controls.enablePan = false;
    },

    createMesh(geom: Geometry) {
      var meshMaterial = new MeshBasicMaterial({
        color: '#778899',
        transparent: true,
        opacity: 0.2,
      });
      meshMaterial.side = BackSide;
      var mesh = SceneUtils.createMultiMaterialObject(geom, [meshMaterial]);

      return mesh;
    },

    renderThree() {
      this.renderer.render(this.scene, this.camera);
    },

    onWindowResize() {
      this.camera.aspect = this.widthNum / this.heightNum;
      this.camera.updateProjectionMatrix();
      this.renderThree();
      this.renderer.setSize(this.widthNum, this.heightNum);
    },

    animate() {
      this.controls.update();
      this.renderThree();
      requestAnimationFrame(this.animate);
    },

    makeTextSprite(message:any, fontsize:any) {
      var canvas = document.createElement('canvas');
      canvas.height = 1000;
      canvas.width = 2000;
      var context = canvas.getContext('2d');
      if (context) {
        context.font = "Bold " + fontsize + "px Arial";
        context.fillStyle = "rgba(0, 0, 0, 1.0)";
        context.fillText(message, 4, 800);
      }
      var texture = new Texture(canvas);
      texture.needsUpdate = true;
      var spriteMaterial = new SpriteMaterial({ map: texture, alphaTest: 0.1});
      var sprite = new Sprite( spriteMaterial );
      sprite.scale.set(16,8,1);
      return sprite;
    },

    initModel() {
      var _this = this;
      var datas = this.brillouinZone;
      var factor = datas["factor"];

      var points = [];
      var _points = datas["_points"];

      if (!factor || !_points) {
        // console.log("factor:", factor, "_points:", _points);
        return;
      }

      // console.log("_p:", _points);
      var max_length = 0;
      for (var i = 0; i < _points.length; i++) {
        points.push(
          new Vector3(
            _points[i][0] * factor,
            _points[i][1] * factor,
            _points[i][2] * factor
          )
        );
        var len = _points[i][0] ** 2 + _points[i][1] ** 2 + _points[i][2] ** 2;
        if (len > max_length) {
          max_length = len;
        }
      }
      max_length = Math.sqrt(max_length) * factor + 5;
      var spGroup = new Object3D();
      var material = new MeshBasicMaterial({
        color: 0x0080ff,
        transparent: false,
      });
      var material_font = new MeshBasicMaterial({
        color: 0x080808,
        transparent: false,
      });
      points.forEach(function (point) {
        var spGeom = new SphereGeometry(2);
        var spMesh = new Mesh(spGeom, material);
        spMesh.position.copy(point);
        _this.scene.add(spMesh);
      });
      this.scene.add(spGroup);

      var hullGeometry = new ConvexGeometry(points);
      var hullMesh = this.createMesh(hullGeometry);
      let Edges = new EdgesGeometry(hullGeometry, 1);
      let edgesMtl = new LineBasicMaterial({ color: 0xd1d1d1 });
      let Line = new LineSegments(Edges, edgesMtl);
      hullMesh.add(Line);
      this.scene.add(hullMesh);

      var kpath = datas["kpath"];
      var kpoints = datas["kpoints"];

      for (i = 0; i < kpoints.length; i++) {
        var spmesh = new Mesh(new SphereGeometry(2), material);
        spmesh.position.copy(
          new Vector3(
            kpoints[i][0] * factor,
            kpoints[i][1] * factor,
            kpoints[i][2] * factor
          )
        );
        _this.scene.add(spmesh);
      }
      var factor1 = factor;
      factor = max_length;
      var reciprocal_lattice = datas["reciprocal_lattice"];
      var axis = [
        [factor, 0, 0],
        [0, factor, 0],
        [0, 0, factor],
      ];
      var _axis = [];
      var t1 = Math.sqrt(
        factor ** 2 /
          (reciprocal_lattice[0][0] ** 2 +
            reciprocal_lattice[0][1] ** 2 +
            reciprocal_lattice[0][2] ** 2)
      );
      var t2 = Math.sqrt(
        factor ** 2 /
          (reciprocal_lattice[1][0] ** 2 +
            reciprocal_lattice[1][1] ** 2 +
            reciprocal_lattice[1][2] ** 2)
      );
      var t3 = Math.sqrt(
        factor ** 2 /
          (reciprocal_lattice[2][0] ** 2 +
            reciprocal_lattice[2][1] ** 2 +
            reciprocal_lattice[2][2] ** 2)
      );
      _axis = [
        [
          reciprocal_lattice[0][0] * t1,
          reciprocal_lattice[0][1] * t1,
          reciprocal_lattice[0][2] * t1,
        ],
        [
          reciprocal_lattice[1][0] * t2,
          reciprocal_lattice[1][1] * t2,
          reciprocal_lattice[1][2] * t2,
        ],
        [
          reciprocal_lattice[2][0] * t3,
          reciprocal_lattice[2][1] * t3,
          reciprocal_lattice[2][2] * t3,
        ],
      ];

      var p: any[] = [];
      for (i = 0; i < 3; i++) {
        var flag = true;
        for (var j = 0; j < 3; j++) {
          if (
            Math.abs(_axis[j][0] - axis[i][0]) > 5 ||
            Math.abs(_axis[j][1] - axis[i][1]) > 5 ||
            Math.abs(_axis[j][2] - axis[i][2]) > 5
          ) {
            continue;
          } else {
            flag = false;
            break;
          }
        }

        if (flag) {
          p.push(true);
        } else {
          p.push(false);
        }
      }

      for (i = 0; i < 3; i++) {
        var dir = new Vector3(
          reciprocal_lattice[i][0],
          reciprocal_lattice[i][1],
          reciprocal_lattice[i][2]
        );
        dir.normalize();
        var origin = new Vector3(0, 0, 0);
        var length = factor;
        var hex = 0x696969;
        var headLength = factor / 20;
        var headWidth = factor / 30;

        var arrowHelper = new ArrowHelper(
          dir,
          origin,
          length,
          hex,
          headLength,
          headWidth
        );
        _this.scene.add(arrowHelper);
      }

      var loader = new FontLoader();
      loader.load("./helvetiker_regular.typeface.json", function (font) {
        for (var i = 0; i < kpath.length; i++) {
          var text = _this.makeTextSprite((kpath[i]), "800");
          text.center = new Vector2(0, 0);
          _this.scene.add( text );
          text.position.set(
              kpoints[i][0] * factor1 - 2,
              kpoints[i][1] * factor1 - 10,
              kpoints[i][2] * factor1
          );
          _this.scene.add(text);
        }
      });
    },
  },
});
