































import Vue from "vue";
import JmolComponent from "@/components/JmolComponent.vue";
import JmolButton from "@/components/public/operateBox/ButtonCpn.vue";
import DropDown from "@/components/public/dropdown/DropDown.vue";
export default Vue.extend({
  name: "CrystalStructure",
  data() {
    return {
      labelBtnText: "show label",
      unitcellBtnText: "hide unticell",
      bondsBtnText: "hide bonds",
      polyhedraBtnText: "show polyhedra",
      label_value: true,
      unitcell_value: false,
      bonds_value: false,
      poly_value: true,
      isDownLoad: false,
    };
  },
  components: {
    JmolComponent,
    JmolButton,
    DropDown,
  },
  computed: {
    jmol_data: {
      get: function () {
        const jmolData: any = this.$store.state.matDataDetails.mat_data_des;
        let scriptRunData = "";
        if (jmolData) {
          if (jmolData.nsites <= 20) {
            scriptRunData =
          "background [255, 255, 255];set displayCellParameters FALSE;load data 'model' " + jmolData.output_structs.jsmol_struct + " end 'model'{2 2 2}PACKED ;wireframe 0.08;spacefill 20%;set atomPicking FALSE; zoom 80;unitcell 0.01;background [255, 255, 255];set frank off;center visible; set disablePopupMenu True; set specularPercent 0; frank OFF; set minimizationRefresh FALSE;set antialiasDisplay TRUE; set appendNew false;set antialiasdisplay true;axes off;set frank off;label HIDE;";
          } else {
            scriptRunData =
          "background [255, 255, 255];set displayCellParameters FALSE;load data 'model' " + jmolData.output_structs.jsmol_struct + "end 'model'{1 1 1}PACKED range -4; display within(4.0, symop=1555);wireframe 0.08;spacefill 20%;set atomPicking FALSE; zoom 80;unitcell 0.01;background [255, 255, 255];set frank off;center visible; set disablePopupMenu True; set specularPercent 0; frank OFF; set minimizationRefresh FALSE;set antialiasDisplay TRUE; set appendNew false;set antialiasdisplay true;axes off;set frank off;label HIDE;";
          }
        }
        return scriptRunData;
      },
    },
    jmol_button: {
      get: function () {
        const jmolStruct = this.$store.state.matDataDetails.mat_data_des.output_structs.jsmol_struct;
        let scriptButtonData = "";
        if (jmolStruct) {
          scriptButtonData = "data 'model' " + jmolStruct + " end 'model';axes off;set frank off;set displayCellParameters FALSE;label HIDE;unitcell 'a/2,b/2,c/2'";
        }
        return scriptButtonData;
      }
    }
  },
  methods: {
    crystalShowLabel() {
      if (this.labelBtnText == "show label") {
        this.labelBtnText = "hide label";
        this.label_value = false;
      } else {
        this.labelBtnText = "show label";
        this.label_value = true;
      }
    },
    crystalHideUnticell() {
      if (this.unitcellBtnText == "hide unticell") {
        this.unitcellBtnText = "show unticell";
        this.unitcell_value = true;
      } else {
        this.unitcellBtnText = "hide unticell";
        this.unitcell_value = false;
      }
    },
    crystalHideBonds() {
      if (this.bondsBtnText == "hide bonds") {
        this.bondsBtnText = "show bonds";
        this.bonds_value = true;
      } else {
        this.bondsBtnText = "hide bonds";
        this.bonds_value = false;
      }
    },
    crystalShowPolyhedra() {
      if (this.polyhedraBtnText == "show polyhedra") {
        this.polyhedraBtnText = "hide polyhedra";
        this.poly_value = false;
      } else {
        this.polyhedraBtnText = "show polyhedra";
        this.poly_value = true;
      }
    },
    downloadFile(e: any) {
      if(e == 'p') {
        this.downloadPFile()
      } else {
        this.downloadCFile()
      }
    },
    downloadCFile() {
      const file_name =
        this.$store.state.structIdGrobal.struct_id_global +
        "-conventional-cell.cif";
      const file_text = this.$store.state.cifFile.cif_data.ccif;
      this.downLoad(file_name, file_text);
    },
    downloadPFile() {
      const file_name =
        this.$store.state.structIdGrobal.struct_id_global +
        "-primitive-cell.cif";
      const file_text = this.$store.state.cifFile.cif_data.pcif;
      this.downLoad(file_name, file_text);
    },
    downLoad(filename: any, text: any) {
      var pom = document.createElement("a");
      pom.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      pom.setAttribute("download", filename);
      if (document.createEvent) {
        document.body.appendChild(pom);
        var event = document.createEvent("MouseEvents");
        event.initEvent("click", true, true);
        pom.dispatchEvent(event);
        document.body.removeChild(pom);
      } else {
        pom.click();
      }
    },
  },
});
