import { IFNode } from "arkfbp/lib/ifNode";

export class HandleCodeNode extends IFNode {
  condition() {
    return this.inputs.mobile == "invalid";
  }

  positive() {
    const _store = this.$state.fetch().store;
    _store.dispatch("setloginOrRegister", { data: "register" });
    return {};
  }

  negative() {
    const _store = this.$state.fetch().store;
    _store.dispatch("setloginOrRegister", { data: "login" });
    return {};
  }
}