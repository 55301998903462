














import Vue from "vue";
import { mapState, mapMutations } from "vuex";
export default Vue.extend({
  name: "PeriodicBox",
  data() {
    return {
      active: this.color || "#FFFFFF",
    };
  },
  props: {
    index: Number,
    name: String,
    color: String,
  },
  methods: {
    mouseOver() {
      this.active = "#FFFFFF";
      this.$emit("periodicEnlarge", this.index, this.name);
      if (this.name) {
        this.$store.state.periodic.periodicEnlargeShow = true;
      }
    },
    mouseLeave() {
      this.active = this.color;
      this.$store.state.periodic.periodicEnlargeShow = false;
    },
    clickPeriodic() {
      this.$emit("searchPeriodic", this.name);
    },
  },
});
