export default {
  state: {
    more_table_data: {
      calc_method: "GGA+U",
      calc_date: "2020-03-20",
      vasp: "5.4.1",
      potcar: "PAW_PBE-H",
      u_value: "None",
      band_gap: "Indirect_Band_Gap Bandgap: 7.3665ev",
    }
  }
};