








































































































































import Vue from 'vue';
import NavHead from "@/components/NavHead.vue";
import JmolComponent from "@/components/JmolComponent.vue";
import Tb_band_diagram from "@/components/Tb_band_diagram.vue";

import { runWorkflow } from "arkfbp/lib/flow";
import { Main } from "@/flows/tightBinding";
import { Main_band_data } from "@/flows/tightBindingBandData";

export default Vue.extend({
    name: "Tight_binding",
    
    components: {
        NavHead,
        JmolComponent,
        Tb_band_diagram,
    },
    
    data() {
        return {
            navData: {
                bgc_unscroll: "rgba(224, 224, 224, 0.4)",
                bgc_scroll: "rgba(224, 224, 224, 0.4)",
                sbgc_scroll: "rgba(255,255,255,0.5)",
                sbgc_unscroll: "rgba(255,255,255,0.1)",
                border_color: "#BDBDBD",
                color_unscroll: "#000",
                color_scroll: "#000",
            },

            grid_types: [
                {
                    value: 'kgm',
                    label: 'kgm'
                },
                {
                    value: 'bew',
                    label: 'bew'
                },
                {
                    value: 'bex',
                    label: 'bex'
                },
                {
                    value: 'bey',
                    label: 'bey'
                },
                {
                    value: 'bhd',
                    label: 'bhd'
                },
                {
                    value: 'bil',
                    label: 'bil'
                },
                {
                    value: 'car',
                    label: 'car'
                },
                {
                    value: 'cem',
                    label: 'cem'
                },
                {
                    value: 'cem-a',
                    label: 'cem-a'
                },
                {
                    value: 'cem-d',
                    label: 'cem-d'
                },
                {
                    value: 'cpa',
                    label: 'cpa'
                },
                {
                    value: 'cpb',
                    label: 'cpb'
                },
                {
                    value: 'cpc',
                    label: 'cpc'
                },
                {
                    value: 'cpd',
                    label: 'cpd'
                },
                {
                    value: 'cpe',
                    label: 'cpe'
                },
                {
                    value: 'cpf',
                    label: 'cpf'
                },
                {
                    value: 'cpg',
                    label: 'cpg'
                },
                {
                    value: 'cph',
                    label: 'cph'
                },
                {
                    value: 'cpi',
                    label: 'cpi'
                },
                {
                    value: 'cpj',
                    label: 'cpj'
                },
                {
                    value: 'cpk',
                    label: 'cpk'
                },
                {
                    value: 'cpl',
                    label: 'cpl'
                },
                {
                    value: 'cpm',
                    label: 'cpm'
                },
                {
                    value: 'cpn',
                    label: 'cpn'
                },
                {
                    value: 'cpo',
                    label: 'cpo'
                },
                {
                    value: 'cpp',
                    label: 'cpp'
                },
                {
                    value: 'cpq',
                    label: 'cpq'
                },
                {
                    value: 'cpr',
                    label: 'cpr'
                },
                {
                    value: 'cps',
                    label: 'cps'
                },
                {
                    value: 'cpt',
                    label: 'cpt'
                },
                {
                    value: 'cpu',
                    label: 'cpu'
                },
                {
                    value: 'cpv',
                    label: 'cpv'
                },
                {
                    value: 'cpw',
                    label: 'cpw'
                },
                {
                    value: 'cpx',
                    label: 'cpx'
                },
                {
                    value: 'cpy',
                    label: 'cpy'
                },
                {
                    value: 'cpz',
                    label: 'cpz'
                },
                {
                    value: 'cqa',
                    label: 'cqa'
                },
                {
                    value: 'cqb',
                    label: 'cqb'
                },
                {
                    value: 'cqc',
                    label: 'cqc'
                },
                {
                    value: 'cqd',
                    label: 'cqd'
                },
                {
                    value: 'cqe',
                    label: 'cqe'
                },
                {
                    value: 'cqg',
                    label: 'cqg'
                },
                {
                    value: 'cqh',
                    label: 'cqh'
                },
                {
                    value: 'cqi',
                    label: 'cqi'
                },
                {
                    value: 'cqj',
                    label: 'cqj'
                },
                {
                    value: 'cqk',
                    label: 'cqk'
                },
                {
                    value: 'cql',
                    label: 'cql'
                },
                {
                    value: 'cqm',
                    label: 'cqm'
                },
                {
                    value: 'cqn',
                    label: 'cqn'
                },
                {
                    value: 'cqo',
                    label: 'cqo'
                },
                {
                    value: 'cqp',
                    label: 'cqp'
                },
                {
                    value: 'cqq',
                    label: 'cqq'
                },
                {
                    value: 'cqr',
                    label: 'cqr'
                },
                {
                    value: 'cqt',
                    label: 'cqt'
                },
                {
                    value: 'cqu',
                    label: 'cqu'
                },
                {
                    value: 'cqv',
                    label: 'cqv'
                },
                {
                    value: 'cqx',
                    label: 'cqx'
                },
                {
                    value: 'cqy',
                    label: 'cqy'
                },
                {
                    value: 'cqz',
                    label: 'cqz'
                },
                {
                    value: 'cru',
                    label: 'cru'
                },
                {
                    value: 'crx',
                    label: 'crx'
                },
                {
                    value: 'cry',
                    label: 'cry'
                },
                {
                    value: 'crz',
                    label: 'crz'
                },
                {
                    value: 'dal',
                    label: 'dal'
                },
                {
                    value: 'dha',
                    label: 'dha'
                },
                {
                    value: 'dhb',
                    label: 'dhb'
                },
                {
                    value: 'dhc',
                    label: 'dhc'
                },
                {
                    value: 'dhd',
                    label: 'dhd'
                },
                {
                    value: 'dhe',
                    label: 'dhe'
                },
                {
                    value: 'dhf',
                    label: 'dhf'
                },
                {
                    value: 'dhg',
                    label: 'dhg'
                },
                {
                    value: 'dhh',
                    label: 'dhh'
                },
                {
                    value: 'esp',
                    label: 'esp'
                },
                {
                    value: 'esq',
                    label: 'esq'
                },
                {
                    value: 'fes',
                    label: 'fes'
                },
                 {
                    value: 'fss',
                    label: 'fss'
                },
                {
                    value: 'fsv',
                    label: 'fsv'
                },
                {
                    value: 'fsz',
                    label: 'fsz'
                },
                {
                    value: 'fsz-a',
                    label: 'fsz-a'
                },
                {
                    value: 'fsz-d',
                    label: 'fsz-d'
                },
                {
                    value: 'fwa',
                    label: 'fwa'
                },
                {
                    value: 'fwb',
                    label: 'fwb'
                },
                {
                    value: 'fwc',
                    label: 'fwc'
                },
                {
                    value: 'fwd',
                    label: 'fwd'
                },
                {
                    value: 'fwe',
                    label: 'fwe'
                },
                {
                    value: 'fxt',
                    label: 'fxt'
                },
                {
                    value: 'fxt-a',
                    label: 'fxt-a'
                },
                {
                    value: 'fzh',
                    label: 'fzh'
                },
                {
                    value: 'hbt',
                    label: 'hbt'
                },
                {
                    value: 'hca',
                    label: 'hca'
                },
                {
                    value: 'hca-a',
                    label: 'hca-a'
                },
                {
                    value: 'hcb',
                    label: 'hcb'
                },
                {
                    value: 'hna',
                    label: 'hna'
                },
                {
                    value: 'hnb',
                    label: 'hnb'
                },
                {
                    value: 'hnc',
                    label: 'hnc'
                },
                {
                    value: 'hnd',
                    label: 'hnd'
                },
                {
                    value: 'hne',
                    label: 'hne'
                },
                {
                    value: 'hnf',
                    label: 'hnf'
                },
                {
                    value: 'htb',
                    label: 'htb'
                },
                {
                    value: 'htb-a',
                    label: 'htb-a'
                },
                {
                    value: 'hxl',
                    label: 'hxl'
                },
                {
                    value: 'jvh',
                    label: 'jvh'
                },
                {
                    value: 'kgd',
                    label: 'kgd'
                },
                {
                    value: 'kgd-a',
                    label: 'kgd-a'
                },
                {
                    value: 'kra',
                    label: 'kra'
                },
                {
                    value: 'krb',
                    label: 'krb'
                },
                {
                    value: 'krc',
                    label: 'krc'
                },
                {
                    value: 'krd',
                    label: 'krd'
                },
                {
                    value: 'kre',
                    label: 'kre'
                },
                {
                    value: 'krf',
                    label: 'krf'
                },
                {
                    value: 'krg',
                    label: 'krg'
                },
                {
                    value: 'krh',
                    label: 'krh'
                },
                {
                    value: 'krj',
                    label: 'krj'
                },
                {
                    value: 'krj-d',
                    label: 'krj-d'
                },
                {
                    value: 'krk',
                    label: 'krk'
                },
                {
                    value: 'krl',
                    label: 'krl'
                },
                {
                    value: 'krm',
                    label: 'krm'
                },
                {
                    value: 'krn',
                    label: 'krn'
                },
                {
                    value: 'krq',
                    label: 'krq'
                },
                {
                    value: 'krr',
                    label: 'krr'
                },
                {
                    value: 'krs',
                    label: 'krs'
                },
                {
                    value: 'krt',
                    label: 'krt'
                },
                {
                    value: 'kru',
                    label: 'kru'
                },
                {
                    value: 'kru-d',
                    label: 'kru-d'
                },
                {
                    value: 'krv',
                    label: 'krv'
                },
                {
                    value: 'krv-d',
                    label: 'krv-d'
                },
                {
                    value: 'krw',
                    label: 'krw'
                },
                {
                    value: 'krw-d',
                    label: 'krw-d'
                },
                {
                    value: 'krx',
                    label: 'krx'
                },
                {
                    value: 'krx-d',
                    label: 'krx-d'
                },
                {
                    value: 'kry',
                    label: 'kry'
                },
                {
                    value: 'kry-d',
                    label: 'kry-d'
                },
                {
                    value: 'mcm',
                    label: 'mcm'
                },
                {
                    value: 'mta',
                    label: 'mta'
                },
                {
                    value: 'mta-a',
                    label: 'mta-a'
                },
                {
                    value: 'mtb',
                    label: 'mtb'
                },
                {
                    value: 'mtb-a',
                    label: 'mtb-a'
                },
                {
                    value: 'mtc',
                    label: 'mtc'
                },
                {
                    value: 'mtc-a',
                    label: 'mtc-a'
                },
                {
                    value: 'mtd',
                    label: 'mtd'
                },
                {
                    value: 'mtd-a',
                    label: 'mtd-a'
                },
                {
                    value: 'mte',
                    label: 'mte'
                },
                {
                    value: 'mte-a',
                    label: 'mte-a'
                },
                {
                    value: 'mtf',
                    label: 'mtf'
                },
                {
                    value: 'mtf-a',
                    label: 'mtf-a'
                },
                {
                    value: 'mtg',
                    label: 'mtg'
                },
                {
                    value: 'mtg-a',
                    label: 'mtg-a'
                },
                {
                    value: 'mth',
                    label: 'mth'
                },
                {
                    value: 'mth-a',
                    label: 'mth-a'
                },
                {
                    value: 'ply',
                    label: 'ply'
                },
                {
                    value: 'pna',
                    label: 'pna'
                },
                {
                    value: 'pnb',
                    label: 'pnb'
                },
                {
                    value: 'pnc',
                    label: 'pnc'
                },
                {
                    value: 'pnd',
                    label: 'pnd'
                },
                {
                    value: 'pne',
                    label: 'pne'
                },
                {
                    value: 'pnf',
                    label: 'pnf'
                },
                {
                    value: 'png',
                    label: 'png'
                },
                {
                    value: 'pnh',
                    label: 'pnh'
                },
                {
                    value: 'sdb',
                    label: 'sdb'
                },
                {
                    value: 'sdc',
                    label: 'sdc'
                },
                {
                    value: 'sdd',
                    label: 'sdd'
                },
                {
                    value: 'sde',
                    label: 'sde'
                },
                {
                    value: 'sdf',
                    label: 'sdf'
                },
                {
                    value: 'sdg',
                    label: 'sdg'
                },
                {
                    value: 'sdh',
                    label: 'sdh'
                },
                {
                    value: 'sdi',
                    label: 'sdi'
                },
                {
                    value: 'sdj',
                    label: 'sdj'
                },
                {
                    value: 'sdk',
                    label: 'sdk'
                },
                {
                    value: 'sdl',
                    label: 'sdl'
                },
                {
                    value: 'sdm',
                    label: 'sdm'
                },
                {
                    value: 'sdn',
                    label: 'sdn'
                },
                {
                    value: 'sdo',
                    label: 'sdo'
                },
                {
                    value: 'sdp',
                    label: 'sdp'
                },
                {
                    value: 'sdq',
                    label: 'sdq'
                },
                {
                    value: 'sdr',
                    label: 'sdr'
                },
                {
                    value: 'sdu',
                    label: 'sdu'
                },
                {
                    value: 'sdv',
                    label: 'sdv'
                },
                {
                    value: 'sdw',
                    label: 'sdw'
                },
                {
                    value: 'sdx',
                    label: 'sdx'
                },
                {
                    value: 'sdy',
                    label: 'sdy'
                },
                {
                    value: 'sdz',
                    label: 'sdz'
                },
                {
                    value: 'sql',
                    label: 'sql'
                },
                {
                    value: 'stz',
                    label: 'stz'
                },
                {
                    value: 'suz',
                    label: 'suz'
                },
                {
                    value: 'tdr',
                    label: 'tdr'
                },
                {
                    value: 'tds',
                    label: 'tds'
                },
                {
                    value: 'tdt',
                    label: 'tdt'
                },
                {
                    value: 'tdu',
                    label: 'tdu'
                },
                {
                    value: 'tdv',
                    label: 'tdv'
                },
                {
                    value: 'tdw',
                    label: 'tdw'
                },
                {
                    value: 'tdx',
                    label: 'tdx'
                },
                {
                    value: 'tdy',
                    label: 'tdy'
                },
                {
                    value: 'tdz',
                    label: 'tdz'
                },
                {
                    value: 'tfr',
                    label: 'tfr'
                },
                {
                    value: 'tth',
                    label: 'tth'
                },
                {
                    value: 'tth-a',
                    label: 'tth-a'
                },
                {
                    value: 'tts',
                    label: 'tts'
                },
                {
                    value: 'tts-a',
                    label: 'tts-a'
                },
                {
                    value: 'usm',
                    label: 'usm'
                },
                {
                    value: 'usm-d',
                    label: 'usm-d'
                },
                {
                    value: 'var',
                    label: 'var'
                },
                {
                    value: 'lch',
                    label: 'lch'
                },
                {
                    value: 'lieb',
                    label: 'lieb'
                },
                {
                    value: 'checkboard',
                    label: 'checkboard'
                },
                {
                    value: 'star',
                    label: 'star'
                },
                {
                    value: 'ruby',
                    label: 'ruby'
                },
            ],

            grid_type: 'kgm',
            t1_ratio: -1,
            t2_ratio: -1,

        }
    },

    methods: {
        initial_tb_grid(grid_id: any) {
            runWorkflow(new Main(), {
                grid_id: grid_id,
                tb_store: this.$store,
            }).then((data) => {
                //console.log(data.script)
            });
            runWorkflow(new Main_band_data(), {
                grid_id: grid_id,
                t1_ratio: -1,
                t2_ratio: -1,
                onsites_symbol: [0],
                new_grid: true,
                tb_store: this.$store,
            }).then((data) => {
                //console.log(data.plotly_data)
            });
        },

        generate() {
            runWorkflow(new Main_band_data(), {
                grid_id: this.grid_type,
                t1_ratio: this.t1_ratio,
                t2_ratio: this.t2_ratio,
                onsites_symbol: this.wyckoeffs,
                new_grid: false,
                tb_store: this.$store,
            }).then((data) => {
                //console.log(data.plotly_data)
            });
        },

        get_new_grid(grid_type: any) {
            runWorkflow(new Main(), {
                grid_id: grid_type,
                tb_store: this.$store,
            }).then((data) => {
                //console.log(data.script)
            });
            runWorkflow(new Main_band_data(), {
                grid_id: grid_type,
                t1_ratio: -1,
                t2_ratio: -1,
                onsites_symbol: [0],
                new_grid: true,
                tb_store: this.$store,
            }).then((data) => {
                this.t1_ratio = -1
                this.t2_ratio = -1
            });
        }
    },

    watch: {
        grid_type: {
            handler: function() {
                this.get_new_grid(this.grid_type)
            }
        },
    },

    computed: {
        jsmol_struct: {
            get: function () {
                return this.$store.state.tbData.tb_data.script;
            }
        },

        wyckoeffs: {
            get: function () {
                return this.$store.state.tbData.tb_data.wyckoeffs;
            }
        },

        tb_band_data: {
            get: function () {
                return {
                    'k_dist': this.$store.state.tbData.tb_band.k_dist,
                    'plotly_data': this.$store.state.tbData.tb_band.plotly_data,
                    'hsp_nodes': this.$store.state.tbData.tb_band.hsp_nodes,
                    'hsp_labels': this.$store.state.tbData.tb_band.hsp_labels,
                }
            }
        }
    },

    mounted() {
        this.initial_tb_grid('kgm');
    },
})
