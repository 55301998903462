export default {
  state: {
    stableData: [],
    unstableData: []
  },
  mutations: {
    saveStableData(state: any, data: any) {
      state.stableData = data.stable_data;
      state.unstableData = data.unstable_data;
    }
  },
  actions: {
    saveStable (context: any, data: any) {
      context.commit("saveStableData", data);
    }
  }
}