import { AuthApiNode } from "@/nodes/authApiNode"
export class RequestImgInfo extends AuthApiNode {
  url = "/api/xrd/xrd_match/"
  method = "post"
  mode = "direct"

  buildParams = async () => {
    const params_obj = {
      xrd_data: this.inputs.xrd_data,
      elements: this.inputs.elements,
      radiation: this.inputs.radiation,
      match_way: this.inputs.match_way,
    }
    this.$state.commit((state: any) => {
      state.store = this.inputs.upload_store
    })
    return params_obj
  }

}