















import Vue from "vue";
import AboutTab from "@/views/about/AboutTab.vue";
import AboutUs from "@/components/about/AboutUs.vue";
import NavHead from "@/components/NavHead.vue";
import NavFooter from "@/components/NavFooter.vue";
export default Vue.extend({
  components: { AboutTab, AboutUs, NavHead, NavFooter },
  data() {
    return {
      navData: {
        bgc_unscroll: "rgba(224, 224, 224, 0.4)",
        bgc_scroll: "rgba(224, 224, 224, 0.4)",
        sbgc_scroll: "rgba(255,255,255,0.5)",
        sbgc_unscroll: "rgba(255,255,255,0.1)",
        border_color: "#E0E0E0",
        color_unscroll: "#000",
        color_scroll: "#000",
      },
    };
  },
  methods: {},
});
