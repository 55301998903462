import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/about/Atomly.vue"
import Run from "@/views/Run4U.vue";
import Xrd from "@/views/Xrd.vue";
import Ec_window from "@/views/Ec_window.vue";
import Ec_windowDetail from "@/views/Ec_windowDetail.vue";
import Interface_reaction from "@/views/Interface_reaction.vue";
import Matdatades from "@/views/matdata/MatDataDes.vue"
import Matdata from "@/views/matdata/MatData.vue"
import PhaseDiagram from "@/views/matdata/PhaseDiagram.vue"
import Account from "@/views/account/Account.vue"
import Atomly from "@/views/about/Atomly.vue"
import Team from "@/views/about/Team.vue"
import Milestone from "@/views/about/Milestone.vue"
import Advisory from "@/views/about/Advisory.vue"
import Publication from "@/views/about/Publication.vue"
import UserProtocal from "@/views/protocal/UserProtocal.vue"
import PrivateProtocal from "@/views/protocal/PrivateProtocal.vue"
//import PLD_detail from "@/views/pld/PLD_detail.vue";
import Tight_Binding from "@/views/Tight_Binding.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/run4u",
    name: "run4u",
    component: Run,
  },
  {
    path: "/xrd",
    name: "xrd",
    component: Xrd,
  },
  {
    path: "/Ec_window",
    name: "Ec_window",
    component: Ec_window,
  },
  {
    path: "/Interface_reaction",
    name: "Interface_Reaction",
    component: Interface_reaction,
  },
  {
    path: "/Ec_windowDetail/{:id,:work_ion}",
    name: "Ec_windowDetail",
    component: Ec_windowDetail
  },
  {
    path: "/matdata",
    name: "matdata",
    component: Matdata,
  },
  {
    name: "matdatades",
    path: "/matdatades/:id",
    component: Matdatades,
  },
  {
    path: "/phase",
    name: "phase",
    component: PhaseDiagram,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
  },
  {
    path: "/atomly",
    name: "atomly",
    component: Atomly,
  },
  {
    path: "/team",
    name: "team",
    component: Team,
  },
  {
    path: "/milestone",
    name: "milestone",
    component: Milestone,
  },
  {
    path: "/advisory",
    name: "advisory",
    component: Advisory,
  },
  {
    path: "/publication",
    name: "publication",
    component: Publication,
  },
  {
    path: "/protocal/user",
    name: "Protocal",
    component: UserProtocal,
  },
  {
    path: "/protocal/private",
    name: "privateProtocal",
    component: PrivateProtocal,
  },
  {
    path: "/Tight_Binding",
    name: "Tight_Binding",
    component: Tight_Binding,
  },
  //{
  //  name: "PLD_detail",
  //  path: "/PLD_detail/:id",
  //  component: PLD_detail,
  //},
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
