
























import Vue from "vue";
export default Vue.extend({
  name: "HomeHeader",
  data() {
    return {
      mainTitle01: this.$t("home.mainTitle01"),
      mainTitle02: this.$t("home.mainTitle02"),
      mainTitle03: this.$t("home.mainTitle03"),
      ssmainTitle01: this.$t("home.ssmainTitle01"),
      ssmainTitle02: this.$t("home.ssmainTitle02"),
      ssmainTitle02_: this.$t("home.ssmainTitle02_"),
      ssmainTitle03: this.$t("home.ssmainTitle03"),
    }
  },
  watch: {
    '$i18n.locale'(language) {
      this.initLanguage()
    }
  },
  computed: {
    lang: {
      get: function () {
        return localStorage.getItem("languageSet") || 'zh';
      }
    }
  },
  methods: {
    initLanguage() {
      this.mainTitle01 = this.$t("home.mainTitle01")
      this.mainTitle02 = this.$t("home.mainTitle02")
      this.mainTitle03 = this.$t("home.mainTitle03")
      this.ssmainTitle01 = this.$t("home.ssmainTitle01")
      this.ssmainTitle02 = this.$t("home.ssmainTitle02")
      this.ssmainTitle02_ = this.$t("home.ssmainTitle02_")
      this.ssmainTitle03 = this.$t("home.ssmainTitle03")
    }
  }
});
