





















































 
import Vue, { VNode, CreateElement } from "vue";
import { EventBus } from "../eventBus";
import NavHead from "@/components/NavHead.vue";
import NavFooter from "@/components/NavFooter.vue";
import RunTitle from "@/components/xrd/RunTitle.vue";
import UploadCpn from "@/components/xrd/UploadCpn.vue";
import ButtonCpn from "@/components/xrd/ButtonCpn.vue";
import XrdDisplay from "@/components/xrd/XrdDisplay.vue";
import MatchTable from "@/components/xrd/MatchTable.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main_Xrd_Upload_Img } from "@/flows/xrdUploadFlow";
import {Main_Xrd_Normalize_Img} from "@/flows/xrdNormalizeFlow"
import {Main_Xrd_Removebg_Img} from "@/flows/xrdRemovebgFlow"
export default Vue.extend({
    name: "XrdPage",
    components: {
        NavHead,
        NavFooter,
        RunTitle,
        UploadCpn,
        XrdDisplay,
        ButtonCpn,
        MatchTable,
    },
    data() {
        return {
            navData: {
                bgc_unscroll: "rgba(224, 224, 224, 0.4)",
                bgc_scroll: "rgba(224, 224, 224, 0.4)",
                sbgc_scroll: "rgba(255,255,255,0.5)",
                sbgc_unscroll: "rgba(255,255,255,0.1)",
                border_color: "#BDBDBD",
                color_unscroll: "#000",
                color_scroll: "#000",
            },
            upload_text_1: this.$t("xrd.upload"),
            upload_text_2: this.$t("xrd.reupload"),
            upload_file_info: this.$t("xrd.fileInfoTitle"),
            upload_file_info_1: this.$t("xrd.fileInfoContent01"),
            upload_file_info_2: this.$t("xrd.fileInfoContent02"),
            upload_file_name: "",
            isUpload: false,
            flag: false,
            submitStatus: 0,
            normalize_text: this.$t("xrd.normalize"),
            removebg_text: this.$t("xrd.removebg"),
            promptInfo: "Please Upload a structure first!",
            promptColor: "",
            isNormalize: false,
            displayMatchValue: false,
            isNormalizing: false,
            isRemoving: false,
            flag1: true,
            flag2: true,
        };
    },
    beforeCreate() {
        if (!localStorage.getItem("userToken")) {
            this.$router.push("/");
            EventBus.$emit("toShowLoginEvent");
        }
    },
    computed: {
        getBackgroundImage: {
            get: function () {
                const _width = document.documentElement.offsetWidth;
                if (_width >= 800) {
                    return "xrd-page";
                } else {
                        return "xrd-mobile-page";
                }
            },
        },
        xrd_data: {
            get: function () {
                return this.$store.state.xRayData.x_ray_data;
            },
        },
    },
    watch: {
        "$i18n.locale"(language) {
            this.initLanguage();
        },
    },
    methods: {
        setdisplayMatchValue(data: any) {
            this.displayMatchValue = data
        },
        initLanguage() {
            this.upload_text_1 = this.$t("xrd.upload");
            this.upload_text_2 = this.$t("xrd.reupload");
            this.normalize_text = this.$t("xrd.normalize");
            this.removebg_text = this.$t("xrd.removebg");
            this.upload_file_info = this.$t("xrd.fileInfoTitle");
            this.upload_file_info_1 = this.$t("xrd.fileInfoContent01");
            this.upload_file_info_2 = this.$t("xrd.fileInfoContent02");
        },
        uploadCifFile(file_data: any) {
            this.upload_file_name = file_data.name;
            let formData = new FormData();
            formData.append("xrd-file", file_data.raw);
            runWorkflow(new Main_Xrd_Upload_Img(), {
                upload_data: formData,
                upload_store: this.$store,
            }).then((data) => {
                 this.isUpload = true;
                 this.flag1 = false;
                 this.flag2 = false;
            });
        },
        normalize_data() {
            this.isNormalizing = true;
            this.flag1 = true;
            const xrd_data = this.$store.state.xRayDataInitial.x_ray_data_initial;
            runWorkflow(new Main_Xrd_Normalize_Img(), {
                xrd_data: xrd_data,
                upload_store: this.$store,
            }).then((data) => {
                this.isNormalize = true;
                this.isNormalizing = false;
            })
        },
        remove_bg() {
            this.isRemoving = true;
            this.flag2 = true;
            const xrd_data = this.$store.state.xRayDataInitial.x_ray_data_initial;
            runWorkflow(new Main_Xrd_Removebg_Img(), {
                xrd_data: xrd_data,
                upload_store: this.$store,
                isNormalize: this.isNormalize
            }).then((data) => {
                this.isRemoving = false
            })
        },
    }
})
