export default {
  state: {
    search_by_someway_gather: [],
    search_by_someway_time: "",
    search_by_someway_len: "",
    search_by_someway_formula: ""
  },
  mutations: {
    updateSearchBySomeway(state: any, data: any) {
      state.search_by_someway_gather = data;
    },
    addCurrentTime(state: any, data: any) {
      state.search_by_someway_time = data
    },
    getLenAndFormulaBySomeway(state: any, data: any) {
      state.search_by_someway_len = data.length
      state.search_by_someway_formula = data.formula
    }
  },
  actions: {
    updateBySomeway(context: any, data: any) {
      context.commit("updateSearchBySomeway", data);
    },
    addTime(context: any) {
      const date = new Date();
      const near_cur = date.getFullYear();
      const month_cur = date.getMonth() + 1;
      const day_cur = date.getDate();
      const time_cur = near_cur + '-' + month_cur + '-' + day_cur
      context.commit("addCurrentTime", time_cur)
    },
    getLenAndFormula(context: any, data: any) {
      context.commit("getLenAndFormulaBySomeway", data)
    }
  },
};
