







import Vue from "vue";
export default Vue.extend({
  props: {
    tabData: Object,
  },
});
