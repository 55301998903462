import { AuthApiNode } from "@/nodes/authApiNode"
export class GenerateXRay extends AuthApiNode {
  url = "/api/matdata/get_xrd_handle2/"
  method = "post"
  mode = "direct"

  buildParams = async () => {
    this.$state.commit((state: any) => {
      state.store = this.inputs.generate_store
    })
    const params_obj = {
      wavelength: this.inputs.generate_waveLength,
      crystalSize: this.inputs.generate_size,
      struct_id: this.inputs.generate_id
    }
    return params_obj
  }

}