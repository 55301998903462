import formatFormula from "@/utils/formatFormula"
export default function processSearchTableResult(before_arr: Array<any>) {
  let after_arr = new Array()
    before_arr.forEach((item: any) => {
      let temp = new Object();
      temp = {
        struct_id: item[0] || "-",
        formula: formatFormula(item[1]),
        space_group: item[2] || "-",
        form_energy: item[3] === 0 ? "0" : item[3] || "-",
        e_above_hull: item[4] === 0 ? "0" : item[4] || "-",
        band_gap: item[5] === 0 ? "0" : item[5] || "-",
        nsites: item[6] === 0 ? "0" : item[6] || "-",
        efermi: item[7] === 0 ? "0" : item[7] || "-",
        crystal_system: item[8] || "-",
        lattice_type: item[9] || "-",
        has_band: item[10] === true ? "true" : "false",
        is_hubbard: item[11] === true ? "true" : "false",
        has_hse: item[12] === true ? "true" : "false",
        has_epsilon: item[13] === true ? "true" : "false",
        has_piezo: item[14] === true ? "true" : "false",
        has_elastic: item[15] === true ? "true" : "false",
        
        //space_group: item[4] || "-",
        //energy: item[2] === 0 ? "0" : item[2]  || "-",
        //ehull: item[9] === 0 ? "0" : item[9] || "-",
        //band_gap: item[5] === 0 ? "0" : item[5] || "-",
        //niste: item[3] === 0 ? "0" : item[3] || "-",
        //efermi: item[6] === 0 ? "0" : item[6] || "-",
        //band: item[7] === true ? "true" : "false",
        //dos: item[8] === true ? "true" : "false",
        //e_formation: item[10] === 0 ? "0" : item[10] || "-",
        //hse: (item[11] && item[11] === true) ? "true" : (item[11] && item[11] === false) ? "false" : "-",
      };
      after_arr.push(temp);
    })
    return after_arr;
}