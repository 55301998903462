import { APINode } from "arkfbp/lib/apiNode";

export class BindTokenNode extends APINode {
  mode = "direct";
  url = "/api/arkid/bind_get_sms_token/";
  method = "post";

  async buildParams() {
    const mobile = this.$state.fetch().mobile;
    const code = this.$state.fetch().code;
    return { mobile: mobile, code: code };
  }
}
