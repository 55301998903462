import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { RequestImgInfo } from "./nodes/RequestImgInfo"
import { StoreXrdInfo } from "./nodes/StoreImgInfo"

export class Main_Xrd_Match_Img extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "1",
        next: "2",
      },
      {
        cls: RequestImgInfo,
        id: "2",
        next: "3",
      },
      {
        cls: StoreXrdInfo,
        id: "3",
        next: "4",
      },
      {
        cls: StopNode,
        id: "4",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
