
























































































import Vue from "vue";
import PeriodicBox from "@/components/matdata/PeriodicBox.vue";
export default Vue.extend({
  components: {
    PeriodicBox,
  },
  data() {
    return {
      tableConfig: require("./table.json"),
      periodicIndex1: "",
      periodicIndex2: "",
      periodicIndex3: "",
      periodicData: [],
      selectIndex: "",
      selectName: "",
      showAll: false,
    };
  },

  computed: {
    showList: function() {
      const that: any = this;
      if (this.showAll == false) {
        var showList = [];
        if (this.periodicIndex3.length > 5) {
          for (var i = 0; i < 5; i++) {
            showList.push(that.periodicIndex3[i] as string);
          }
        } else {
          showList = that.periodicIndex3;
        }
        return showList;
      } else {
        return that.periodicIndex3;
      }
    },
  },
  mounted() {
    this.getPeriodicData();
  },
  methods: {
    getPeriodicData() {
      this.periodicIndex1 = this.tableConfig.position1;
      this.periodicIndex2 = this.tableConfig.position2;
      this.periodicIndex3 = this.tableConfig.position3;
      this.periodicData = this.tableConfig.data;
    },
    getData(index: number) {
      let data: any = this.periodicData[index];
      if (data) return data.name;
      return "0";
    },
    getColor(index: number) {
      let data: any = this.periodicData[index];
      if (data) {
        return data.color;
      }
      return "visibility:hidden";
    },
    getEnlargePeriodic(val1: string, val2: string) {
      this.selectIndex = val1;
      this.selectName = val2;
    },
    getSearchPeriodic(name: string) {
      this.$emit("setPeriodicTable", name);
    },
  },
});
