























import Vue from "vue";
import LoginFooter from "@/components/login/LoginFooter.vue";
import LoginInput from "@/components/login/LoginInput.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { MobileCode } from "@/flows/mobileCode";
import { MobileLogin } from "@/flows/mobileLogin";
import { WxLoginRequest } from "@/flows/wxLoginRequest";
import { GitLoginRequest } from "@/flows/gitLoginRequest";

export default Vue.extend({
  components: { LoginFooter, LoginInput },
  data() {
    return {
      text: this.$t("placeholder.nextStep"),
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.initProtocalData();
    },
  },

  methods: {
    initProtocalData() {
      this.text = this.$t("placeholder.nextStep");
    },
    getCodeHandler(mobile: string) {
      runWorkflow(new MobileCode(), {
        mobile: mobile,
        store: this.$store,
      });
    },

    submitHandler(mobile: string, code: string) {
      runWorkflow(new MobileLogin(), {
        mobile: mobile,
        code: code,
        store: this.$store,
      }).then((data) => {
        if (data.token) {
          this.$store.dispatch("setUserInfo", data);
          window.localStorage.setItem("userToken", data.token);
          if (this.$store.state.login.loginOrRegister.data == "login") {
            this.$store.state.login.loginShow = false;
             window.location.href = this.$route.path.replace("/", "/#/");
          } else {
            //跳转绑定账户其他信息
            this.$emit("goAccountShow");
          }
        } else {
          this.$message({
            message: "Oops,fail to login",
            type: "error",
            // offset: 100,
          });
        }
      });
    },
    loginGithub() {
      runWorkflow(new GitLoginRequest(), {});
    },
    loginWeChat() {
      runWorkflow(new WxLoginRequest(), {});
    },
  },
});
