




import Vue from "vue";
// @ts-ignore
import Plotly from 'plotly.js-dist';
export default Vue.extend({
  name: "BandComponent",
  data() {
    return {
      scale: 1,
    };
  },
  props: {
    banddata: Object,
    num: {
      type: Number,
      required: false,
    },
  },
  watch: {
    banddata(dic: any) {
      const that = this;
      
      if (this.num == 2) {
        let line_type = { width: 2, color: "#7f7f7f" };
        let marker_type = { size: 8, color: "teal", opacity: 0.8 };
        let marker_type_2 = { size: 8, color: "#af5f00", opacity: 0.5 };

        let data = [];
        for (let i = 0; i < dic.data.length; i++) {
          if (i != dic.data.length - 1 && i != dic.data.length - 2) {
            data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              mode: "lines",
              text: ["", ""],
              hoverinfo: "text",
              line: line_type,
              showlegend: false,
            };
          } else if (i == dic.data.length - 1) {
            data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              text: dic.data[i]["text"],
              id: dic.data[i]["id"],
              mode: "markers",
              hoverinfo: "text",
              marker: marker_type,
              name: "Stable",
            };
          } else {
            data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              text: dic.data[i]["text"],
              id: dic.data[i]["id"],
              tid: dic.data[i]["tid"],
              mode: "markers",
              hoverinfo: "text",
              marker: marker_type_2,
              name: "Unstable",
              visible: 'legendonly'
            };
          }
        }

        let annotations = [];
        for (let i = 0; i < dic.annotations.length; i++) {
          annotations[i] = {
            showarrow: false,
            x: dic.annotations[i]["x"],
            y: dic.annotations[i]["y"],
            mode: "text",
            text: dic.annotations[i]["text"],
            yanchor: "top",
            xanchor: 'right',
            font: { color: "black", size: 10 },
          };
        }

        let layout = {
          legend: {
            x: 1.15,
            y: 1.15,
            "orientation": "h",
            xanchor: 'right',
            font: {
                size: 16
            }
          },
          autosize: true,
          hovermode: "closest",
          annotations: annotations,
          showlegend: true,

          xaxis: {
            title: dic.title,
            zeroline: false,
            showgrid: true,
            automargin: true,
            showline: true,
            ticks: 'outside',

          },
          yaxis: {
            title: 'Formation energy (eV/atom)',
            zeroline: false,
            showgrid: true,
            automargin: true,
            showline: true,
            ticks: 'outside',
            
          },

        };
        //@ts-ignore
        Plotly.newPlot("pdgraph", data, layout, {
          displayModeBar: false,
          responsive: true,
        });
        //@ts-ignore
        let myPlot_ = document.getElementById("pdgraph");
        //@ts-ignore
        myPlot_.on("plotly_click", function (data_) {
          for (let i = 0; i < data_.points.length; i++) {
            let name = data_.points[i].fullData.name;
            if (name == "Stable" || name == "Unstable") {
              let index = 0;
              for (let j = 0; j < data_.points[i].fullData.x.length; j++) {
                if (
                  data_.points[i].fullData.x[j] == data_.points[i].x &&
                  data_.points[i].fullData.y[j] == data_.points[i].y
                ) {
                  index = j;
                  break;
                }
              }
              if (name == "Stable") {
                const { href } = that.$router.resolve({
                  name: "matdatades",
                  params: {
                    id: data_.points[i].data["id"][index],
                  },
                });
                window.open(href, "_blank");
              } else {
                const { href } = that.$router.resolve({
                  name: "matdatades",
                  params: {
                    id: data_.points[i].data["tid"][index],
                  },
                });
                window.open(href, "_blank");
              }
            }
          }
        });
      }

      if (this.num == 3) {
        let line_type = { width: 2, color: "#7f7f7f" };
        let marker_type = { size: 8, color: "teal", opacity: 0.8 };
        let marker_type_2 = { size: 8, color: "#af5f00", opacity: 0.5 };

        let data = [];
        for (let i = 0; i < dic.data.length; i++) {
          if (i != dic.data.length - 1 && i != dic.data.length - 2) {
            data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              mode: "lines",
              text: ["", ""],
              hoverinfo: "text",
              line: line_type,
              showlegend: false,
            };
          } else if (i == dic.data.length - 1) {
            data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              text: dic.data[i]["text"],
              id: dic.data[i]["id"],
              mode: "markers",
              hoverinfo: "text",
              marker: marker_type,
              name: "Stable",
            };
          } else {
            data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              text: dic.data[i]["text"],
              id: dic.data[i]["id"],
              tid: dic.data[i]["tid"],
              mode: "markers",
              hoverinfo: "text",
              marker: marker_type_2,
              name: "Unstable",
              visible: 'legendonly'
            };
          }
        }

        let annotations = [];
        for (let i = 0; i < dic.annotations.length; i++) {
          annotations[i] = {
            showarrow: false,
            x: dic.annotations[i]["x"],
            y: dic.annotations[i]["y"],
            mode: "text",
            text: dic.annotations[i]["text"],
            yanchor: "top",
            xanchor: 'right',
            font: { color: "black", size: 10 },
          };
        }

        let layout = {
          legend: {
            x: 1.15,
            y: 1.15,
            "orientation": "h",
            xanchor: 'right',
            font: {
                size: 16
            }
          },
          autosize: true,
          hovermode: "closest",
          annotations: annotations,
          showlegend: true,

          xaxis: {
            showbackground: false,
            zeroline: false,
            showgrid: false,
            ticks: "",
            showlabels: false,
            showticklabels: false,
            showspikes: false,
          },
          yaxis: {
            showbackground: false,
            zeroline: false,
            showgrid: false,
            ticks: "",
            showlabels: false,
            showticklabels: false,
            showspikes: false,
          },

        };
        //@ts-ignore
        Plotly.newPlot("pdgraph", data, layout, {
          displayModeBar: false,
          responsive: true,
        });
        //@ts-ignore
        let myPlot_ = document.getElementById("pdgraph");
        //@ts-ignore
        myPlot_.on("plotly_click", function (data_) {
          for (let i = 0; i < data_.points.length; i++) {
            let name = data_.points[i].fullData.name;
            if (name == "Stable" || name == "Unstable") {
              let index = 0;
              for (let j = 0; j < data_.points[i].fullData.x.length; j++) {
                if (
                  data_.points[i].fullData.x[j] == data_.points[i].x &&
                  data_.points[i].fullData.y[j] == data_.points[i].y
                ) {
                  index = j;
                  break;
                }
              }
              if (name == "Stable") {
                const { href } = that.$router.resolve({
                  name: "matdatades",
                  params: {
                    id: data_.points[i].data["id"][index],
                  },
                });
                window.open(href, "_blank");
              } else {
                const { href } = that.$router.resolve({
                  name: "matdatades",
                  params: {
                    id: data_.points[i].data["tid"][index],
                  },
                });
                window.open(href, "_blank");
              }
            }
          }
        });
      } 

      if (this.num == 4) {
        let xyz_line_type = { width: 2, color: "grey" };
        let xyz_marker_type = { size: 8, color: "teal", opacity: 0.8 };
        let xyz_marker_type_2 = { size: 8, color: "#af5f00", opacity: 0.5 };

        let xyz_axis_type = {
          title: "",
          ticks: "",
          showlabels: false,
          showbackground: false,
          zeroline: false,
          showgrid: false,
          showticklabels: false,
          showspikes: false
        };

        let xyz_data = [];
        for (let i = 0; i < dic.data.length; i++) {
          if (
            i != dic.data.length - 1 &&
            i != dic.data.length - 2
          ) {
            xyz_data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              z: dic.data[i]["z"],
              type: "scatter3d",
              mode: "lines",
              text: ["", ""],
              hoverinfo: "text",
              line: xyz_line_type,
              showlegend: false,
            };
          } else if (i == dic.data.length - 1) {
            xyz_data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              z: dic.data[i]["z"],
              text: dic.data[i]["text"],
              id: dic.data[i]["id"],
              type: "scatter3d",
              mode: "markers",
              hoverinfo: "text",
              marker: xyz_marker_type,
              name: "Stable",
            };
          } else {
            xyz_data[i] = {
              x: dic.data[i]["x"],
              y: dic.data[i]["y"],
              z: dic.data[i]["z"],
              text: dic.data[i]["text"],
              id: dic.data[i]["id"],
              type: "scatter3d",
              mode: "markers",
              hoverinfo: "text",
              marker: xyz_marker_type_2,
              name: "Unstable",
              visible: 'legendonly',
              tid: dic.data[i]["tid"],
            };
          }
        }

        let xyz_annotations = [];
        for (let i = 0; i < dic.annotations.length; i++) {
          xyz_annotations[i] = {
            showarrow: false,
            x: dic.annotations[i]["x"],
            y: dic.annotations[i]["y"],
            z: dic.annotations[i]["z"],
            text: dic.annotations[i]["text"],
            mode: "text",
            yanchor: "top",
            xanchor: 'right',
            font: { color: "black", size: 10 },
          };
        }
        
        let xyz_layout = {
          scene: {
            camera: {
              eye: { x: 1.6, y: 0.1, z: 0.1 },
              center: { x: -0.2, y: -0.2, z: -0.2 },
            },
            xaxis: xyz_axis_type,
            yaxis: xyz_axis_type,
            zaxis: xyz_axis_type,
            annotations: xyz_annotations,
          },
          legend: {
            x: 1.15,
            y: 1.15,
            "orientation": "h",
            xanchor: 'right',
            font: {
                size: 16
            }
          },
          autosize: true,
          hovermode: "closest",
        };
        
        // @ts-ignore
        Plotly.newPlot("pdgraph", xyz_data, xyz_layout, {
          displayModeBar: false,
          responsive: true,
        });
        //@ts-ignore
        let _myPlot_ = document.getElementById("pdgraph");
        //@ts-ignore
        _myPlot_.on("plotly_click", function (_data_) {
          for (let i = 0; i < _data_.points.length; i++) {
            let name = _data_.points[i].fullData.name;
            if (name == "Stable" || name == "Unstable") {
              let index = 0;
              for (let j = 0; j < _data_.points[i].fullData.x.length; j++) {
                if (
                  _data_.points[i].fullData.x[j] == _data_.points[i].x &&
                  _data_.points[i].fullData.y[j] == _data_.points[i].y
                ) {
                  index = j;
                  break;
                }
              }
              if (name == "Stable") {
                const { href } = that.$router.resolve({
                  name: "matdatades",
                  params: {
                    id: _data_.points[i].data["id"][index],
                  },
                });
                window.open(href, "_blank");
              } else {
                const { href } = that.$router.resolve({
                  name: "matdatades",
                  params: {
                    id: _data_.points[i].data["tid"][index],
                  },
                });
                window.open(href, "_blank");
              }
            }
          }
        });
      }
    },
  },
});
