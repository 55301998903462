
// 使用该控件需要在public/index.html中引入JSmol.min.js文件，jquery可以通过npm安装或者也在index.html中引入
import Vue, { CreateElement, VNode } from "vue";

const JmolComponent = Vue.extend({
  props: {
    data: String,
    buttonData: String,
    poly_value: Boolean,
    bonds_value: Boolean,
    unitcell_value: Boolean,
    label_value: Boolean,
  },
  watch: {
    data(val: string, oldval: string) {
      if (val) {
        this.toScript(val)
      }
    },
    label_value(val: Boolean) {
      if (val == true) {
        this.hideLabel();
      } else {
        this.showLabel();
      }
    },
    unitcell_value(val: Boolean) {
      if (val == true) {
        this.hideUnitcell();
        } else {
        this.showUnitCell();
      }
    },
    bonds_value(val: Boolean) {
      if (val == true) {
        this.hideBonds();
        } else {
        this.showBonds();
      }
    },
    poly_value(val: Boolean) {
      if (val == true) {
        this.hidePolyhedra();
        } else {
        this.showPolyhedra();
      }
    },
  },
  //mounted() {
  //  const data_include = this.$store.state.matDataDetails.mat_data_des.output_structs.jsmol_struct;
  //  if (this.data != null && this.data != "" && (this.data.indexOf(data_include) > 0 || location.hash.indexOf('run4u') > 0)) {
  //    //@ts-ignore
  //    Jmol.script(jmolApplet0, this.data);
  //  }
  //},
  methods: {
    toScript(scriptString: string) {
      if (scriptString && scriptString != "") {
        //@ts-ignore
        Jmol.script(jmolApplet0, scriptString);
      }
    },
    showLabel() {
      //@ts-ignore
      Jmol.script(jmolApplet0, "label display;font label 15");
    },
    hideLabel() {
      //@ts-ignore
      Jmol.script(jmolApplet0, "label hide");
    },
    showUnitCell() {
      //@ts-ignore
      Jmol.script(jmolApplet0, "unitcell on;unitcell 0.01");
    },
    hideUnitcell() {
      //@ts-ignore
      Jmol.script(jmolApplet0, "unitcell off");
    },
    showBonds() {
      //@ts-ignore
      Jmol.script(jmolApplet0, "display bonds;wireframe 0.08");
    },
    hideBonds() {
      //@ts-ignore
      Jmol.script(jmolApplet0, "hide bonds");
    },
    showPolyhedra() {
      //@ts-ignore
      Jmol.script(jmolApplet0, 
        "polyhedra bonds \
        (connected(4) or connected(6) and visible) \
        distanceFactor 2.0;\
        color polyhedra translucent;"
      );
    },
    hidePolyhedra() {
      //@ts-ignore
      Jmol.script(jmolApplet0, "polyhedra off");
    },
    getWidthMultiplier() {
      if (document.documentElement.offsetWidth >= 1440) {
        if (location.hash.includes('run4u')) {
          return "713"
        }
        if (location.hash.includes('Tight_Binding')) {
          return "600"
        }
        return "1000";
      } else if (
        document.documentElement.offsetWidth >= 800.1 &&
        document.documentElement.offsetWidth < 1440
      ) {
        if (location.hash.includes('run4u')) {
          return document.documentElement.offsetWidth * 0.5;
        }
        return document.documentElement.offsetWidth * 0.7;
      } else {
        if (location.hash.includes('run4u')) {
          return document.documentElement.offsetWidth * 0.85;
        }
        return document.documentElement.offsetWidth * 1;
      }
    },
    getHeightMultiplier() {
      if (document.documentElement.offsetWidth >= 1440) {
        if (location.hash.includes('run4u')) {
          return "481"
        }
        if (location.hash.includes('Tight_Binding')) {
          return "500"
        }
        return "700";
      } else if (
        document.documentElement.offsetWidth >= 800.1 &&
        document.documentElement.offsetWidth < 1440
      ) {
        if (location.hash.includes('run4u')) {
          return document.documentElement.offsetWidth * 0.33;
        }
        return document.documentElement.offsetWidth * 0.49;
      } else {
        if (location.hash.includes('run4u')) {
          return document.documentElement.offsetWidth * 0.7;
        }
        return document.documentElement.offsetWidth * 0.7;
      }
    }
  },

  render: function (h: CreateElement): VNode {
    if (location.hash.includes('matdatades')) {
    return h("div", [
      h("div", { attrs: { id: "jmoldiv" } }),
      h(
        "script",
        `
          var jmolApplet0; 
          var Info = {
              width: ` +
                    this.getWidthMultiplier() +
                    ` ,
              height: ` +
                    this.getHeightMultiplier() +
                    ` ,
              use: "HTML5",
              j2sPath: "./jsmol/j2s", 
              jarPath: "./jsmol/java",
              isSigned: true,
              script: "set displayCellParameters FALSE; wireframe 0.08;spacefill 20%;set atomPicking FALSE; zoom 80;unitcell 0.01;background [255, 255, 255];set frank off;center visible; set disablePopupMenu True; set specularPercent 0; frank OFF; set minimizationRefresh FALSE;set antialiasDisplay TRUE; set appendNew false;set antialiasdisplay true;axes off;set frank off;label HIDE;",
              serverURL: "./jsmol/php/jsmol.php",
              disableInitialConsole: true,
              antialiasDisplay: !0,
              disableJ2SLoadMonitor: !1,
              deferUncover: !1, 
              deferApplet: !1,
          }

          $(document).ready(function() {
              Jmol.getApplet("jmolApplet0", Info)
              var a = Jmol.getAppletHtml("jmolApplet0", Info);
              $("#jmoldiv").html(a)
          })
        `
      ),
    ]);
    } else {
      return h("div", [
      h("div", { attrs: { id: "jmoldiv" } }),
      h(
        "script",
        `
          var jmolApplet0; 
          var Info = {
              width: ` +
                    this.getWidthMultiplier() +
                    ` ,
              height: ` +
                    this.getHeightMultiplier() +
                    ` ,
              use: "HTML5",
              j2sPath: "./jsmol/j2s", 
              jarPath: "./jsmol/java",
              isSigned: true,
              script: "set displayCellParameters FALSE; wireframe 0.08;spacefill 20%;set atomPicking FALSE; zoom 80;unitcell 0.01;background [255, 255, 255];set frank off;center visible; set disablePopupMenu True; set specularPercent 0; frank OFF; set minimizationRefresh FALSE;set antialiasDisplay TRUE; set appendNew false;set antialiasdisplay true;axes off;set frank off;label HIDE;",
              disableInitialConsole: true,
              antialiasDisplay: !0,
              disableJ2SLoadMonitor: !1,
              deferUncover: !1, 
              deferApplet: !1,
          }

          $(document).ready(function() {
              Jmol.getApplet("jmolApplet0", Info)
              var a = Jmol.getAppletHtml("jmolApplet0", Info);
              $("#jmoldiv").html(a)
          })
        `
      ),
    ]);
    }
    }
});

export default JmolComponent;
