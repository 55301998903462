import { FunctionNode } from 'arkfbp/lib/functionNode'
import formatFormula from '@/utils/formatFormula'
export class UpdateRunHistoryFile extends FunctionNode {
  async run() {
    const _store = this.$state.fetch().store
    //let fFormula: Array<any> = []
    //if (this.inputs.data && this.inputs.data.formula) {
    //  this.inputs.data.formula.forEach((item: any) => {
    //    fFormula.push(formatFormula(item))
    //  });
    //}
    //this.inputs.data.formula = fFormula
    _store.dispatch("updateRunHistory", this.inputs)
    return this.inputs
  }
}