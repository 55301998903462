export default {
  state: {
    // 存储Brillouin-Zone数据
    // b_zone => brillouinZone => ...
    b_zone: {}
  },
  mutations: {
    saveBZoneData (state: any, data: any) {
      state.b_zone = data
    }
  },
  actions: {
    saveBZone (context: any, data: any) {
      context.commit("saveBZoneData", data);
    }
  }
}