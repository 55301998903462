




























































import Vue from "vue";
// @ts-ignore 
import Plotly from "plotly.js-dist";
import XButton from "@/components/public/operateBox/ButtonCpn.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import {Main_Xrd_Match_Img} from "@/flows/xrdMatchFlow";
export default Vue.extend({
    name: "XrdDisplay",
    components: {
        XButton
    },
    props: {
        model: Object,
    },
    data() {
        return {
            radiation_wavelength: "1.54184",
            isSelectWaveParams: false,
            checked: true,
            check: false,
            particle_size: "",
            isMatching: false,
            flag3: false,
            displayMatchValue: false,
            waveLengthParamsItem: {
                name: "CuKα",
                id: "0",
                defalut_radiation_wavelength: "1.54184",
            },
            waveLengthParams: [
                {
                    name: "CuKα",
                    id: "1",
                    defalut_radiation_wavelength: "1.54184",
                },
                {
                    name: "CuKα2",
                    id: "2",
                    defalut_radiation_wavelength: "1.54439",
                },
                {
                    name: "CuKα1",
                    id: "3",
                    defalut_radiation_wavelength: "1.54056",
                },
                {
                    name: "CuKβ1",
                    id: "4",
                    defalut_radiation_wavelength: "1.39222",
                },
                {
                    name: "MoKα",
                    id: "5",
                    defalut_radiation_wavelength: "0.71073",
                },
                {
                    name: "MoKα2",
                    id: "6",
                    defalut_radiation_wavelength: "0.71359",
                },
                {
                    name: "MoKα1",
                    id: "7",
                    defalut_radiation_wavelength: "0.7093",
                },
                {
                    name: "MoKβ1",
                    id: "8",
                    defalut_radiation_wavelength: "0.63229",
                },
                {
                    name: "CrKα",
                    id: "9",
                    defalut_radiation_wavelength: "2.291",
                },
                {
                    name: "CrKα2",
                    id: "10",
                    defalut_radiation_wavelength: "2.29361",
                },
                {
                    name: "CrKα1",
                    id: "11",
                    defalut_radiation_wavelength: "2.2897",
                },
                {
                    name: "CrKβ1",
                    id: "12",
                    defalut_radiation_wavelength: "2.08487",
                },
                {
                    name: "FeKα",
                    id: "13",
                    defalut_radiation_wavelength: "1.93735",
                },
                {
                    name: "FeKα2",
                    id: "14",
                    defalut_radiation_wavelength: "1.93998",
                },
                {
                    name: "FeKα1",
                    id: "15",
                    defalut_radiation_wavelength: "1.93604",
                },
                {
                    name: "FeKβ1",
                    id: "16",
                    defalut_radiation_wavelength: "1.75661",
                },
                {
                    name: "CoKα",
                    id: "17",
                    defalut_radiation_wavelength: "1.79026",
                },
                {
                    name: "CoKα2",
                    id: "18",
                    defalut_radiation_wavelength: "1.79285",
                },
                {
                    name: "CoKα1",
                    id: "19",
                    defalut_radiation_wavelength: "1.78896",
                },
                {
                    name: "CoKβ1",
                    id: "20",
                    defalut_radiation_wavelength: "1.63079",
                },
                {
                    name: "AgKα",
                    id: "21",
                    defalut_radiation_wavelength: "0.560885",
                },
                {
                    name: "AgKα2",
                    id: "22",
                    defalut_radiation_wavelength: "0.563813",
                },
                {
                    name: "AgKα1",
                    id: "23",
                    defalut_radiation_wavelength: "0.559421",
                },
                {
                    name: "AgKβ1",
                    id: "24",
                    defalut_radiation_wavelength: "0.497082",
                },
            ],
        }
    },
    methods: {
        
        getCurrentXRayHeight() {
            const cur_screen_height = document.getElementById("xrd")?.offsetHeight;
            return cur_screen_height
        },
        selectWaveParams(wave_data: any) {
            this.isSelectWaveParams = wave_data;
        },
        changeButtonContent(e: any) {
            this.waveLengthParams.forEach((item, index) => {
                if (item.name == e) {
                    this.radiation_wavelength = item.defalut_radiation_wavelength;
                }
            });
        },
        match_way1() {
            if (this.checked==false) {
                this.check = true
            } else {
                this.check = false
            }
        },
        match_way2() {
            if (this.check==false) {
                this.checked = true
            } else {
                this.checked = false
            }
        },
        match() {
            this.flag3 = true;
            const xrd_data = this.$store.state.xRayDataBg.x_ray_data_bg;
            this.isMatching = true;
            let match_way = "";
            if (this.checked){
                match_way = "DTW"
            } else {
                match_way = "Euclidean"
            }
            runWorkflow(new Main_Xrd_Match_Img(), {
                xrd_data: xrd_data,
                elements: this.particle_size,
                radiation: this.radiation_wavelength,
                upload_store: this.$store,
                match_way: match_way,
            }).then((data) => {
                if(data && data.error == "true"){
                    this.flag3 = false;
                    this.isMatching = false;
                    this.$message({
                        message: data.message,
                        type: "error",
                        duration: 3000,
                        showClose: true,
                        customClass: "error-message-box",
                        // offset: 100,
                    });
                }
                else{
                    this.displayMatchValue = true;
                    this.isMatching = false;
                    this.flag3 = false;
                }
            })
        },
    },
    watch: {
        model(data: any) {
            var plotly_data = [
                {
                    mode: "lines",
                    x: data.x,
                    y: data.y,
                    line: {
                        width: 1,
                    },
                    name: data.name
                },
            ];

            var axis_type = {
                title: "",
                showbackground: false,
                zeroline: false,
                showgrid: false,
                ticks: "",
                showlabels: false,
                showticklabels: false,
                showspikes: false,
            };
            var layout = {
                xaxis: {
                    title: "2Θ (degree)",
                    rangeslider: {
                        autorange: true,
                    },
                    autorange: true,
                },
                yaxis: {
                    title: "Intensity",
                    fixedrange: true,
                },
                autosize: true,
                hovermode: "closest",
                height: this.getCurrentXRayHeight(),
            };
            //@ts-ignore
            if (data.name == "pristine"){
                Plotly.purge("xrd")
            }
            if (data.name == "normlized"){
                Plotly.deleteTraces("xrd", 0)
            }
            Plotly.plot("xrd", plotly_data, layout, {
                displayModeBar: true,
                responsive: true,
                scrollZoom: true,
                displaylogo: false
            });
            // newPlot: deleate previous figure; plot: do not deleate. 
        },
        displayMatchValue() {
            this.$emit('setdisplayMatchValue', this.displayMatchValue)
        },
    },
})
