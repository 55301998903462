
















































































import Vue from 'vue'
import FourFunItem from './FourFunctionItem.vue'
export default Vue.extend({
  name: "FourFunction",
  components: {
    FourFunItem
  },
  data() {
    return {
      firstFunctionTitle: this.$t("home.firstFunctionTitle"),
      firstFunctionContent: this.$t("home.firstFunctionContent"),
      secondFunctionTitle: this.$t("home.secondFunctionTitle"),
      secondFunctionContent: this.$t("home.secondFunctionContent"),
      thirdFunctionTitle: this.$t("home.thirdFunctionTitle"),
      thirdFunctionContent: this.$t("home.thirdFunctionContent"),
      fourthFunctionTitle: this.$t("home.fourthFunctionTitle"),
      fourthFunctionContent: this.$t("home.fourthFunctionContent"),
    }
  },
  watch: {
    '$i18n.locale'(language) {
      this.initLanguage()
    }
  },
  methods: {
    initLanguage() {
      this.firstFunctionTitle = this.$t("home.firstFunctionTitle")
      this.firstFunctionContent = this.$t("home.firstFunctionContent")
      this.secondFunctionTitle = this.$t("home.secondFunctionTitle")
      this.secondFunctionContent = this.$t("home.secondFunctionContent")
      this.thirdFunctionTitle = this.$t("home.thirdFunctionTitle")
      this.thirdFunctionContent = this.$t("home.thirdFunctionContent")
      this.fourthFunctionTitle = this.$t("home.fourthFunctionTitle")
      this.fourthFunctionContent = this.$t("home.fourthFunctionContent")
    }
  }
})
