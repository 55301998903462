export default {
  state: {
    team_data: []
  },
  getters: {},
  mutations: {
    saveTeamData(state: any, data: any) {
      state.team_data = data;
    },
  },
  actions: {
    saveTeam (context: any, data: any) {
      context.commit("saveTeamData", data);
    }
  }
};
