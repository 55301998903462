









import Vue from "vue";
import AboutContainer from "@/components/about/AboutContainer.vue";

export default Vue.extend({
  components: { AboutContainer },
  data() {
    return {
      imgUrl: require("@/assets/icon/atomlyinfo.svg"),
      text: this.$t("about.atomlyText"),
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.text = this.$t("about.atomlyText");
    },
  },
});
