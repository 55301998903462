import { FunctionNode } from 'arkfbp/lib/functionNode'
export class UpdateData extends FunctionNode {
  async run() {
    const _store = this.$state.fetch().store
    const _data = this.inputs

    _store.dispatch("saveTbBand", _data)

    return this.inputs
  }
}