export default {
    state: {
      interface_data: {
        atomic_fraction: new Array(),
        reaction_energy: new Array(),
        interface_table_data: new Array(),
        atomic_title: new String(),
        molar_title: new String(),
        molar_text: new Array(),
        min_re_index: new Number(),
        text: new Array()
      },
    },
    mutations: {
        saveInterfaceData_(state: any, data: any) {
        state.interface_data = data;
      },
    },
    actions: {
      saveInterfaceData(context: any, data: any) {
        context.commit("saveInterfaceData_", data);
      },
    },
  };