





















































import Vue from "vue";
export default Vue.extend({
  name: "FourFunction",
  props: {
    layout: String,
    title: String,
    content: String,
  },
});
