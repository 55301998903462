export default {
    state: {
      checked_box_data: [
        {
          name: "Struct_id",
          checked: true
        },
        {
          name: "Formula",
          checked: true
        },
        {
          name: "Reduction_onset(V)",
          checked: true
        },
        {
          name: "Oxidation_onset(V)",
          checked: true
        },
        {
          name: "Work_ion",
          checked: true
        },
        {
          name: "Ehull(eV)",
          checked: true
        },
        {
          name: "BandGap(eV)",
          checked: true
        },
        {
          name: "Nsites",
          checked: true
        },
        {
          name: "Spacegroup",
          checked: false
        },
        {
          name: "Crystal System",
          checked: false
        },
        {
          name: "Pointgroup",
          checked: false
        },
        {
          name: "Hall",
          checked: false
        }
      ]
    },
    mutations: {
      updateCheckedBoxData(state: any, data: any) {
        state.checked_box_data = data;
      }
    },
    actions: {
      ecwUpdateCheckedBox(context: any, data: any) {
        context.commit("updateCheckedBoxData", data);
      }
    }
  };
  