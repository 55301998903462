export default {
    state: {
        pld_detail: {
            locations_x: new Array(),
            locations_y: new Array(),
            location_mapping: new Object(),
            xrange: new Array(),
            sample_id: new String,
            sample_name: new String,
            measurement_condition: new Array(),
        },
        pld_xrd: new Array()
    },
    mutations: {
        savePldDetail_(state: any, data: any) {
            state.pld_detail = data;
        },
        savePldXrd_(state: any, data: any) {
            state.pld_xrd = data.xrd_data;
        },
    },
    actions: {
        savePldDetail(context: any, data: any) {
            context.commit("savePldDetail_", data);
        },
        savePldXrd(context: any, data: any) {
            context.commit("savePldXrd_", data);
        },
    },
}