








import Vue from 'vue';
// @ts-ignore
import Plotly from "plotly.js-dist";
export default Vue.extend({
    props: {
        model: Object,
    },
    watch: {
        model(data: any){
            var rxn_energy = data.rxn_energy;
            var d_min = Math.min.apply(null, rxn_energy) - 0.01;
            var v_max = Math.max.apply(null, data.voltage) + 0.1;
            var trace = {
                x: data.voltage,
                y: rxn_energy,
                showlegend: false,
                xaxis: 'x',
            };
            var x_r = data.reduction_onset;
            var x_o = data.oxidation_onset;
            var d_r = data.d_e_reduction_onset
            var d_o = data.d_e_oxidation_onset

            var trace1 = {
                x: [x_r, x_o],
                y: [d_r, d_o],
                mode: 'markers',
                marker: {
                    color: '#af5f00',
                    size: 10
                },
                showlegend: false,
                text: data.hovertext,
                hoverinfo: "text",
                xaxis: 'x',
            }

            var plotly_data = [
                trace, trace1,
            ];
            var layout = {
                margin: {
                    b: 50,
                    r: 30,
                    t: 70
                },
                xaxis: {
                    title: "Voltage (V)",
                    autorange: false,
                    side: 'top',
                    ticks: 'outside',
                    range: [-0.05, v_max],
                    anchor: 'free',
                    position: 1,
                    zeroline: false,
                    showgrid: true,
                    automargin: true,
                    showline: true,
                    linecolor: 'rgb(0,0,0)',
                },
                yaxis: {
                    title: "Decomposition Energy (eV/atom)",
                    autorange: false,
                    ticks: 'outside',
                    range: [d_min,0.02],
                    zeroline: false,
                    showline: true,
                    automargin: true,
                    linecolor: 'rgb(0,0,0)',
                    showgrid: true,
                    anchor: 'free',
                },
                autosize: true,
                hovermode: "closest",
                
            };
            //@ts-ignore
            Plotly.newPlot("ecw", plotly_data, layout, {
                displayModeBar: false,
                responsive: true,
                scrollZoom: true,
                displaylogo: false
            });
        }
    }
})
