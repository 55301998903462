import { AuthApiNode } from "@/nodes/authApiNode"
export class GetRunHistoryFile extends AuthApiNode {
  url = "/api/run4u/dashboard_handle/"
  method = "post"
  mode = "direct"

  buildParams = async () => {
    this.$state.commit((state: any) => {
      state.store = this.inputs.history_store
      return state
    })
  }
}