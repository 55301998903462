import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { BindOrRegisterNode } from "./nodes/BindOrRegisterNode";
import { BindTokenNode } from "./nodes/BindTokenNode";
import { GithubOrWechatNode } from "./nodes/GithubOrWechatNode";
import { GitBindNode } from "./nodes/GitBindNode";
import { WeChatBindNode } from "./nodes/WeChatBindNode";
import { WeChatRegisterNode } from "./nodes/WeChatRegisterNode";
import { GitRegisterNode } from "./nodes/GitRegisterNode";
export class BindLogin extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "start",
        next: "1",
      },
      {
        cls: BindOrRegisterNode,
        id: "1",
        positiveNext: "2",
        negativeNext: "6",
      },
      {
        cls: BindTokenNode,
        id: "2",
        next: "3",
      },
      {
        cls: GithubOrWechatNode,
        id: "3",
        positiveNext: "4",
        negativeNext: "5",
      },
      {
        cls: GitBindNode,
        id: "4",
        next: "end",
      },
      {
        cls: WeChatBindNode,
        id: "5",
        next: "end",
      },
      {
        cls: BindTokenNode,
        id: "6",
        next: "7",
      },
      {
        cls: GithubOrWechatNode,
        id: "7",
        positiveNext: "8",
        negativeNext: "9",
      },
      {
        cls: GitRegisterNode,
        id: "8",
        next: "end",
      },
      {
        cls: WeChatRegisterNode,
        id: "9",
        next: "end",
      },
      {
        cls: StopNode,
        id: "end",
      },
    ];
  }
  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
