
















import Vue from "vue";
export default Vue.extend({
  methods: {
    handleCommand(command: any) {
      this.$emit("click", command);
    }
  }
});
