export default {
  state: {
    struct_id_global: ""
  },
  mutations: {
    storeStructIDGrobalData(state: any, data: any) {
      state.struct_id_global = data;
    }
  },
  actions: {
    storeStructIDGrobal(context: any, data: any) {
      context.commit("storeStructIDGrobalData", data);
    }
  }
};
