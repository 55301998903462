export default {
  state: {
    // 晶胞参数 - unit cell parameter
    unit_cell_parameter: {
      a: "8.164 Å",
      b: "7.952 Å",
      c: "7.052 Å",
      α: "90.0°",
      β: "90.0°",
      γ: "90.0°",
      volumn: "457.823 Å<sup>3</sup>",
    },
    // 对称属性 - symmetry property
    symmetry_property: {
      hall: "P 2ac 2ab",
      space_group: "P2_12_12_1",
      point_group: "222",
      crystal_system: "orthorhombic",
    },
    // 能量值
    energies: {
      energy: "-1.121 eV",
      energy_atom: "-1.122 eV",
      energy_vdw: "-1.123 eV",
      energy_vdw_atom: "-1.124 eV"
    }
  }
}