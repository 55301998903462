import { FunctionNode } from "arkfbp/lib/functionNode";
export class HandleParams extends FunctionNode {
  async run() {
    this.$state.commit((state: any) => {
      state.store = this.inputs.tb_store;
      state.grid_id = this.inputs.grid_id;
      return state;
    });
  }
}
