






































































































































import Vue from 'vue';
export default Vue.extend({
    name: "Dielectric",
    props: {
        epsilon_data: Object,
    },
})
