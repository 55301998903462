export default {
  state: {
    submitted_structures_data_gather: [
      {
        number: 1,
        name: "H2O",
        space_group: "P-6m2",
        submit_time: "2020-09-07",
        state_value: true,
        struct_id: 10020002,
        reason: "error code:01",
        reason_details: "Too many atoms to run (more than 100 atoms)",
      },
      {
        number: 2,
        name: "Ni3O4",
        space_group: "P-6m2",
        submit_time: "2020-09-07",
        state_value: true,
        struct_id: 10020003,
        reason: "error code:01",
        reason_details: "Too many atoms to run (more than 100 atoms)",
      },
      {
        number: 3,
        name: "Al1.5CsF6Na0.5",
        space_group: "P-6m2",
        submit_time: "2020-09-07",
        state_value: true,
        struct_id: 1010101010,
        reason: "error code:01",
        reason_details: "Too many atoms to run (more than 100 atoms)",
      },
      {
        number: 4,
        name: "F12Li3Na3Sc2",
        space_group: "P-6m2",
        submit_time: "2020-09-07",
        state_value: true,
        struct_id: 10010023,
        reason: "error code:01",
        reason_details: "Too many atoms to run (more than 100 atoms)",
      }
    ],
    search_structures_data_gather: []
  },
  mutations: {
    updateLargeTableSearch(state: any, data: any) {
      state.submitted_structures_data_gather = data
    },
    updateLargeTableSubmitted(state: any, data: any) {
      state.search_structures_data_gather = data
    }
  },
  actions: {
    updateLargeSearch(context: any, data: any) {
      context.commit("updateLargeTableSearch", data)
    },
    updateLargeSubmitted(context: any, data: any) {
      context.commit("updateLargeTableSubmitted", data)
    }
  }
}