import { APINode } from "arkfbp/lib/apiNode";

export class BindSmsNode extends APINode {
  mode = "direct";
  url = "/api/arkid/bind_request_sm/";
  method = "post";

  async buildParams() {
    this.$state.commit((state: any) => {
      state.mobile = this.inputs.mobile;
      state.store = this.inputs.store;
      return state;
    });
    const mobile = this.inputs.mobile;
    return { mobile: mobile };
  }
}
