















import Vue from "vue";
import LoginInput from "@/components/login/LoginInput.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { MobileCode } from "@/flows/mobileCode";
import { MobileChangeMobile } from "@/flows/mobileChangeNew";

export default Vue.extend({
  components: { LoginInput },
  data() {
    return {
      text: this.$t("placeholder.confirmBind"),
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.initProtocalData();
    },
  },

  methods: {
    initProtocalData() {
      this.text = this.$t("placeholder.confirmBind");
    },
    getCodeHandler(mobile: string) {
      runWorkflow(new MobileCode(), {
        mobile: mobile,
        store: this.$store,
      });
    },
    submitHandler(mobile: string, code: string) {
      runWorkflow(new MobileChangeMobile(), {
        mobile: mobile,
        code: code,
        store: this.$store,
      }).then((data) => {
        if (data.new_mobile) {
          this.$store.commit("setUserMobile", data.new_mobile);
          this.$emit("goBindSuccess");
        } else {
          this.$message({
            message: "手机号绑定失败",
            type: "warning",
            offset: 100,
          });
        }
      });
    },
  },
});
