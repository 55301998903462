



























import Vue from "vue";
import OutlineTitle from "./OutlineTitle.vue";
import ThreeDataTitle from "./ThreeDataTitle.vue";
import ThreeData from "./ThreeData.vue";
import FourFunction from "./FourFunction.vue";
import TeamList from "./TeamList.vue";
import SupportList from "./SupportList.vue";
export default Vue.extend({
  name: "HomeOutline",
  components: {
    OutlineTitle,
    ThreeDataTitle,
    ThreeData,
    FourFunction,
    TeamList,
    SupportList,
  },
  props: {
    atomlyData: Object,
    teamList: Array,
    isResetOutline: Boolean,
  }
});
