import { AuthApiNode } from "@/nodes/authApiNode"
export class GetBrillouinData extends AuthApiNode {
  mode = "direct";
  method = "post";
  url = "/api/matdata/get_brillouin_zone/"
  
  async buildParams() {
    let params_obj = { struct_id: "" }
    params_obj.struct_id = this.$state.fetch().struct_id_;
    return params_obj
  }
}