import { IFNode } from "arkfbp/lib/ifNode";

export class GithubOrWechatNode extends IFNode {
  condition() {
    // console.log("GithubOrWechatNode:", this.inputs);
    const _store = this.$state.fetch().store;
    _store.dispatch("setSmsToken", this.inputs.sms_token);

    const third_party = sessionStorage.getItem("third_party");
    return third_party == "github";
  }
  positive() {
    return {};
  }
  negative() {
    return {};
  }
}
