



















































import Vue from "vue";
// @ts-ignore
import Plotly from "plotly.js-dist";
import XButton from "@/components/public/operateBox/ButtonCpn.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main_ } from "@/flows/generateXRayFlow";
export default Vue.extend({
  name: "XRDComponent",
  components: {
    XButton,
  },
  props: {
    model: Object,
  },
  data() {
    return {
      scale: 1,
      isSelectWaveParams: false,
      radiation_wavelength: "1.54184",
      particle_size: 200,
      waveLengthParamsItem: {
        name: "CuKa",
        id: "0",
        defalut_radiation_wavelength: "1.54184",
      },
      waveLengthParams: [
        {
          name: "CuKa",
          id: "0",
          defalut_radiation_wavelength: "1.54184",
        },
        {
          name: "Customized",
          id: "1",
          defalut_radiation_wavelength: "",
        },
        {
          name: "CuKa2",
          id: "2",
          defalut_radiation_wavelength: "1.54439",
        },
        {
          name: "CuKa1",
          id: "3",
          defalut_radiation_wavelength: "1.54056",
        },
        {
          name: "CuKb1",
          id: "4",
          defalut_radiation_wavelength: "1.39222",
        },
        {
          name: "MoKa",
          id: "5",
          defalut_radiation_wavelength: "0.71073",
        },
        {
          name: "MoKa2",
          id: "6",
          defalut_radiation_wavelength: "0.71359",
        },
        {
          name: "MoKa1",
          id: "7",
          defalut_radiation_wavelength: "0.7093",
        },
        {
          name: "MoKb1",
          id: "8",
          defalut_radiation_wavelength: "0.63229",
        },
        {
          name: "CrKa",
          id: "9",
          defalut_radiation_wavelength: "2.291",
        },
        {
          name: "CrKa2",
          id: "10",
          defalut_radiation_wavelength: "2.29361",
        },
        {
          name: "CrKa1",
          id: "11",
          defalut_radiation_wavelength: "2.2897",
        },
        {
          name: "CrKb1",
          id: "12",
          defalut_radiation_wavelength: "2.08487",
        },
        {
          name: "FeKa",
          id: "13",
          defalut_radiation_wavelength: "1.93735",
        },
        {
          name: "FeKa2",
          id: "14",
          defalut_radiation_wavelength: "1.93998",
        },
        {
          name: "FeKa1",
          id: "15",
          defalut_radiation_wavelength: "1.93604",
        },
        {
          name: "FeKb1",
          id: "16",
          defalut_radiation_wavelength: "1.75661",
        },
        {
          name: "CoKa",
          id: "17",
          defalut_radiation_wavelength: "1.79026",
        },
        {
          name: "CoKa2",
          id: "18",
          defalut_radiation_wavelength: "1.79285",
        },
        {
          name: "CoKa1",
          id: "19",
          defalut_radiation_wavelength: "1.78896",
        },
        {
          name: "CoKb1",
          id: "20",
          defalut_radiation_wavelength: "1.63079",
        },
        {
          name: "AgKa",
          id: "21",
          defalut_radiation_wavelength: "0.560885",
        },
        {
          name: "AgKa2",
          id: "22",
          defalut_radiation_wavelength: "0.563813",
        },
        {
          name: "AgKa1",
          id: "23",
          defalut_radiation_wavelength: "0.559421",
        },
        {
          name: "AgKb1",
          id: "24",
          defalut_radiation_wavelength: "0.497082",
        },
      ],
      isDrop: false,
      isGenerating: false,
      rotateVal: 0,
      isHasData: false,
      rotateInterval: 0,
    };
  },
  mounted() {
    this.toRotate();
    this.toScale();
  },
  watch: {
    model(data: any) {
      clearInterval(this.rotateInterval);
      this.isHasData = true;
      var plotly_data = [
        {
          mode: "lines",
          x: data.x,
          y: data.y,
          line: {
            width: 1,
          },
          name: data.data[0]['name'] || 'pristine'
        },
        {
          x: data.data[0]["x"],
          y: data.data[0]["y"],
          text: data.data[0]["text"],
          mode: "markers",
          hoverinfo: "text",
          marker: {
            opacity: 0,
          },
          showlegend: false,
        },
      ];

      var layout = {
        legend: {
            x: 1,
            y: 1.1,
            "orientation": "h",
            xanchor: 'right',
            font: {
                size: 16
            }
        },
        margin: {
          b: 40,
          r: 40,
          t: 60,
          l: 60,
        },
        xaxis: {
          title: "2Θ (degree)",
          rangeslider: {
            autorange: false,
            range: [0, 90],
          },
          autorange: false,
          range: [10, 70],
        },
        yaxis: {
          title: "Intensity",
          fixedrange: true,
        },
        autosize: true,
        hovermode: "closest",
      };
      //@ts-ignore
      Plotly.newPlot("xrd", plotly_data, layout, {
        displayModeBar: false,
        responsive: true,
      });
    },
  },
  methods: {
    selectWaveParams(wave_data: any) {
      this.isSelectWaveParams = wave_data;
    },
    changeButtonContent(e: any) {
      this.waveLengthParams.forEach((item, index) => {
        if (item.name == e) {
          this.radiation_wavelength = item.defalut_radiation_wavelength;
          this.waveLengthParams.splice(index, 1);
          this.waveLengthParams.unshift(item);
        }
      });
    },
    generateXRay() {
      this.isGenerating = true;
      runWorkflow(new Main_(), {
        generate_waveLength: Number(this.radiation_wavelength),
        generate_size: this.particle_size,
        generate_id: this.$route.params.id,
        generate_store: this.$store,
      }).then(() => {
        this.isGenerating = false;
      });
    },
    getCurrentXRayHeight() {
      const cur_screen_width = document.documentElement.offsetWidth;
      if (cur_screen_width >= 800) {
        return "424";
      }
      if (cur_screen_width < 800) {
        return "305";
      }
    },
    getCurrentXRayWidth() {
      const cur_screen_width = document.documentElement.offsetWidth;
      if (cur_screen_width >= 800) {
        return "937";
      }
      if (cur_screen_width < 800) {
        return "676";
      }
    },
    toScale() {
      const _screen_width = document.documentElement.offsetWidth;
      const _el_xrd: any = document.getElementById("xrd");
      const _xrd_width = _el_xrd.parentNode.clientWidth;
      if (_screen_width >= 1440) {
        this.scale = 1;
      }
      if (_screen_width >= 800 && _screen_width < 1440) {
        const _x_width = _xrd_width * 0.937;
        this.scale = _x_width / 937;
      }
      if (_screen_width < 800) {
        const _smalll_x_width = _xrd_width * 0.92;
        this.scale = _smalll_x_width / 676;
      }
    },
    toRotate() {
      this.rotateInterval = setInterval(() => {
        const el_data_placeholder: any = document.getElementById('data-placeholder');
        this.rotateVal += 1;
        el_data_placeholder.style.transform = 'rotate(' + this.rotateVal + 'deg)';
        el_data_placeholder.style.transition = '0.1s linear';
      }, 10);
    }
  },
});
