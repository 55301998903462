import { AuthApiNode } from "@/nodes/authApiNode"
export class GetXRayData extends AuthApiNode {
  url = "/api/matdata/get_xrd_handle/"
  method = "post"
  mode = "direct"

  buildParams = async () => {
    let params_obj = { struct_id: ""}
    this.$state.commit((state: any) => {
      state.store = this.inputs.x_ray_store
      return state
    })
    params_obj.struct_id = this.inputs.struct_id
    return params_obj
  }

}