
































import Vue from "vue";
import RunButton from "@/components/public/operateBox/ButtonCpn.vue";
export default Vue.extend({
  components: {
    RunButton,
  },
  props: {
    uploadText: {
      type: String,
      required: false,
    },
    upload_text_2: {
      type: String,
      default: "点击或将文件拖拽到这里上传，CIF、POSCAR，小于10KB。",
    },
  },
  data() {
    return {
      imgUrl: false,
      btnStatus: 1,
      file_list: [],
    };
  },
  methods: {
    uploadCPFile(fileData: any, fileList: Array<any>) {
      this.file_list = [];
      if (fileData != null) {
        this.$emit("uploadFile", fileData);
      }
    },
    clearFileList() {
      this.file_list = [];
    },
  },
});
