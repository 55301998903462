















import Vue from "vue";
import LoginInput from "@/components/login/LoginInput.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { MobileCode } from "@/flows/mobileCode";
import { MobileOldSms } from "@/flows/mobileOldSms";
export default Vue.extend({
  components: { LoginInput },
  data() {
    return {
      text: this.$t("placeholder.nextStep"),
    };
  },
  watch: {
    "$i18n.locale"(language) {
      this.initProtocalData();
    },
  },

  methods: {
    initProtocalData() {
      this.text = this.$t("placeholder.nextStep");
    },
    getCodeHandler(mobile: string) {
      runWorkflow(new MobileCode(), {
        mobile: mobile,
        store: this.$store,
      });
    },
    submitHandler(mobile: string, code: string) {
      runWorkflow(new MobileOldSms(), {
        mobile: mobile,
        code: code,
      }).then((data) => {
        // console.log("MobileOldSms", data);
        this.$store.commit("setOldSmsToken", data.sms_token);
        this.$emit("goBindNewMobile");
      });
    },
  },
});
