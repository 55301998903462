export default {
  state: {
    submitted_history: []
  },
  mutations: {
    updateSubmittedHistoryData(state: any, data: any) {
      state.submitted_history = data
    }
  },
  actions: {
    updateSubmittedHistory(context: any, data: any) {
      context.commit("updateSubmittedHistoryData", data)
    }
  }
}