





























import { Component, Vue } from "vue-property-decorator";
import AboutContainer from "@/components/about/AboutContainer.vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { GetAboutInfo } from "@/flows/aboutInfo";
@Component({
  components: { AboutContainer },
})
export default class Milestone extends Vue {
  newstitle = "";
  milestone = "";

  mounted() {
    this.getInfo();
  }
  getInfo() {
    runWorkflow(new GetAboutInfo(), {}).then((data) => {
      this.newstitle = data.news;
      this.milestone = data.milestion;
    });
  }
}
