










































































































import Vue from "vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { Main_Xrd_Get_Img } from "@/flows/xrdGet";
export default Vue.extend({
    name: "MatchTable",
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            pagerCount: 7,
        }
    },
    computed: {
        searchTableData: {
            get: function (): any {
                return this.$store.state.matchBoxData.match_box_data;
            },
        },
        tableData: {
            get: function (): any {
                return this.$store.state.matchBySomeway.match_by_someway_gather || [];
            },
        },
        matchTableItem: {
            get: function (): any {
                return this.$store.state.matchBySomeway;
            },
        },
    },
    methods: {
        watchMatDataDes(item: any) {
            const struct_id = item.struct_id;
            const crystal_size = this.$store.state.matchBySomeway.match_crystal_size;
            const radiation = this.$store.state.matchBySomeway.match_radiation;
            runWorkflow(new Main_Xrd_Get_Img(), {
                struct_id: struct_id,
                crystal_size: crystal_size,
                radiation: radiation,
                upload_store: this.$store
            })
        },
    },
})
