




























































































































































































import Vue from "vue";
import EcwDisplayHideButton from "./EcwDisplayHideButton.vue"
import { runWorkflow } from "arkfbp/lib/flow";
export default Vue.extend({
  name: "SearchTable",
  components: {
    EcwDisplayHideButton,
  },
  props: {
    searchBasis: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 20,
      pagerCount: 7,
    };
  },
  computed: {
    searchTableData: {
      get: function (): any {
        return this.$store.state.ecwCheckedBoxData.checked_box_data;
      },
    },
    searchTableItem: {
      get: function (): any {
        return this.$store.state.ecwSearchBySomeway;
      },
    },
    tableData: {
      get: function (): any {
        return this.$store.state.ecwSearchBySomeway.search_by_someway_gather || [];
      },
    },
  },
  methods: {
    watchMatDataDes(item: any) {
      const { href } = this.$router.resolve({
        name: "Ec_windowDetail",
        params: {
          work_ion: item.work_ion,
          id: item.struct_id,
        },
      });
      window.open(href, "_blank");
    },
    getSearchBasis(data: any) {
      if (data) {
        if (data.type === "formula") {
          return data.formula;
        } else {
          return data.formula.replace(/-/g, " ");
        }
      } else {
        return "-";
      }
    },
  },
});
