export default {
  state: {
    showComponent: "",
    accountMaskShow:false,
    old_sms_token: "",
  },
  getters: {},
  mutations: {
    setOldSmsToken(state: any, data: any) {
      state.old_sms_token = data;
    },
  },
  actions: {},
};
