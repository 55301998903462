








import Vue from "vue";
export default class LoginFooter extends Vue {}
