import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { GenerateXRay } from "./nodes/GenerateXRay"
import { RenderGenerateData } from "./nodes/RenderGenerateData"

export class Main_ extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "1",
        next: "2",
      },
      {
        cls: GenerateXRay,
        id: "2",
        next: "3",
      },
      {
        cls: RenderGenerateData,
        id: "3",
        next: "4",
      },
      {
        cls: StopNode,
        id: "4",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
