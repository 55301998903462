import { APINode } from "arkfbp/lib/apiNode";

export class GitLoginNode extends APINode {
  mode = "direct";
  url = "/api/arkid/github/login/";
  method = "post";

  async buildParams() {
    sessionStorage.setItem("third_party", "github");
    const gitredirect_uri = window.location.href;
    return {redirect_uri: gitredirect_uri};
  }
}
