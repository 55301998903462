import { APINode } from "arkfbp/lib/apiNode";
export class About extends APINode {
  mode = "direct";
  url = "/api/about/get_data/";
  method = "get";
  buildParams = async () => {
    this.$state.commit((state: any) => {
      state.store = this.inputs.home_store;
      return state;
    });
  };
}
