











import Vue, { Component } from "vue";
import { runWorkflow } from "arkfbp/lib/flow";
import { GetUserInfo } from "@/flows/userInfoRequest";
export default Vue.extend({
  mounted() {
    this.setLayout();
    window.addEventListener("resize", this.setLayout);
  },
  created() {
    if (this.isnotEmpty(localStorage.getItem("userToken"))) {
      this.getUserInfo();
    }
  },
  methods: {
    isnotEmpty(obj: any) {
      if (typeof obj == "undefined" || obj == null || obj == "") {
        return false;
      } else {
        return true;
      }
    },
    setLayout() {
      const current_width = document.documentElement.clientWidth;
      if (current_width >= 800.01 || window.innerWidth >= 800.01) {
        this.$store.dispatch("resetLayout", false);
        this.$store.dispatch("saveCurrentPageWidth", current_width);
      }
      if (current_width < 800.01 || window.innerWidth < 800.01) {
        this.$store.dispatch("resetLayout", true);
        this.$store.dispatch("saveCurrentPageWidth", current_width);
      }
    },
    getUserInfo() {
      runWorkflow(new GetUserInfo(), { store: this.$store });
    },
  },
});
