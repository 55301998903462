































































import Vue from "vue";
import MoreItem from "./MoreTableItem.vue";
export default Vue.extend({
  name: "MoreTable",
  components: {
    MoreItem,
  },
  props: {
    more_table_data: Object,
  },
});
