import { Flow } from "arkfbp/lib/flow";
import { Graph } from "arkfbp/lib/graph";
import { StartNode } from "arkfbp/lib/startNode";
import { StopNode } from "arkfbp/lib/stopNode";

import { LoginOrRegisterNode } from "./nodes/LoginOrRegisterNode";
import { LoginSmsTokenNode } from "./nodes/LoginSmsTokenNode";
import { LoginInfoNode } from "./nodes/LoginInfoNode";
import { RegisterSmsTokenNode } from "./nodes/RegisterSmsTokenNode";
import { RegisterInfoNode } from "./nodes/RegisterInfoNode";

export class MobileLogin extends Flow {
  createNodes() {
    return [
      {
        cls: StartNode,
        id: "start",
        next: "1",
      },
      {
        cls: LoginOrRegisterNode,
        id: "1",
        positiveNext: "2",
        negativeNext: "4",
      },
      {
        cls: LoginSmsTokenNode,
        id: "2",
        next: "3",
      },
      {
        cls: LoginInfoNode,
        id: "3",
        next: "end",
      },
      {
        cls: RegisterSmsTokenNode,
        id: "4",
        next: "5",
      },
      {
        cls: RegisterInfoNode,
        id: "5",
        next: "end",
      },
      {
        cls: StopNode,
        id: "end",
      },
    ];
  }

  createGraph() {
    const g = new Graph();
    g.nodes = this.createNodes();
    return g;
  }
}
